import styled from 'styled-components/macro';

const Textarea = styled.textarea`
  background-color: ${(props) => props.theme.color.white};
  border: 0;
  border-bottom: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.grayLight};
  border-radius: 0;
  color: ${(props) => props.theme.color.grayDarker};
  font: inherit;
  margin: 0;
  padding: ${(props) => props.theme.space['16px']};

  ::placeholder {
    color: ${(props) => props.theme.color.gray};
  }
`;

export default Textarea;

import React from 'react';
import styled from 'styled-components/macro';
import Image from './Image';

const ImageWrapper = styled.div`
  float: left;
  margin-right: ${(props) => props.theme.space['24px']};
  width: 40%;
`;

const ImageCaption = styled.p`
  color: ${(props) => props.theme.color.grayDark};
  font-style: italic;
  font-size: ${(props) => props.theme.font.size['14px']};
  margin-top: ${(props) => props.theme.space['16px']};
`;

const CopyImage = ({ children, ...props }) => {
  const imgProps = { ...props };
  delete imgProps.style;

  return (
    <ImageWrapper {...props}>
      <Image {...imgProps} />
      <ImageCaption>{children}</ImageCaption>
    </ImageWrapper>
  );
};

export default CopyImage;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import getContactBar from '../../api/contentful/getContactBar';
import mapPinIcon from '../../assets/icons/map_pin_icon_outline_yellow.svg';
import messageIcon from '../../assets/icons/message_icon_yellow.svg';
import phoneIcon from '../../assets/icons/phone_icon_yellow.svg';
import newsletterIcon from '../../assets/icons/news_icon_yellow.svg';
import questionMarkIcon from '../../assets/icons/Ramirent_Qmark_yellow_large.svg';

import BoldText from './BoldText';
import {
  GOOGLE_ANALYTICS_CATEGORY_LINK,
  GOOGLE_ANALYTICS_LABEL_CONTACT_BAR,
} from '../../constants';
import { GoogleAnalyticsEvent } from './GoogleAnalytics';
import Link from './Link';
import { noPrintMixin } from '../../theme';
import { useLocale } from '../../shared/hooks/useLocale';
import { getLink } from '../../utils/link';

const StyledContactBar = styled.div`
  ${noPrintMixin};

  background-color: ${(props) => props.theme.color.blue};
  padding: ${(props) => props.theme.space['12px']}
    ${(props) => props.theme.space['16px']};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 465px;

  ${(props) => props.theme.device.tablet`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: ${(props) => props.theme.page.maxWidth};
  `}

  ${(props) => props.theme.device.desktop`
    flex-wrap: nowrap;
  `}
`;

const Section = styled(Link)`
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  color: inherit;
  display: flex;
  padding: ${(props) => props.theme.space['12px']};
  text-decoration: none;
  transition: background-color 0.2s ease;

  :hover {
    background-color: ${(props) => props.theme.color.blueLight};
    color: inherit;
  }

  ${(props) => props.theme.device.tablet`
    width: 45%;
  `}
`;

const Image = styled.img`
  margin-right: ${(props) => props.theme.space['16px']};
  max-height: 40px;
  max-width: 40px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.color.white};
  font-family: ${(props) => props.theme.font.family.messina};
  font-size: ${(props) => props.theme.font.size['14px']};
  margin-top: ${(props) => props.theme.space['4px']};
`;

const handleSectionClick = (sectionActionId) => {
  GoogleAnalyticsEvent(
    GOOGLE_ANALYTICS_CATEGORY_LINK,
    `Clicked ${sectionActionId}`,
    GOOGLE_ANALYTICS_LABEL_CONTACT_BAR
  );
};

const handleContactWay = (contactWay, contactInfo, local) => {
  switch (contactWay) {
    case 'Call':
      return `tel:${contactInfo}`;
    case 'Email':
      return `mailto:${contactInfo}`;
    case 'Link':
      return contactInfo.startsWith('/')
        ? getLink(contactInfo, local)
        : contactInfo;
    default:
      return 'messageIcon';
  }
};

const handleIconSelection = (icon) => {
  switch (icon) {
    case 'Telephone':
      return phoneIcon;
    case 'Pin':
      return mapPinIcon;
    case 'Newspaper':
      return newsletterIcon;
    case 'Speech bubble':
      return messageIcon;
    case 'Question mark':
      return questionMarkIcon;
    default:
      return 'messageIcon';
  }
};

const ContactBar = () => {
  const { locale, translations } = useLocale();
  const [contactBarFields, setContactBarFields] = useState([]);
  const language = window.location.href.split('=')[1];

  useEffect(() => {
    const getData = async () => {
      const response = await getContactBar(language);
      setContactBarFields(response || []);
    };
    getData();
  }, [language]);
  return (
    <StyledContactBar data-cy="contactBar">
      <Wrapper>
        {contactBarFields.contactMenu?.map((item, i) => (
          <Section
            key={item.title + i}
            onClick={() => handleSectionClick(item.contactWay)}
            url={handleContactWay(item.contactWay, item.contactInfo, locale)}
          >
            <Image alt={item.icon} src={handleIconSelection(item.icon)} />
            <div>
              <BoldText
                yellow
                inline
                nowrap
                data-cy={`${item.contactWay.toLowerCase()}Header`}
              >
                {item.title}
              </BoldText>
              <Description>{item.copy}</Description>
            </div>
          </Section>
        ))}
      </Wrapper>
    </StyledContactBar>
  );
};

export default ContactBar;

import {
  COUNTRY,
  ARTICLES_PATH,
  BLOG_PATH,
  NEWS_PATH,
  REFERENCES_PATH,
} from '../../constants';
import contentfulClient from '../../clients/contentfulClient';
import { getArticlePosts } from './getArticlePosts';
import { getBlogPosts } from './getBlogPosts';
import { getNewsPosts } from './getNewsPosts';
import { getReferenceEntities } from './getReferenceEntities';
import { getPages } from './getPages';
import logError from '../../utils/logger';

const getBlogs = async (limit, skip, filter) => {
  const response = await getBlogPosts(limit, skip, filter);
  return {
    pages: response.blogPosts,
    totalPages: response.total,
  };
};

const getLatestSubpages = async (
  locale,
  parentSlug,
  limit,
  skip = 0,
  filter = undefined
) => {
  if (`/${parentSlug}` === ARTICLES_PATH) {
    if (COUNTRY === 'NO') {
      return await getBlogs(limit, skip, filter);
    }
    const response = await getArticlePosts(limit, skip, filter);
    return {
      pages: response.articlePosts,
      totalPages: response.total,
    };
  } else if (`/${parentSlug}` === BLOG_PATH) {
    return await getBlogs(limit, skip, filter);
  } else if (`/${parentSlug}` === NEWS_PATH) {
    const response = await getNewsPosts(limit, skip, filter);
    return {
      pages: response.newsPosts,
      totalPages: response.total,
    };
  } else if (`/${parentSlug}` === REFERENCES_PATH) {
    const response = await getReferenceEntities(limit, skip, filter);
    return {
      pages: response.references,
      totalPages: response.total,
    };
  }
  return await getPages(locale, parentSlug, limit, skip, filter);
};

const getFilterOptions = async (type) => {
  if (
    ![
      'articlePost',
      'blogPost',
      'newsPost',
      'referenceEntity',
      'page',
    ].includes(type)
  ) {
    return [];
  }
  try {
    const response = await contentfulClient.getEntries({
      content_type: type,
      limit: 500,
      order: '-fields.date',
      select: 'fields.date',
    });
    const years = response.items
      .filter((post) => post.fields && post.fields.date)
      .map((post) => {
        const date = new Date(post.fields.date).getFullYear().toString();
        return {
          value: date,
          label: date,
        };
      })
      .filter(
        (year, index, self) =>
          index ===
          self.findIndex(
            (y) => y.place === year.place && y.value === year.value
          )
      );

    return years;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export { getLatestSubpages, getFilterOptions };

import contentfulClient from '../../clients/contentfulClient';
import logError from '../../utils/logger';
import { formatNavigationBarCollection } from './model/NavigationBar';
import { formatNavigationBarCollectionFI } from './model/NavigationBarFI';
import { COUNTRY } from '../../constants';

const getNavigationBar = async (language) => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'navigationBar',
      include: 10,
      ...(COUNTRY === 'FI' && { locale: '*' }),
    });
    if (response.items) {
      return COUNTRY === 'FI'
        ? formatNavigationBarCollectionFI(response.items[0].fields, language)
        : formatNavigationBarCollection(response.items[0].fields);
    } else {
      return [];
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export default getNavigationBar;

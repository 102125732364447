import contentfulClient from '../../clients/contentfulClient';
import { getLocalIsoTime } from '../../utils/dateFunctions';
import logError from '../../utils/logger';
import { locales, COUNTRY } from '../../constants';

import { formatPage, formatParentPages } from './model';

const pageParams = (locale) => ({
  content_type: 'page',
  include: 10,
  locale: locale ? locale : null,
});

const parentPageFilters = (parentSlug) => ({
  'fields.parentPage.fields.slug': parentSlug,
  'fields.parentPage.sys.contentType.sys.id': 'page',
});

export const parentSlugsMatchesPageParentPageReferences = (
  parentPages,
  parentSlugs
) => {
  const givenParentSlugs = parentSlugs ? [...parentSlugs] : [];
  if (parentPages.length !== givenParentSlugs.length) {
    return false;
  }
  for (var i = 0; i < parentPages.length; i++) {
    if (parentPages[i].slug !== givenParentSlugs[i]) {
      return false;
    }
  }
  return true;
};

const getPageBySlug = async (slug, parentSlugs, locale) => {
  try {
    const response = await contentfulClient.getEntries({
      ...pageParams(locale),
      'fields.slug': slug,
      ...(parentSlugs
        ? parentPageFilters(parentSlugs[parentSlugs.length - 1])
        : {}),
    });
    if (response.items) {
      const pageWithCorrectParents = response.items.find((item) =>
        parentSlugsMatchesPageParentPageReferences(
          formatParentPages(item, []),
          parentSlugs
        )
      );
      if (pageWithCorrectParents) {
        const pageResponse = { ...pageWithCorrectParents };
        return formatPage(pageResponse.fields, locale);
      }
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getPages = async (locale, parentSlug, limit, skip, filter) => {
  let min = undefined;
  let max = undefined;

  if (filter && filter.toString() !== 'all') {
    min = `${filter}-01-01`;
    // If filter is the current year do not display
    // pages with publish time later than current time
    max = getLocalIsoTime().includes(filter)
      ? getLocalIsoTime()
      : `${filter}-12-31`;
  }

  try {
    const response = await contentfulClient.getEntries({
      ...pageParams(locale),
      'fields.date[lte]': max ? max : getLocalIsoTime(),
      'fields.date[gte]': min,
      ...parentPageFilters(parentSlug),
      limit,
      skip,
      order: '-fields.date',
    });
    const pages = response.items.map((page) => formatPage(page.fields, locale));
    return { pages, totalPages: response.total };
  } catch (error) {
    logError(error);
    throw error;
  }
};

const traversePageSlugs = (page, slugList) => {
  if (!page.parentPage) {
    slugList.push({ slug: page.slug[locales.FI] || page.slug });
  } else {
    slugList.push({ slug: page.slug[locales.FI] || page.slug });
    page = page.parentPage[locales.FI]?.fields || page.parentPage.fields;
    return traversePageSlugs(page, slugList);
  }
};

const getCompleteSlug = (page) => {
  let slugList = [];
  traversePageSlugs(page, slugList);
  return slugList.reverse().reduce((acc, curr) => acc + '/' + curr.slug, '');
};

export { getPageBySlug, getPages, getCompleteSlug };

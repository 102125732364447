import ReactGA from 'react-ga';

import { ENVIRONMENT, GOOGLE_ANALYTICS_ID } from '../constants';

const initGoogleAnalyticsClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    ReactGA.set({ anonymizeIp: true });
  }
};

export default initGoogleAnalyticsClient;

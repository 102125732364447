import { lazy } from 'react';
import {
  ARTICLES_PATH,
  BLOG_PATH,
  CUSTOMER_CENTERS_PATH,
  HOME_PATH,
  NEWS_PATH,
  REFERENCES_PATH,
  RENT_PATH,
  SEARCH_PATH,
  ABOUT_PATH,
  SERVICES_PATH,
} from './constants';
import FEATURE_TOGGLES from './shared/views/featureToggle/featureToggles';

const ArticlePost = lazy(() => import('./views/articlePost/ArticlePost'));
const BlogPost = lazy(() => import('./views/blogPost/BlogPost'));
const CustomerCenter = lazy(() =>
  import('./views/customerCenter/CustomerCenter')
);
const NewsPost = lazy(() => import('./views/newsPost/NewsPost'));
const NotFound = lazy(() => import('./views/notFound/NotFound'));
const Page = lazy(() => import('./views/page/Page'));
const Reference = lazy(() => import('./views/reference/Reference'));
const Rent = lazy(() => import('./views/rent/Rent'));
const RentSubcategory = lazy(() =>
  import('./views/rentSubcategory/RentSubcategory')
);
const Search = lazy(() => import('./views/search/Search'));
const RamirentInShortEn = lazy(() =>
  import('./views/ramirentInShort/RamirentInShortEn')
);
const RamirentInShortSe = lazy(() =>
  import('./views/ramirentInShort/RamirentInShortSe')
);

const routes = [
  {
    path: HOME_PATH,
    component: <Page />,
  },
  {
    path: `${CUSTOMER_CENTERS_PATH}/:customerCenterSlug`,
    component: <CustomerCenter />,
  },
  {
    path: `${NEWS_PATH}/:newsPostSlug`,
    component: <NewsPost />,
  },
  {
    path: `${ABOUT_PATH}/:pageSlug/*`,
    component: <Page />,
  },
  {
    path: `${SERVICES_PATH}/:pageSlug/*`,
    component: <Page />,
  },
  {
    path: RENT_PATH,
    component: <Rent />,
  },
  {
    path: `${RENT_PATH}/:nodeSlug/*`,
    component: <RentSubcategory />,
  },
  {
    path: SEARCH_PATH,
    component: <Search />,
  },
  {
    path: `:pageSlug/*`,
    component: <Page />,
  },
  {
    path: '*',
    component: <NotFound />,
  },
];

// Add feature toggled routes to the beginning of the array so
// that they doesn't end up after the wildcard path ('*'). This
// would result in the 404 page being displayed
if (FEATURE_TOGGLES.articles) {
  routes.unshift({
    path: `${ARTICLES_PATH}/:articlePostSlug`,
    component: <ArticlePost />,
  });
}

if (FEATURE_TOGGLES.blog) {
  routes.unshift({
    path: `${BLOG_PATH}/:blogPostSlug`,
    component: <BlogPost />,
  });
}

if (FEATURE_TOGGLES.references) {
  routes.unshift({
    path: `${REFERENCES_PATH}/:referenceEntitySlug`,
    component: <Reference />,
  });
}

if (FEATURE_TOGGLES.ramirentInShort) {
  routes.unshift(
    {
      path: '/en-ramirent-in-short',
      component: <RamirentInShortEn />,
    },
    {
      path: '/se-ramirent-in-short',
      component: <RamirentInShortSe />,
    }
  );
}

export default routes;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(${props => props.theme.page.backgroundColor});
    font-family: ${props => props.theme.font.family.boing};
    font-size: ${props => props.theme.font.size['16px']};
    font-weight: normal;
    height: 100%;
    line-height: normal;
    margin: 0;
  }
  #root {
    height: 100%;
  }
`;

export default GlobalStyle;

import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/macro';

import defaultImage from '../../../../assets/images/Ramicon_Happy.svg';
import { COUNTRY } from '../../../../constants';
import DataError from '../../../components/DataError';
import FlexGrid from '../../../components/FlexGrid';
import SkeletonProductsGrid from '../../../components/SkeletonProductsGrid';
import ProductCard from '../../../views/productCard/ProductCard';
import { sectionMixin } from '../../../../theme';
import logError from '../../../../utils/logger';
import GET_POPULAR_PRODUCTS from '../../../../api/graphQL/queries/getPopularProducts';
import { createProductLink } from '../../../../utils/rentUtil';

const StyledRamiProducts = styled.div`
  ${sectionMixin};

  min-height: 450px;
  position: relative;

  ${(props) => props.theme.device.tablet`
    min-height: 600px;
  `}
`;

const PopularProductsGrid = styled(FlexGrid)`
  > :nth-last-child(-n + 4) {
    display: none;
  }

  ${(props) => props.theme.device.tablet`
    > :nth-last-child(-n + 4) {
      display: inherit;
    }
  `}
`;

const getCountryPopularProductsName = (country) => {
  return {
    SE: 'SE_POPULARAPRODUKTERSUBCAT',
    FI: 'FI_SUOSITTUJATUOTTEITA',
    NO: 'NO_POPULAEREPRODUKTER',
  }[country];
};

const RamiProducts = () => {
  const { data, loading, error } = useQuery(GET_POPULAR_PRODUCTS, {
    variables: {
      id: getCountryPopularProductsName(COUNTRY),
    },
  });

  if (error) {
    logError(error);
  }

  return (
    <StyledRamiProducts data-cy="popularProductsBanner">
      {error && <DataError />}
      {loading && <SkeletonProductsGrid />}
      {data && data.nodeRentalItems && (
        <PopularProductsGrid>
          {data.nodeRentalItems.map(
            ({
              rentalItemId,
              images,
              name,
              specifications,
              make,
              model,
              popularProductParent,
              hideMakeModelFi,
              hideMakeModelNo,
              hideMakeModelSe,
              isRamiGreenFi,
              isRamiGreenNo,
              isRamiGreenSe,
            }) => {
              const link = createProductLink(
                {
                  parentSlug: popularProductParent.slug,
                  name: popularProductParent.name,
                },
                { name, rentalItemId }
              );

              const shouldHideMakeModel = !!(
                (hideMakeModelFi && COUNTRY === 'FI') ||
                (hideMakeModelNo && COUNTRY === 'NO') ||
                (hideMakeModelSe && COUNTRY === 'SE')
              );

              const shouldShowRamiGreenIcon = !!(
                (isRamiGreenFi && COUNTRY === 'FI') ||
                (isRamiGreenNo && COUNTRY === 'NO') ||
                (isRamiGreenSe && COUNTRY === 'SE')
              );

              return (
                <ProductCard
                  key={rentalItemId}
                  image={images[0] ? images[0].url : defaultImage}
                  link={link}
                  make={make}
                  model={model}
                  specifications={specifications}
                  title={name}
                  shouldHideMakeModel={shouldHideMakeModel}
                  shouldShowRamiGreenIcon={shouldShowRamiGreenIcon}
                />
              );
            }
          )}
        </PopularProductsGrid>
      )}
    </StyledRamiProducts>
  );
};

export default RamiProducts;

const swedishTranslations = {
  aboutUs: 'Om oss',
  accessories: 'Tillbehör',
  articles: 'Artiklar',
  blog: 'Blogg',
  blogPost: 'Blogginlägg',
  career: 'Karriär',
  contactUs: 'Kontakta oss',
  cookiePolicy: 'Cookiepolicy',
  cookie: 'Cookie',
  courses: 'Utbildningar',
  customerCenter: 'Kundcenter',
  customerPortal: 'Kundportalen',
  sustainability: 'Hållbarhet',
  customerService: 'För kunder',
  day: 'Dygn',
  documents: 'Dokument',
  excludingVat: 'exkl. moms',
  filterByYear: 'Filtrera på år',
  findCustomerCenter: 'Hitta kundcenter',
  forInvestors: 'För investerare',
  goToCustomerCenter: 'Gå till kundcenter',
  goToHome: 'Gå till startsidan',
  home: 'Hem',
  loadMore: 'Ladda mer',
  marketing: 'Marknadsföring',
  minimize: 'Minimera',
  necessary: 'Nödvändiga',
  news: 'Nyheter',
  newsPost: 'Nyhet',
  noResultsFound: 'Inga resultat hittades',
  openInGoogleMaps: 'Öppna i Google Maps',
  pressroom: 'Nyheter',
  press: 'Pressrum',
  privacyPolicy: 'Integritetspolicy',
  productNumber: 'Produktnummer',
  products: 'Produkter',
  productType: 'Produkttyp',
  published: 'Publicerad',
  readMore: 'Läs mer',
  references: 'Referenser',
  rent: 'Hyra',
  rental: 'Uthyrning',
  reportAbuse: 'Rapportera missbruk',
  reservation: 'Reservation',
  salesItem: 'Försäljning',
  salesItems: 'Försäljningsartiklar',
  seeAll: 'Se alla',
  search: 'Sök',
  searchResults: 'Sökresultat',
  searchResultsFor: 'Sökresultat för',
  services: 'Tjänster',
  shareThis: 'Dela detta',
  statistics: 'Statistik',
  technicalInformation: 'Teknisk information',
  termsAndConditions: 'Användarvillkor',
  articlesBanner: {
    subTitle: 'Håll dig uppdaterad om Ramirent',
    title: 'Artiklar',
  },
  blogBanner: {
    subTitle: 'Håll dig uppdaterad om Ramirent',
    title: 'Senaste blogginläggen',
  },
  contactForm: {
    close: 'Stäng',
    email: 'E-post',
    name: 'Namn',
    phone: 'Telefonnummer',
    location: 'Ort',
    send: 'Skicka',
    yourMessage: 'Ditt meddelande',
    successTitle: 'Tack för ditt meddelande!',
    successMessage: 'Vi återkommer så fort vi kan med svar på ditt ärende.',
    errorTitle: 'Något gick fel.',
    errorMessage: 'Försök igen, eller kontakta kundservice direkt.',
    rules: {
      fullName: 'Fullständigt namn (För- och efternamn)',
      phone: 'Endast telefonnummer',
      required: 'Obligatorisk',
      least6: 'måste vara minst 6 tecken',
      max50: 'får max vara 50 tecken',
      max500: 'får max vara 500 tecken',
    },
  },
  contactPersonsBanner: {
    subTitle: '',
    title: 'Kontaktpersoner',
  },
  customerCenterPage: {
    address: 'Adress',
    openingHours: 'Öppettider',
    specialOpeningHours: 'Avvikande Öppettider',
    closed: 'Stängt',
    weekDays: {
      mon: 'Måndag',
      tue: 'Tisdag',
      wed: 'Onsdag',
      thu: 'Torsdag',
      fri: 'Fredag',
      sat: 'Lördag',
      sun: 'Söndag',
    },
    description: 'Beskrivning',
  },
  customerCentersBanner: {
    byDistance: 'Avstånd',
    byName: 'A-Ö',
    noBrowserSupport: 'Din webbläsare saknar stöd för kartvyn!',
    searchPlaceholder: 'Sök på ort, gata eller plats',
    subTitle: 'Här kan du hyra och lämna tillbaka produkterna',
    title: 'Kundcenter',
  },
  dataErrorBanner: {
    description:
      'Vänta och försök sedan igen. Om felet kvarstår, kontakta kundtjänst.',
    title: 'Fel vid inläsning av data.',
  },
  emptyCategoryPage: {
    description: 'Just nu finns det inga produkter i denna kategori.',
    goBackTo: 'Gå tillbaka till',
    title: 'Tomt!',
  },
  faqBanner: {
    subTitle: 'Här har vi samlat svaren på de vanligaste frågorna',
    title: 'Vanliga frågor',
  },
  footerHeaders: {
    apps: 'Appar',
    group: 'Koncern',
    ourOffer: 'Vårt erbjudande',
    ramirentInTheWorld: 'Ramirent i världen',
  },
  informationBanner: {
    customerCenterDescription: 'Våra medarbetare kan alltid hjälpa dig',
    customerCenterTitle: 'Hitta kundcenter',
    emailDescription: 'Vi svarar vanligtvis inom 24 h',
    phoneDescription: 'Ring oss, vi är här för att hjälpa dig',
    commonQuestionsDescription:
      'Här har vi samlat svaren på de vanligaste frågorna',
    commonQuestionsTitle: 'Vanliga frågor och svar',
    newsletterDescription: 'Här kan du anmäla dig till vårt nyhetsbrev',
    newsletterTitle: 'Anmäl till nyhetsbrev',
  },
  managementBanner: {
    subTitle: '',
    title: 'Ramirents organisation och ledning',
  },
  newsBanner: {
    subTitle: '',
    title: 'Nyheter',
  },
  notFoundPage: {
    description: 'Vi kan inte hitta sidan som du söker.',
    title: 'Ouups!',
  },
  popularProductsBanner: {
    subTitle: 'Ett urval av våra mest hyrda produkter',
    title: 'Populära produkter',
  },
  referenceBanner: {
    subTitle: '',
    title: 'Referenser',
  },
  referencePage: {
    factsTableHeader: 'Fakta om projektet',
    moreReferences: 'Fler referenser',
    productsUsedInProject: 'Produkter som använts i projektet',
  },
  searchBar: {
    placeholder: 'Sök produkter, tjänster och kundcenter',
    seeAllResults: 'Se alla resultat',
  },
  usefulLinksBanner: {
    subTitle: 'Dessa sidor besöker andra kunder ofta',
    title: 'Användbara länkar',
  },
  quickLinksBanner: {
    subTitle: 'Dessa sidor besöker andra kunder ofta',
    title: 'Snabblänkar',
  },
};

export default swedishTranslations;

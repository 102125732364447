import contentfulClient from '../../clients/contentfulClient';
import logError from '../../utils/logger';

import { formatFaqPostCollection } from './model';

const getFaqPostCollections = async () => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'faqPosts',
      order: 'fields.title',
    });

    if (response.items) {
      const faqPosts = response.items.map((faqPostCollection) =>
        formatFaqPostCollection(faqPostCollection.fields)
      );
      return faqPosts;
    }
  } catch (error) {
    logError(error);
    return error;
  }
};

export default getFaqPostCollections;

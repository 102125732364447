import React from 'react';
import styled from 'styled-components/macro';

import arrowIcon from '../../../../../assets/icons/arrow_right_blue.svg';
import ButtonLink from '../../../../components/ButtonLink';
import BoldText from '../../../../components/BoldText';

const StyledArrowButton = styled(ButtonLink)`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 56px;
  justify-content: flex-start;
  padding: 0;
  position: relative;

  ${(props) => props.theme.device.tablet`
      padding: 0;
  `};
`;

const Text = styled(BoldText)`
  margin-left: ${(props) => props.theme.space['32px']};
  /* Special case: 32 + 40 + 8 = 80px */
  margin-right: 80px;
`;

const IconBackground = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  border-radius: ${(props) => props.theme.borderRadius.circle};
  display: flex;
  height: 40px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 40px;
`;

const ArrowIcon = styled.img`
  height: ${(props) => props.theme.icon.size['24px']};
  margin-left: ${(props) => props.theme.space['8px']};
  width: ${(props) => props.theme.icon.size['24px']};
`;

const ArrowButton = ({ children, type, ...props }) => (
  <StyledArrowButton noBorder {...props}>
    <Text inline white>
      {children}
    </Text>
    <IconBackground>
      <ArrowIcon alt="" src={arrowIcon} />
    </IconBackground>
  </StyledArrowButton>
);

export default ArrowButton;

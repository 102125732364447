import { useState, useEffect } from 'react';
/**
 * @param {Number} collapseHeight the height of content that will collapse when hidden
 *
 * If the page is scrolled away from the top will return true
 * @returns {Boolean}
 */

const useHideOnScrolled = (collapseHeight = 0) => {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      setHidden((hidden) => (hidden ? top > 0 : top > collapseHeight));
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [collapseHeight]);

  return hidden;
};

export default useHideOnScrolled;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Wrapper from './containers/Wrapper';

const StyledCustomerCentersMap = styled.div``;

const CustomerCentersMap = ({ className, initialZoom, activeMap = true }) => (
  <StyledCustomerCentersMap className={className} data-cy="customerCentersMap">
    <Wrapper initialZoom={initialZoom} activeMap={activeMap} />
  </StyledCustomerCentersMap>
);

CustomerCentersMap.propTypes = {
  initialZoom: PropTypes.number,
  activeMap: PropTypes.bool
};

export default CustomerCentersMap;

// PLEASE NOTE THAT SEO WILL BE CRITICALLY AFFECTED IF A ROUTE IS MODIFIED
const finnishRoutePaths = {
  aboutPath: 'tietoa ramirentista',
  articlesPath: 'artikkelit',
  blogPath: 'blogi',
  coursesPath: 'koulutukset',
  customerCentersPath: 'vuokraamot',
  customerServicePath: 'asiakaspalvelu',
  sustainabilityPath: 'kestävyys',
  newsPath: 'uutiset',
  pressroomPath: 'ajankohtaista',
  referencesPath: 'referenssit',
  rentPath: 'vuokraa',
  rentPathProduct: 'produkt',
  searchPath: 'hae',
  servicesPath: 'tutustu palveluihimme',
};

export default finnishRoutePaths;

import { css, keyframes } from 'styled-components/macro';

import media from './media';

const theme = {
  border: {
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',
    '6px': '6px solid',
    '8px': '8px solid',
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    larger: '32px',
    circle: '50%',
  },
  color: {
    blue: '#003287',
    blueDark: '#002d7a',
    blueLight: '#335b9f',
    blueLighter: '#7f98c3',
    gray: '#d2d7dc',
    grayDark: '#bdc2c6',
    grayDarker: '#3f4142',
    grayLight: '#dbdfe3',
    grayLighter: '#f8f9fa',
    green: '#50c8aa',
    greenDark: '#48b499',
    greenLight: '#73d3bb',
    greenLighter: '#a7e3d4',
    orange: '#ff963c',
    orangeDark: '#e68736',
    orangeLight: '#ffac63',
    orangeLighter: '#ffca9d',
    red: '#cc0000',
    redDark: '#b80000',
    redLight: '#d63334',
    redLighter: '#e57f7f',
    white: '#fff',
    yellow: '#ffdc00',
    yellowDark: '#e6c600',
    yellowLight: '#ffed7f',
    yellowLighter: '#fff4b2',
  },
  device: {
    ...media,
  },
  font: {
    size: {
      '12px': '12px',
      '14px': '14px',
      '16px': '16px',
      '20px': '20px',
      '26px': '26px',
      '28px': '28px',
      '40px': '40px',
      '46px': '46px',
    },
    weight: {
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    family: {
      boing: 'Boing, -apple-system, sans-serif',
      messina: 'Messina, -apple-system, sans-serif',
    },
  },
  icon: {
    size: {
      '12px': '12px',
      '14px': '14px',
      '16px': '16px',
      '18px': '18px',
      '20px': '20px',
      '24px': '24px',
      '32px': '32px',
      '42px': '42px',
      '54px': '54px',
      '80px': '80px',
    },
  },
  shadow: {
    lighter:
      '0px 1px 3px rgba(63, 65, 66, 0.12), 0px 1px 2px rgba(63, 65, 66, 0.24)',
    light:
      '0px 3px 6px rgba(63, 65, 66, 0.15), 0px 2px 4px rgba(63, 65, 66, 0.12)',
    normal:
      '0px 10px 20px rgba(63, 65, 66, 0.15), 0px 3px 6px rgba(63, 65, 66, 0.1)',
    bold: '0px 15px 25px rgba(63, 65, 66, 0.15), 0px 5px 10px rgba(63, 65, 66, 0.05)',
    bolder: '0px 20px 40px rgba(63, 65, 66, 0.2)',
  },
  space: {
    '2px': '2px',
    '4px': '4px',
    '8px': '8px',
    '10px': '10px',
    '12px': '12px',
    '16px': '16px',
    '24px': '24px',
    '32px': '32px',
    '43px': '43px',
    '46px': '46px',
    '48px': '48px',
    '64px': '64px',
    '96px': '96px',
    '128px': '128px',
    '192px': '192px',
    '256px': '256px',
    '384px': '384px',
    '512px': '512px',
  },
  page: {
    backgroundColor: '248, 249, 250', // Based on #f8f9fa
    heightMobile: '60vh',
    heightTablet: '60vh',
    marginBottom: '0',
    marginTop: '48px',
    maxWidth: '1140px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
};

const fadeIn = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = () => keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fullWidthMixin = () => css`
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
`;

const noPrintMixin = () => css`
  @media print {
    display: none !important;
  }
`;

const pageMixin = () => css`
  margin: 0 auto;
  margin-bottom: ${(props) => props.theme.page.marginBottom};
  margin-top: ${(props) => props.theme.page.marginTop};
  max-width: ${(props) => props.theme.page.maxWidth};
  min-height: ${(props) => props.theme.page.heightMobile};
  position: relative;

  ${(props) => props.theme.device.tablet`
    min-height: ${(props) => props.theme.page.heightTablet};
  `}
`;

const sectionMixin = () => css`
  margin-bottom: ${(props) => props.theme.page.marginTop};
`;

const pageSectionMixin = () => css`
  margin-bottom: ${(props) => props.theme.space['24px']};
`;

export default theme;
export {
  fadeIn,
  fadeOut,
  fullWidthMixin,
  noPrintMixin,
  pageMixin,
  sectionMixin,
  pageSectionMixin,
};

import englishRoutePaths from './en';
import finnishRoutePaths from './fi';
import norwegianRoutePaths from './no';
import swedishRoutePaths from './se';

const ROUTE_PATHS = {
  EN: { ...englishRoutePaths },
  FI: { ...finnishRoutePaths },
  NO: { ...norwegianRoutePaths },
  SE: { ...swedishRoutePaths }
};

export default ROUTE_PATHS[process.env.REACT_APP_COUNTRY_DOMAIN];

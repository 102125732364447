import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components/macro';

import pdfIcon from '../../../../assets/icons/pdf-icon.svg';
import { COUNTRY } from '../../../../constants';
import { useLocale } from '../../../hooks/useLocale';

const StyledAnchorTag = styled.a`
  align-items: center;
  color: ${(props) => props.theme.color.blue};
  display: inline-flex;
  text-decoration: underline;
  word-break: break-word;

  :hover {
    color: ${(props) => props.theme.color.orange};
  }

  :before {
    content: ${(props) => props.isPdf && `url(${pdfIcon})`};
    display: inline-block;
    flex: none;
    height: 31px;
    margin-right: ${(props) => props.theme.space['8px']};
    width: 24px;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.blue};
  transition: color 0.1s ease;
  word-break: break-word;

  :hover {
    color: ${(props) => props.theme.color.orange};
  }
`;

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const AnchorTag = ({ children, href, ...rest }) => {
  const { locale } = useLocale();

  // Anchortag in markdown
  if (rest.id && !href) {
    return <a {...rest}>{children}</a>;
  }
  // Anchortag link
  if (href.startsWith('#')) {
    return (
      <HashLink to={href} {...rest} scroll={(el) => scrollWithOffset(el)}>
        {children}
      </HashLink>
    );
  }

  // mailto or tel link
  if (href.includes('mailto:') || href.includes('tel:')) {
    return (
      <StyledAnchorTag href={href} {...rest}>
        {children}
      </StyledAnchorTag>
    );
  }

  // Internal link with full URL
  if (href.includes(`www.ramirent.${COUNTRY.toLowerCase()}`)) {
    const path = href.split(`.${COUNTRY.toLowerCase()}`)[1];

    return (
      <StyledLink to={path} {...rest}>
        {children}
      </StyledLink>
    );
  }

  // Internal path
  if (!href.includes('.')) {
    return (
      <StyledLink to={`${href}${locale ? '?lang=' + locale : ''}`} {...rest}>
        {children}
      </StyledLink>
    );
  }

  // External link or file
  return (
    <StyledAnchorTag
      download={href.includes('.pdf')}
      isPdf={href.includes('.pdf')}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      {...rest}
    >
      {children}
    </StyledAnchorTag>
  );
};

export default AnchorTag;

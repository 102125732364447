import React from 'react';
import styled from 'styled-components/macro';

import { sectionMixin } from '../../../../../theme';

const StyledRamiBlock = styled.div`
  ${sectionMixin};

  margin-left: auto;
  margin-right: auto;

  ${(props) => props.theme.device.desktop`
    max-width: ${(props) => props.maxWidth};
  `};
`;

const RamiBlock = ({ children, width, ...props }) => (
  <StyledRamiBlock
    maxWidth={width ? width : '720px'}
    {...props}
    data-cy="ramiBlock"
  >
    {children}
  </StyledRamiBlock>
);

export default RamiBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ramiGreen from '../../../../assets/images/RamiGreen_sticker.png';

import BoldText from '../../../components/BoldText';

const StyledSpecifications = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: auto;
  width: 100%;
`;

const SpecificationWrapper = styled.div`
  max-width: 50%;
  overflow: hidden;
`;

const SpecificationName = styled(BoldText)`
  font-size: ${(props) => props.theme.font.size['14px']};
  margin: ${(props) => props.theme.space['4px']};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpecificationValue = styled.div`
  color: ${(props) => props.theme.color.grayDarker};
  font-family: ${(props) => props.theme.font.family.messina};
  font-size: ${(props) => props.theme.font.size['14px']};
  margin: ${(props) => props.theme.space['4px']};
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Divider = styled.div`
  background-color: ${(props) => props.theme.color.grayLight};
  flex: none;
  height: 40px;
  margin: 0 ${(props) => props.theme.space['12px']};
  width: 1px;
`;

const getSecondarySpecification = (specifications, primarySpecification) => {
  return specifications.find(
    (specification) => specification.name !== primarySpecification.name
  );
};

const RamiGreenImage = styled.img`
  height: 45px;
  width: 45px;
`;

const makeValueText = ({ value, unit }) => `${value} ${unit ? unit : ''}`;

const Specifications = ({ specifications, shouldShowRamiGreenIcon }) => {
  const primarySpecification = specifications[0];
  let secondarySpecification = undefined;
  if (specifications) {
    secondarySpecification = getSecondarySpecification(
      specifications,
      primarySpecification
    );
  }

  return (
    <StyledSpecifications>
      <SpecificationWrapper>
        <SpecificationName inline>
          {primarySpecification.name}
        </SpecificationName>
        <SpecificationValue>
          {makeValueText(primarySpecification)}
        </SpecificationValue>
      </SpecificationWrapper>
      {secondarySpecification && (
        <>
          <Divider />
          <SpecificationWrapper>
            <SpecificationName inline>
              {secondarySpecification.name}
            </SpecificationName>
            <SpecificationValue>
              {makeValueText(secondarySpecification)}
            </SpecificationValue>
          </SpecificationWrapper>
        </>
      )}
      {shouldShowRamiGreenIcon && (
        <>
          <Divider />
          <RamiGreenImage src={ramiGreen} alt="RamiGreen" />
        </>
      )}
    </StyledSpecifications>
  );
};

Specifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.object),
};

export default Specifications;

import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { ENVIRONMENT } from '../../constants';

export const GoogleAnalyticsEvent = (category, action, label) => {
  if (ENVIRONMENT === 'PRODUCTION') {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }
};

const GoogleAnalytics = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (ENVIRONMENT === 'PRODUCTION') {
      const page = window.location.pathname + window.location.search;
      ReactGA.set({
        page,
        location: `${window.location.origin}${page}`
      });
      ReactGA.pageview(page);
    }
  }, [pathname]);

  return children;
};

export default GoogleAnalytics;

import { getCompleteSlug } from '../getPages';
import { formatUrl } from '../../../utils/link';
import { COUNTRY, locales } from '../../../constants';

export const getLink = (link) => {
  if (link.fields.link) {
    return formatUrl(
      COUNTRY === 'FI' ? link.fields.link[locales.FI] : link.fields.link
    );
  } else if (link.fields.slug) {
    return getCompleteSlug(link.fields);
  }
};

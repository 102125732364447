import { getCompleteSlug } from '../getPages';
import { formatUrl } from '../../../utils/link';

const formatNavigationBar = (menuOption) => ({
  title: menuOption.fields.displayTitle
    ? menuOption.fields.displayTitle
    : menuOption.fields.title,
  link: menuOption.fields.link && formatUrl(menuOption.fields.link),
  slugList: menuOption.fields.slug && getCompleteSlug(menuOption.fields),
  id: menuOption.sys.id,
});

export const formatNavigationBarCollection = (navigationBarCollection) => ({
  type: 'navigationBar',
  menuOptions:
    navigationBarCollection.menuOption &&
    navigationBarCollection.menuOption.map((option) => {
      return formatNavigationBar(option);
    }),
  title: navigationBarCollection.title,
});

import React from 'react';
import styled from 'styled-components/macro';

const StyledRamiColumn = styled.div`
  border: ${(props) => {
    if (props.border) return `5px dashed ${props.theme.color.grayLight}`;
    return `none`;
  }};
  margin-right: ${(props) => {
    if (props.isLastRamiColumn) return 0;
    return `${props.theme.space['12px']}`;
  }};
  padding: ${(props) => props.theme.space['12px']};
  width: 100%;

  ${(props) => props.theme.device.tablet`
    width: ${(props) => props.width}%;
  `};
`;
const RamiColumn = ({ children, number, isLastRamiColumn, border }) => (
  <StyledRamiColumn
    border={border}
    width={100 / number}
    isLastRamiColumn={isLastRamiColumn}
  >
    {children}
  </StyledRamiColumn>
);

export default RamiColumn;

import styled from 'styled-components/macro';

const UnstyledOrderedList = styled.ol`
  counter-reset: item;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;

  li {
    counter-increment: item;
    list-style-type: none;
  }
`;

const OrderedList = styled(UnstyledOrderedList)`
  color: ${props => props.theme.color.grayDarker};
  font-family: ${props => props.theme.font.family.messina};
  font-size: ${props => props.theme.font.size['16px']};
  font-weight: ${props => props.theme.font.weight.normal};
  padding-bottom: ${props => props.theme.space['16px']};
  padding-left: ${props => props.theme.space['32px']};
  white-space: pre-wrap;

  li:before {
    content: counter(item) '.';
    display: inline-block;
    font-family: sans-serif;
    font-weight: ${props => props.theme.font.weight.semiBold};
    padding-right: ${props => props.theme.space['8px']};
    text-align: right;
  }
`;

export default OrderedList;

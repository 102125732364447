import React from 'react';
import styled from 'styled-components/macro';

import Link from '../../../shared/components/Link';
const StyledSocialMediaLinks = styled.div`
  margin-bottom: ${(props) => props.theme.space['8px']};

  a:not(:last-child) {
    margin-right: ${(props) => props.theme.space['16px']};
  }

  ${(props) => props.theme.device.desktop`
    margin-top: ${(props) => props.theme.space['4px']};
    margin-left: auto;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const SocialMediaIcon = styled.img`
  height: ${(props) => props.theme.icon.size['20px']};
  width: ${(props) => props.theme.icon.size['20px']};
`;

const SocialMediaLinks = (socialMediaLinks) => {
  const list = socialMediaLinks;
  return (
    <StyledSocialMediaLinks data-cy="socialMediaLinks">
      {(list?.links || []).map((item, i) => (
        <Link key={item.link + i} url={item.link}>
          <SocialMediaIcon alt={item.image.title} src={item.image.url} />
        </Link>
      ))}
    </StyledSocialMediaLinks>
  );
};

export default SocialMediaLinks;

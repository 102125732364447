import { useClient } from '@splitsoftware/splitio-react';

export const useFeatureFlags = () => {
  const client = useClient();

  return {
    isFeatureEnabled: (flagName) => client.getTreatment(flagName) === 'on',
    flags: {
      SALES_ITEM_IN_NODE: 'ddt-web_salesItemInNode',
      SCALE_CUSTOMERCENTER: 'ddt-web_scale_customercenter',
      DYNAMICS_MARKETING: 'ddt-web_dynamics_marketing',
    },
  };
};

import {
  formatBanner,
  formatFaqPostCollection,
  formatLink,
  formatList,
  formatProfile,
} from '.';
import { getLink } from '../../../utils/link';
import dateFormat from '../../../utils/dateFunctions';

export const PageStyle = {
  ARTICLE: 'Article',
  DEFAULT: 'Default',
  PROMOTION: 'Promotion',
};

export const PageTitleStyle = {
  BLUE_HEADER: 'Blue header',
  TEXT: 'Text',
  NONE: 'Hide title',
};

const formatComponentsField = (components, locale, parentSlug) => {
  return components
    .map((component) => {
      if (!component.sys.contentType || !component.sys.contentType.sys) {
        return null;
      }
      switch (component.sys.contentType.sys.id) {
        case 'list':
          const listComponents = component.fields.components
            ? formatComponentsField(component.fields.components, locale)
            : [];
          return {
            parentSlug,
            ...formatList(component.sys.id, component.fields),
            components: listComponents,
          };
        case 'banner':
          return formatBanner(component.fields);
        case 'profile':
          return formatProfile(component.fields);
        case 'faqPosts':
          return formatFaqPostCollection(component.fields);
        case 'link':
          return formatLink(component.fields, locale);
        case 'page':
          return formatPage(component.fields, locale);
        default:
          return null;
      }
    })
    .filter((comp) => comp != null);
};

const findImageInComponents = (components) => {
  const imageBanners = components.filter(
    (comp) =>
      comp.type === 'banner' && comp.mediaImages && comp.mediaImages.length
  );
  if (imageBanners[0]) {
    return imageBanners[0].mediaImages[0];
  }
  const listComponentsWithImageThumb = components
    .filter((component) => component.type === 'list')
    .reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue.components);
    }, [])
    .filter((component) => component.imageThumb);
  return listComponentsWithImageThumb[0]
    ? listComponentsWithImageThumb[0].images[0]
    : null;
};

const addPageParentPages = (parent, referenceArray) => {
  // Include in getEntries query determine how many levels a page can have.
  // Contentful allows value to be between 0-10. Currently set to 10.
  const parentPage = parent.fields.parentPage;
  if (parentPage && parentPage.fields) {
    referenceArray.push({
      slug: parentPage.fields.slug,
      title: parentPage.fields.displayTitle
        ? parentPage.fields.displayTitle
        : parentPage.fields.title,
    });
    return addPageParentPages(parentPage, referenceArray);
  }
  return referenceArray;
};

export const formatParentPages = (parent, referenceArray) => {
  const pageParentPages = addPageParentPages(parent, []).reverse();
  return pageParentPages.map((parentPage, index) => ({
    ...parentPage,
    parentSlug:
      index === 0
        ? `/${parentPage.slug}`
        : `/${pageParentPages
            .slice(0, index)
            .map((page) => page.slug)
            .join('/')}/${parentPage.slug}`,
  }));
};

export const getParentSlug = (parents) =>
  parents.length !== 0
    ? `/${parents.map((parent) => parent.slug).join('/')}/`
    : '/';

export const formatPage = (page, locale) => {
  const components = page.components
    ? formatComponentsField(page.components, locale, page.slug)
    : [];

  let image = page.seoImage
    ? {
        title: page.seoImage.fields.title,
        url: `https:${page.seoImage.fields.file.url}`,
      }
    : findImageInComponents(components);

  const parents = formatParentPages({ fields: { ...page } }, []);
  const parentSlug = page.parentPage ? getParentSlug(parents) : '/';

  return {
    type: 'page',
    copyArray: page.copy ? page.copy.split('<rami-insert-component />') : [],
    date: page.date && dateFormat(new Date(page.date)),
    seoTitle: page.seoTitle,
    seoDescription: page.seoDescription,
    seoImage: image && `${image.url}?fm=jpg&q=70&w=1140&fit=thumb`,
    images: image && [image],
    parents: parents,
    slug: page.slug,
    completeSlug: getLink(`${parentSlug}${page.slug}`, locale),
    style: page.style ? page.style : PageStyle.DEFAULT,
    teaser: page.teaser,
    title: page.displayTitle ? page.displayTitle : page.title,
    titleStyle: page.titleStyle ? page.titleStyle : PageTitleStyle.BLUE_HEADER,
    showBreadcrumbs: page.showBreadcrumbs,
    components: components,
    jsonLdSchema: page.jsonLdSchema,
  };
};

import dateFormat from '../../../utils/dateFunctions';
import { getBasePathFromType } from '../../../utils/link';

export const formatNewsPost = (newsPost) => ({
  type: 'newsPost',
  author: newsPost.author && {
    email: newsPost.author.fields.email,
    image: {
      url: `https:${newsPost.author.fields.image.fields.file.url}`,
      title: newsPost.author.fields.image.fields.title,
    },
    name: newsPost.author.fields.name,
    role: newsPost.author.fields.role,
  },
  copy: newsPost.copy,
  date: dateFormat(new Date(newsPost.date)),
  images:
    newsPost.images &&
    newsPost.images.map((image) => {
      return {
        url: `https:${image.fields.file.url}`,
        title: image.fields.title,
      };
    }),
  imageThumb:
    newsPost.images &&
    `https:${newsPost.images[0].fields.file.url}?fm=jpg&q=70&w=1140&fit=thumb`,
  primaryLink: newsPost.primaryLink,
  primaryLinkText: newsPost.primaryLinkText,
  slug: newsPost.slug,
  completeSlug: `${getBasePathFromType('newsPost')}${newsPost.slug}`,
  teaser: newsPost.teaser,
  title: newsPost.title,
});

import React from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components/macro';

import { POST_CONTACT_FORM } from '../../../api/graphQL/queries/postContactForm';
import closeIconBlue from '../../../assets/icons/close_icon.svg';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import Header3 from '../../components/Header3';
import Loader from '../../components/Loader';
import Paragraph from '../../components/Paragraph';
import logError from '../../../utils/logger';
import translations from '../../../translations';
import { SendButtonWrapper, FormMessage } from './components/FormMessage';

const FormWrapper = styled.div`
  background-color: ${(props) => props.theme.color.yellow};
  margin-top: 160px;
  max-height: 65vh;
  overflow: auto;
  max-width: 100vw;
  z-index: 8;
  ${(props) => props.theme.device.tablet`
    width: 400px;
  `};
`;

const Form = styled.form`
  padding: ${(props) => props.theme.space['16px']};
  padding-top: 0;
`;

const Required = styled.span`
  color: ${(props) => props.theme.color.redDark};
`;

const FormLabel = styled.label`
  color: ${(props) => (props.error ? props.theme.color.redDark : 'black')};
`;

const FormHeader = styled(Header3)`
  position: sticky;
  z-index: 7;
  top: 0;
  padding-top: ${(props) => props.theme.space['24px']};
  background-color: ${(props) => props.theme.color.yellow};
`;

const CloseButton = styled(Button)`
  background-image: url(${closeIconBlue});
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  border-radius: ${(props) => props.theme.borderRadius.larger};
  height: 20px;
  position: absolute;
  right: ${(props) => props.theme.space['8px']};
  top: ${(props) => props.theme.space['24px']};
  width: 20px;
  z-index: 10;

  ${(props) => props.theme.device.tablet`
    background-image: url(${closeIconBlue});
    background-size: 20px 20px;
    border-radius: 0;
  `};
`;

const StyledInput = styled(Input)`
  line-height: 1.5em;
  margin: ${(props) => props.theme.space['4px']} 0;
  width: 100%;
  ${(props) =>
    props.error ? 'border: 2px solid ' + props.theme.color.redDark : ''}
`;

const StyledTextarea = styled(Textarea)`
  margin: ${(props) => props.theme.space['4px']} 0 0 0;
  width: 100%;
  max-width: 100%;
  ${(props) =>
    props.error ? 'border: 2px solid ' + props.theme.color.redDark : ''}
`;

const ErrorMessage = styled.div`
  margin-top: -4;
  font-size: ${(props) => props.theme.font.size['12px']};
  color: ${(props) => props.theme.color.redDark};
  min-height: 14px;
`;

const LoaderWrapper = styled.div`
  padding: ${(props) => props.theme.space['48px']};
`;

const ContactForm = ({ closeContactForm }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [submitContactForm, { loading, data, error }] = useLazyQuery(
    POST_CONTACT_FORM
  );

  const submitForm = async (formData) => {
    try {
      submitContactForm({
        variables: formData,
      });
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  const closeForm = (e) => {
    if (e) e.preventDefault();
    closeContactForm();
  };

  return (
    <FormWrapper onClick={(e) => e.stopPropagation()} data-cy="contactForm">
      <Form onSubmit={handleSubmit(submitForm)}>
        <FormHeader blue>
          {translations.contactUs}
          <CloseButton onClick={closeForm} data-cy="contactFormCloseButton" />
        </FormHeader>
        {isSubmitSuccessful &&
          data &&
          data.sendEmailToCustomerService === 'ok' && (
            <FormMessage
              title={translations.contactForm.successTitle}
              buttonAction={closeForm}
            >
              <Paragraph>{translations.contactForm.successMessage}</Paragraph>
            </FormMessage>
          )}
        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {error && error.message && (
          <FormMessage
            title={translations.contactForm.errorTitle}
            buttonAction={closeForm}
          >
            <Paragraph>{translations.contactForm.errorMessage}</Paragraph>
            <Paragraph>{error.message}</Paragraph>
          </FormMessage>
        )}
        {!isSubmitSuccessful && (
          <>
            <FormLabel>
              {translations.contactForm.email}
              <Required> *</Required>
              <StyledInput
                placeholder={translations.contactForm.email}
                type="email"
                error={errors.email}
                {...register('email', {
                  required: translations.contactForm.rules.required,
                  maxLength: {
                    value: 50,
                    message:
                      translations.contactForm.email +
                      ' ' +
                      translations.contactForm.rules.max50,
                  },
                })}
              />
            </FormLabel>
            <ErrorMessage>{errors.email && errors.email.message}</ErrorMessage>

            <FormLabel>
              {translations.contactForm.name}
              <Required> *</Required>

              <StyledInput
                placeholder={translations.contactForm.name}
                error={errors.name}
                {...register('name', {
                  required: translations.contactForm.rules.required,
                  pattern: {
                    value: /^(\p{L})+(((\p{L})+)*( (\p{L})+)+)+$/u,
                    message: translations.contactForm.rules.fullName,
                  },
                  maxLength: {
                    value: 50,
                    message:
                      translations.contactForm.name +
                      ' ' +
                      translations.contactForm.rules.max50,
                  },
                })}
              />
            </FormLabel>
            <ErrorMessage>{errors.name && errors.name.message}</ErrorMessage>

            <FormLabel>
              {translations.contactForm.phone}
              <Required error={errors.phone}> *</Required>
              <StyledInput
                placeholder={translations.contactForm.phone}
                error={errors.phone}
                {...register('phone', {
                  required: translations.contactForm.rules.required,
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                    message: translations.contactForm.rules.phone,
                  },
                  maxLength: {
                    value: 50,
                    message:
                      translations.contactForm.phone +
                      ' ' +
                      translations.contactForm.rules.max50,
                  },
                })}
              />
            </FormLabel>
            <ErrorMessage>{errors.phone && errors.phone.message}</ErrorMessage>

            <React.Fragment>
              <FormLabel>
                {translations.contactForm.location}
                <Required error={errors.location}> *</Required>
                <StyledInput
                  placeholder={translations.contactForm.location}
                  {...register('location', {
                    required: translations.contactForm.rules.required,
                  })}
                />
              </FormLabel>
              <ErrorMessage>
                {errors.location && errors.location.message}
              </ErrorMessage>
            </React.Fragment>

            <FormLabel>
              {translations.contactForm.yourMessage}
              <Required> *</Required>
              <StyledTextarea
                type="textarea"
                multiline
                placeholder={translations.contactForm.yourMessage}
                error={errors.message}
                {...register('message', {
                  required: translations.contactForm.rules.required,
                  minLength: {
                    value: 6,
                    message:
                      translations.contactForm.yourMessage +
                      ' ' +
                      translations.contactForm.rules.least6,
                  },
                  maxLength: {
                    value: 500,
                    message:
                      translations.contactForm.yourMessage +
                      ' ' +
                      translations.contactForm.rules.max500,
                  },
                })}
              />
            </FormLabel>
            <ErrorMessage>
              {errors.message && errors.message.message}
            </ErrorMessage>

            <SendButtonWrapper>
              <Button type="submit" name="button">
                {translations.contactForm.send}
              </Button>
            </SendButtonWrapper>
          </>
        )}
      </Form>
    </FormWrapper>
  );
};

export default ContactForm;

import { formatParentPages, getParentSlug } from './Page';
import { getLink, getBasePathFromType } from '../../../utils/link';

export const formatLink = (link, locale) => {
  if (!link.pageReference && !link.link) {
    return null;
  }

  let linkUrl = link.link && link.link;
  let parentSlugUrl = '';
  if (link.pageReference) {
    parentSlugUrl =
      link.pageReference.sys.contentType.sys.id === 'page'
        ? getParentSlug(formatParentPages(link.pageReference, []))
        : getBasePathFromType(link.pageReference.sys.contentType.sys.id);
    linkUrl = link.pageReference.fields.slug;
  }

  return {
    images: link.media && [
      {
        url: `https:${link.media.fields.file.url}`,
        title: link.media.fields.title,
      },
    ],
    title: link.title,
    teaser: link.teaser,
    slug: linkUrl,
    completeSlug: getLink(`${parentSlugUrl}${linkUrl}`, locale),
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Header3 from './Header3';
import Paragraph from './Paragraph';
import { ImageType, ImageLink } from './Image/Image';

const Content = styled.div`
  background: ${(props) => {
    if (props.noGradient) return 'none';
    return 'linear-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.8))';
  }};
  border-radius: ${(props) => props.theme.borderRadius.medium};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: ${(props) => props.theme.space['12px']}
    ${(props) => props.theme.space['24px']};
`;

const StyledSmallImageCard = styled(ImageLink)`
  flex: 1;
  height: 200px;
  margin: ${(props) => props.theme.space['4px']};
  position: relative;
  width: 100%;

  ${(props) => props.theme.device.tablet`
    height: 250px;
    margin: 0.5%;
  `}
`;

const SmallImageCard = ({
  className,
  image,
  link,
  teaser,
  title,
  ...props
}) => (
  <StyledSmallImageCard
    image={{
      image: {
        url: image,
      },
      type: ImageType.RECTANGLE_SMALL,
    }}
    url={link}
    className={className}
  >
    <Content {...props}>
      <Header3 white as="div">
        {title}
      </Header3>
      {teaser && <Paragraph white>{teaser}</Paragraph>}
    </Content>
  </StyledSmallImageCard>
);

SmallImageCard.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  teaser: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SmallImageCard;

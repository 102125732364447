import React, { createContext, useReducer } from 'react';

const PageContext = createContext();

const initialState = {};

export const initialStateForAList = {
  error: false,
  loading: true,
  loadMore: false,
  pages: [],
  selectedFilterOption: undefined,
  totalPages: undefined,
  data: {},
  filterOptions: [],
  filterOptionsLoading: true,
};

export const INITIALIZE_LIST = 'initializeList';
export const SET_ERROR = 'setError';
export const SET_LOADING = 'setLoading';
export const SET_LOAD_MORE = 'setLoadMore';
export const SET_LIST_PAGES = 'setListPages';
export const SET_PAGES = 'setPages';
export const SET_FILTER_OPTIONS = 'setFilterOptions';
export const CHANGE_FILTER = 'changeFilter';

const reducer = (state, action) => {
  const { id } = action.payload;
  if (!state[id] && action.type !== INITIALIZE_LIST) {
    return state;
  }

  switch (action.type) {
    case INITIALIZE_LIST:
      state[id] = {
        ...initialStateForAList,
        data: action.payload.data,
      };
      return { ...state };

    case SET_LOADING:
      state[id] = {
        ...state[id],
        loading: true,
      };
      return { ...state };

    case SET_LOAD_MORE:
      state[id] = {
        ...state[id],
        loadMore: true,
      };
      return { ...state };

    case SET_LIST_PAGES:
      state[id] = {
        ...state[id],
        loading: false,
        pages: action.payload.pages,
        totalPages: action.payload.totalPages,
      };
      return { ...state };

    case SET_PAGES:
      const pageList = [...state[id].pages, ...action.payload.pages];
      state[id] = {
        ...state[id],
        loading: false,
        loadMore: false,
        totalPages: action.payload.totalPages,
        pages: pageList,
      };
      return { ...state };

    case SET_ERROR:
      state[id] = {
        ...state[id],
        error: true,
        loading: false,
      };
      return { ...state };

    case SET_FILTER_OPTIONS:
      state[id] = {
        ...state[id],
        filterOptions: action.payload.filterOptions,
        filterOptionsLoading: false,
      };
      return { ...state };

    case CHANGE_FILTER:
      if (
        state[id].selectedFilterOption &&
        action.payload.filterOption.value ===
          state[id].selectedFilterOption.value
      ) {
        return state;
      }
      state[id] = {
        ...state[id],
        loading: true,
        pages: [],
        selectedFilterOption: action.payload.filterOption,
      };
      return { ...state };

    default:
      return state;
  }
};

const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider };

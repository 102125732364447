import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components/macro';

import { fullWidthMixin } from '../../theme';
import { isMediaFromContentful } from '../../utils/mediaFunctions';

const StyledWrapper = styled.div`
  ${fullWidthMixin};
`;

const StyledVideo = styled.video`
  object-fit: cover;
  height: 550px;
`;

const getPlayer = (url, videoSetting) => {
  if (isMediaFromContentful(url)) {
    return (
      <StyledVideo
        controls
        name="media"
        id="myVid"
        width="100%"
        height="400"
        autoPlay={videoSetting.mediaAutoplay}
        muted={videoSetting.mediaAutoplay}
        loop={videoSetting.mediaLoop}
      >
        <source src={url} type="video/mp4" />
      </StyledVideo>
    );
  }
  return (
    <ReactPlayer
      controls
      url={url}
      width="100%"
      height="100%"
      playing={videoSetting.mediaAutoplay}
      muted={videoSetting.mediaAutoplay}
      loop={videoSetting.mediaLoop}
    />
  );
};

const VideoPlayer = ({ url, fullWidth, style, videoSetting }) => (
  <>
    {fullWidth ? (
      <StyledWrapper style={style}>
        {getPlayer(url, videoSetting)}
      </StyledWrapper>
    ) : (
      <>{getPlayer(url, videoSetting)}</>
    )}
  </>
);

export default VideoPlayer;

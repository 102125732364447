import React, { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { withSplitFactory } from '@splitsoftware/splitio-react';
import styled from 'styled-components/macro';

import { COUNTRY, FACEBOOK_DOMAIN_VERIFICATION_CODE } from './constants';
import routes from './routes';
import ContactBar from './shared/components/ContactBar';
import Overlay from './shared/components/Overlay';
import Footer from './views/footer/Footer';
import NavigationBar from './views/navigationBar/NavigationBar';
import Sidebar from './views/sidebar/Sidebar';
import ContactForm from './shared/views/contactForm/ContactForm';
import { splitconfig } from './clients/split';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const PageWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 80vh;
  padding-left: ${(props) => props.theme.page.paddingLeft};
  padding-right: ${(props) => props.theme.page.paddingRight};
  position: relative;
  width: 100%;
`;

const App = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  const metaTags =
    COUNTRY === 'SE' || COUNTRY === 'NO'
      ? [
          {
            name: 'facebook-domain-verification',
            content: FACEBOOK_DOMAIN_VERIFICATION_CODE,
          },
        ]
      : [];

  const handleHamburgerMenuClick = () => setSideBarOpen(!sideBarOpen);
  const handleMenuClose = () => setSideBarOpen(false);

  return (
    <AppWrapper>
      <Helmet
        htmlAttributes={{ lang: COUNTRY.toLowerCase() }}
        meta={metaTags}
      />
      <Overlay visible={sideBarOpen} onClick={handleMenuClose} />
      <Overlay
        visible={contactFormOpen}
        onClick={() => setContactFormOpen(false)}
      >
        <ContactForm closeContactForm={() => setContactFormOpen(false)} />
      </Overlay>
      <NavigationBar
        onHamburgerMenuClick={handleHamburgerMenuClick}
        openContactForm={() => setContactFormOpen(true)}
      />
      <Sidebar open={sideBarOpen} onClose={handleMenuClose} />
      <PageWrapper>
        <Suspense fallback={<></>}>
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  element={route.component}
                  exact={!route.matchAllSubRoutes}
                  path={route.path}
                />
              );
            })}
          </Routes>
        </Suspense>
      </PageWrapper>
      <ContactBar />
      <Footer />
    </AppWrapper>
  );
};

export default withSplitFactory(splitconfig)(App);

import React from 'react';
import * as Sentry from '@sentry/browser';

// Cannot use functional component since there isn't any hook for componentDidCatch
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

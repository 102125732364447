import React from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { COUNTRY } from '../../../constants';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

import AnchorTag from './components/AnchorTag';
import Blockquote from '../../components/Blockquote';
import BoldText from '../../components/BoldText';
import Header3 from '../../components/Header3';
import Header4 from '../../components/Header4';
import HorizontalRule from '../../components/HorizontalRule';
import Image from './components/Image';
import CopyImage from './components/CopyImage';
import OrderedList from '../../components/OrderedList';
import Paragraph from '../../components/Paragraph';
import Table from '../../components/Table';
import UnorderedList from '../../components/UnorderedList';

import HubspotFormComponent from './components/HubspotFormComponent';

import RamiCustomerCentersMap from './containers/RamiCustomerCentersMap';
import RamiFaq from './containers/RamiFaq';
import RamiProducts from './containers/RamiProducts';
import RamiLoadMoreButtonContainer from './containers/RamiLoadMoreButtonContainer';
import RamiDropdownFilterContainer from './containers/RamiDropdownFilterContainer';

import RamiButton from './components/links/RamiButton';
import RamiBlock from './components/layout/RamiBlock';
import RamiColumn from './components/layout/RamiColumn';
import RamiColumns from './components/layout/RamiColumns';
import RamiHeader from './components/RamiHeader';
import RamiImage from './components/RamiImage';
import RamiLink from './components/links/RamiLink';
import RamiPromotionLink from './components/links/RamiPromotionLink';
import RamiContentContainer from './components/layout/RamiContentContainer';
import DynamicsMarketingForm from './components/DynamicsMarketingForm';
import VideoPlayer from '../../components/VideoPlayer';

// Replace the smaller headers in markdown with BoldText
const defaultOverrides = {
  a: {
    component: AnchorTag,
  },
  blockquote: {
    component: Blockquote,
  },
  h1: {
    component: Header3,
    props: {
      as: 'h2',
    },
  },
  h2: {
    component: Header4,
    props: {
      as: 'h2',
    },
  },
  h3: {
    component: BoldText,
    props: {
      as: 'h3',
    },
  },
  h4: {
    component: BoldText,
  },
  h5: {
    component: BoldText,
  },
  h6: {
    component: BoldText,
  },
  hr: {
    component: HorizontalRule,
  },
  img: {
    component: Image,
  },
  copyimage: {
    component: CopyImage,
  },
  ol: {
    component: OrderedList,
  },
  p: {
    component: Paragraph,
  },
  span: {
    component: Paragraph,
  },
  table: {
    component: Table,
  },
  ul: {
    component: UnorderedList,
  },
  hubspotform: {
    component: HubspotFormComponent,
  },
  'rami-button': {
    component: RamiButton,
  },
  'rami-load-more-button': {
    component: RamiLoadMoreButtonContainer,
  },
  'rami-dropdown-filter': {
    component: RamiDropdownFilterContainer,
  },
  'rami-block': {
    component: RamiBlock,
  },
  'rami-columns': {
    component: RamiColumns,
  },
  'rami-column': {
    component: RamiColumn,
  },
  'rami-customer-centers-map': {
    component: RamiCustomerCentersMap,
  },
  'rami-faq': {
    component: RamiFaq,
  },
  'rami-header': {
    component: RamiHeader,
  },
  'rami-image': {
    component: RamiImage,
  },
  'rami-link': {
    component: RamiLink,
  },
  'rami-promotion-link': {
    component: RamiPromotionLink,
  },
  'rami-products': {
    component: RamiProducts,
  },
  'rami-video': {
    component: VideoPlayer,
  },
  'rami-content-container': {
    component: RamiContentContainer,
  },
};

const CustomMarkdown = ({ children, extraOverrides = {} }) => {
  const { isFeatureEnabled, flags } = useFeatureFlags();

  const dynamicsMarketingOverride = {
    'dynamics-marketing-form': {
      component:
        COUNTRY === 'FI' &&
        isFeatureEnabled(flags.DYNAMICS_MARKETING) &&
        DynamicsMarketingForm,
    },
  };

  return (
    <Markdown
      options={{
        overrides: {
          ...defaultOverrides,
          ...dynamicsMarketingOverride,
          ...extraOverrides,
        },
        // TODO: This did not work, eg Tables stopped working.
        // createElement(type, props, children) {
        //   // Cleaning <p> tags from image since cannot have a <div> inside a <p>
        //   if (
        //     type.displayName === 'Paragraph' &&
        //     children &&
        //     children[0] &&
        //     typeof children[0] === 'object' &&
        //     children[0].type.name === 'Image'
        //   ) {
        //     const childrenProps = { ...children[0].props };
        //     childrenProps.key = props.key;
        //     return React.createElement(
        //       children[0].type,
        //       childrenProps,
        //       children[0].children
        //     );
        //   }
        //   return React.createElement(type, props, children);
        // },
      }}
    >
      {children}
    </Markdown>
  );
};

CustomMarkdown.propTypes = {
  extraOverrides: PropTypes.object,
};

export default CustomMarkdown;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BoldText from '../../../components/BoldText';
import Paragraph from '../../../components/Paragraph';
import shorten from '../../../../utils/shorten';

const StyledIconSuggestion = styled.div`
  align-items: center;
  display: flex;
  padding: ${props => props.theme.space['12px']} 0;
  padding-right: ${props => props.theme.space['24px']};
  width: 100%;
`;

const Text = styled(Paragraph)`
  display: none;

  ${props => props.theme.device.tablet`
    display: initial;
  `};
`;

const Image = styled.img`
  flex: none;
  height: 60px;
  margin-right: ${props => props.theme.space['12px']};
  width: 60px;
`;

const IconSuggestion = ({ image, teaser, title }) => (
  <StyledIconSuggestion>
    {image && <Image alt={title} src={`${image}?fm=jpg&q=70&w=120`} />}
    <div>
      <BoldText inline>{shorten(title, 50)}</BoldText>
      {teaser && <Text inline>{teaser}</Text>}
    </div>
  </StyledIconSuggestion>
);

IconSuggestion.propTypes = {
  image: PropTypes.string,
  teaser: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default IconSuggestion;

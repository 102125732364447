import { gql } from '@apollo/client';

const GET_CUSTOMER_CENTERS = gql`
  query Locations($country: Country!, $type: LocationType) {
    locations(country: $country, type: $type) {
      address
      coords {
        lat
        lng
      }
      description
      shortDescription
      email
      id
      name
      phoneNumber
      slug
      openHours {
        mon {
          span {
            open
            close
          }
          state
        }
        tue {
          span {
            open
            close
          }
          state
        }
        wed {
          span {
            open
            close
          }
          state
        }
        thu {
          span {
            open
            close
          }
          state
        }
        fri {
          span {
            open
            close
          }
          state
        }
        sat {
          span {
            open
            close
          }
          state
        }
        sun {
          span {
            open
            close
          }
          state
        }
      }
    }
  }
`;

export default GET_CUSTOMER_CENTERS;

import React from 'react';
import styled from 'styled-components/macro';

import {
  GOOGLE_ANALYTICS_CATEGORY_LINK,
  GOOGLE_ANALYTICS_LABEL_RAMI_TAG,
} from '../../../../../constants';
import { GoogleAnalyticsEvent } from '../../../../components/GoogleAnalytics';
import ButtonLink from '../../../../components/ButtonLink';
import ArrowButton from './ArrowButton';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => {
    if (props.center) return `center`;
    return `flex-start`;
  }};
  margin-bottom: ${(props) => props.theme.space['16px']};

  ${(props) => {
    if (props.width) return `width: ${props.width};`;
  }}
`;

const RamiButton = ({ children, center, arrow, secondary, ...props }) => {
  const handleButtonClick = () => {
    if (children && children[0]) {
      GoogleAnalyticsEvent(
        GOOGLE_ANALYTICS_CATEGORY_LINK,
        `Clicked ${children[0]}`,
        GOOGLE_ANALYTICS_LABEL_RAMI_TAG('rami-button')
      );
    }
  };

  if (arrow) {
    return (
      <StyledWrapper center={center} data-cy="ramiButton">
        {!secondary && (
          <ArrowButton {...props} primary onClick={() => handleButtonClick()}>
            {children}
          </ArrowButton>
        )}
        {secondary && (
          <ArrowButton {...props} onClick={() => handleButtonClick()}>
            {children}
          </ArrowButton>
        )}
      </StyledWrapper>
    );
  }
  return (
    <StyledWrapper center={center}>
      {!secondary && (
        <ButtonLink {...props} primary onClick={() => handleButtonClick()}>
          {children}
        </ButtonLink>
      )}
      {secondary && (
        <ButtonLink {...props} onClick={() => handleButtonClick()}>
          {children}
        </ButtonLink>
      )}
    </StyledWrapper>
  );
};

export default RamiButton;

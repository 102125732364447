import React from 'react';
import styled from 'styled-components/macro';

import errorIcon from '../../assets/icons/error_icon.svg';
import Header2 from './Header2';
import Paragraph from './Paragraph';
import translations from '../../translations';

const StyledDataError = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius.medium};
  box-shadow: ${props => props.theme.shadow.normal};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 280px;
  padding: ${props => props.theme.space['24px']};
  text-align: center;

  ${props => props.theme.device.tablet`
    flex-direction: row;
    justify-content: center;
    text-align: left;
    width: 100%;
  `};
`;

const ErrorIcon = styled.img`
  height: ${props => props.theme.icon.size['80px']};
  margin-bottom: ${props => props.theme.space['12px']};

  ${props => props.theme.device.tablet`
    margin-right: ${props => props.theme.space['24px']};
    margin-bottom: 0;
  `}
`;

const DataError = ({ className }) => (
  <StyledDataError className={className}>
    <ErrorIcon src={errorIcon} />
    <div>
      <Header2 as="div">{translations.dataErrorBanner.title}</Header2>
      <Paragraph as="div" inline>
        {translations.dataErrorBanner.description}
      </Paragraph>
    </div>
  </StyledDataError>
);

export default DataError;

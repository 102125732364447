import React from 'react';
import styled from 'styled-components/macro';

import Skeleton from '../../../components/Skeleton';

const StyledSkeletonSideBar = styled.div`
  height: 100%;
  overflow: hidden;
`;

const CustomerCenterSearch = styled(Skeleton)`
  border-bottom: ${props => props.theme.border['1px']}
    ${props => props.theme.color.grayLight};
  height: 52px;
  padding: 12px;
`;

const FilterOptions = styled.div`
  align-items: center;
  border-bottom: ${props => props.theme.border['1px']}
    ${props => props.theme.color.grayLight};
  display: flex;
  height: 58px;
  position: relative;
`;

const FilterButton = styled(Skeleton)`
  border-radius: ${props => props.theme.borderRadius.small};
  flex-grow: 1;
  height: 45px;
  margin: 0 ${props => props.theme.space['8px']};
  width: 150px;
`;

const CustomerCenterList = styled.div`
  display: block;
`;

const CustomerCenter = styled.div`
  border-bottom: ${props => props.theme.border['1px']}
    ${props => props.theme.color.grayLight};
  height: 191px;
  margin: 0 ${props => props.theme.space['8px']};
  padding: ${props => props.theme.space['24px']}
    ${props => props.theme.space['12px']};
  width: 100%;

  ${props => props.theme.device.tablet`
    height: 160px;
  `}
`;

const Title = styled(Skeleton)`
  margin-bottom: 10px;
  max-width: 150px;
`;

const AddressSection = styled.div`
  margin-bottom: ${props => props.theme.space['8px']};
  max-width: 240px;
`;

const ContactSection = styled.div`
  margin-bottom: 6px;
  max-width: 150px;

  ${props => props.theme.device.tablet`
    margin-bottom: 0;
  `}
`;

const Link = styled(Skeleton)`
  margin-bottom: ${props => props.theme.space['24px']};
  max-width: 170px;

  ${props => props.theme.device.tablet`
    display: none;
  `}
`;

const SkeletonSideBar = () => {
  const ListObjects = () => {
    const objects = [];
    for (let i = 0; i < 4; i++) {
      objects.push(
        <CustomerCenter key={i}>
          <Title type={'header3'} />
          <AddressSection>
            <Skeleton type={'paragraph'} />
            <Skeleton type={'paragraph'} />
          </AddressSection>
          <ContactSection>
            <Skeleton type={'paragraph'} />
            <Skeleton type={'paragraph'} />
          </ContactSection>
          <Link type={'header3'} />
        </CustomerCenter>
      );
    }
    return objects;
  };

  return (
    <StyledSkeletonSideBar>
      <CustomerCenterSearch color={'white'} />
      <FilterOptions>
        <FilterButton />
        <FilterButton />
      </FilterOptions>
      <CustomerCenterList>
        <ListObjects />
      </CustomerCenterList>
    </StyledSkeletonSideBar>
  );
};

export default SkeletonSideBar;

const groupPages = (pages) => {
  const pagesByParentObject = {};
  pages.forEach((page) => {
    if (pagesByParentObject[page.mainTitle]) {
      pagesByParentObject[page.mainTitle].pages.push(page);
    } else {
      pagesByParentObject[page.mainTitle] = {
        title: page.mainTitle,
        pages: [page],
      };
    }
  });
  return Object.values(pagesByParentObject);
};

export { groupPages };

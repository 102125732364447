import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { fadeIn } from '../../theme';

const bouncing = keyframes`
  from {
    transform: translateY(10px) scale(1, 1);
  }
  to {
    transform: translateY(-10px) scale(1, 1);
  }
`;

const bouncingSmall = keyframes`
  from {
    transform: translateY(7px) scale(0.7, 0.7);
  }
  to {
    transform: translateY(-7px) scale(0.7, 0.7);
  }
`;

const StyledLoader = styled.div`
  align-items: center;
  animation: ${fadeIn} ease-in 0.7s;
  display: flex;
  height: 30px;
  justify-content: space-between;
  left: 50%;
  margin-left: ${props => (props.small ? '-20px' : '-30px')};
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: ${props => (props.small ? '40px' : '60px')};

  & div {
    background-color: ${props => props.theme.color.blue};
    border-radius: 50%;
    height: ${props => props.theme.space['12px']};
    width: ${props => props.theme.space['12px']};

    :nth-child(1) {
      animation: ${props => (props.small ? bouncingSmall : bouncing)} 0.4s
        alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
    }

    :nth-child(2) {
      animation: ${props => (props.small ? bouncingSmall : bouncing)} 0.4s 0.1s
        alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
    }

    :nth-child(3) {
      animation: ${props => (props.small ? bouncingSmall : bouncing)} 0.4s 0.2s
        alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
    }
  }
`;

const Loader = ({ className, small }) => (
  <StyledLoader className={className} small={small}>
    <div />
    <div />
    <div />
  </StyledLoader>
);

export default Loader;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';

const colors = {
  blue: '#CFDBF0',
  blueHighlight: '#E0EAFB',
  gray: '#eee',
  grayHighlight: '#f5f5f5'
};

const BackgroundKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const ColorTheme = styled.div`
  ${props => {
    if (props.color === 'blue') {
      return css`
        background-color: ${colors.blue};
        background-image: linear-gradient(
          90deg,
          ${colors.blue},
          ${colors.blueHighlight},
          ${colors.blue}
        );
      `;
    }
    if (props.color === 'white') {
      return css`
        background-color: ${props.theme.color.white};
      `;
    }
    return css`
      background-color: ${colors.gray};
      background-image: linear-gradient(
        90deg,
        ${colors.gray},
        ${colors.grayHighlight},
        ${colors.gray}
      );
    `;
  }};
`;

const StyledSkeleton = styled(ColorTheme)`
  animation: ${BackgroundKeyframes} 1.3s ease-in-out infinite;
  background-repeat: no-repeat;
  background-size: 200px 100%;
  border-radius: ${props => props.theme.borderRadius.small};
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
`;

const Header1 = styled(StyledSkeleton)`
  height: 35px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['24px'])};

  ${props => props.theme.device.tablet`
    height: 55px;
    margin-bottom: ${props => (props.inline ? 0 : props.theme.space['16px'])};
  `};
`;

const Header2 = styled(StyledSkeleton)`
  height: 25px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['24px'])};

  ${props => props.theme.device.tablet`
    height: 35px;
    margin-bottom: ${props => (props.inline ? 0 : props.theme.space['32px'])};
  `};
`;

const Header3 = styled(StyledSkeleton)`
  height: 18px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['12px'])};

  ${props => props.theme.device.tablet`
    height: 20px;
  `};
`;

const Image = styled(StyledSkeleton)`
  height: 375px;
  margin: 0 auto;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['16px'])};
  max-width: 720px;
  width: 100%;

  ${props => props.theme.device.tablet`
    height: 400px;
    margin-bottom: ${props => (props.inline ? 0 : props.theme.space['24px'])};
  `};
`;

const Line = styled(StyledSkeleton)`
  border-radius: ${props => props.theme.borderRadius.smaller};
  height: 8px;
  width: 52px;
`;

const LineLarge = styled(StyledSkeleton)`
  border-radius: ${props => props.theme.borderRadius.smaller};
  height: 8px;
  width: 100px;
`;

const Paragraph = styled(StyledSkeleton)`
  height: 8px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['8px'])};
`;

const Table = styled(StyledSkeleton)`
  height: 200px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['8px'])};

  ${props => props.theme.device.tablet`
    margin-bottom: ${props => props.theme.space['16px']};
  `};
`;

const TextSection = styled.div`
  margin-bottom: ${props => props.theme.space['12px']};
`;

const Skeleton = ({ className, color, inline, type }) => {
  switch (type) {
    case 'header1':
      return <Header1 className={className} color={color} inline={inline} />;

    case 'header2':
      return <Header2 className={className} color={color} inline={inline} />;

    case 'header3':
      return <Header3 className={className} color={color} inline={inline} />;

    case 'image':
      return <Image className={className} color={'white'} inline={inline} />;

    case 'line':
      return <Line className={className} color={color} inline={inline} />;

    case 'lineLarge':
      return <LineLarge className={className} color={color} inline={inline} />;

    case 'paragraph':
      return <Paragraph className={className} color={color} inline={inline} />;

    case 'table':
      return <Table className={className} color={color} inline={inline} />;

    case 'sectionThreeRows':
      return (
        <TextSection className={className} color={color} inline={inline}>
          <Paragraph />
          <Paragraph />
          <Paragraph />
        </TextSection>
      );

    case 'sectionFourRows':
      return (
        <TextSection className={className} color={color} inline={inline}>
          <Paragraph />
          <Paragraph />
          <Paragraph />
          <Paragraph />
        </TextSection>
      );

    default:
      return (
        <StyledSkeleton className={className} color={color} inline={inline} />
      );
  }
};

Skeleton.propTypes = {
  color: PropTypes.string,
  inline: PropTypes.bool,
  type: PropTypes.string
};

export default Skeleton;

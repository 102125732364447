import React from 'react';
import styled from 'styled-components/macro';
import Link from '../../../shared/components/Link';

const StyledSocialMediaLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a:not(:last-child) {
    margin-bottom: ${(props) => props.theme.space['24px']};
  }
`;

const SocialMediaIcon = styled.img`
  height: ${(props) => props.theme.icon.size['20px']};
  width: ${(props) => props.theme.icon.size['20px']};
`;

const SocialMediaLinks = (socialMediaLinks = []) => {
  const list = socialMediaLinks;

  return (
    <StyledSocialMediaLinks>
      {(list?.links || []).map((item) => (
        <Link key={item.fields.link} url={item.fields.link}>
          <SocialMediaIcon
            alt={item.fields.image.fields.title}
            src={item.fields.image.fields.file.url}
          />
        </Link>
      ))}
    </StyledSocialMediaLinks>
  );
};

export default SocialMediaLinks;

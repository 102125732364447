import styled from 'styled-components/macro';

const UnstyledHorizontalRule = styled.hr`
  border-width: 0;
  margin: 0;
  margin-block-end: 0;
  margin-block-start: 0;
`;

const HorizontalRule = styled(UnstyledHorizontalRule)`
  background-color: ${props => props.theme.color.grayLight};
  height: 1px;
  margin-bottom: ${props => (props.inline ? 0 : props.theme.space['16px'])};
`;

export default HorizontalRule;

import React, { lazy, Suspense, useState } from 'react';
import distance from '@turf/distance';
import supported from '@mapbox/mapbox-gl-supported';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import arrowRightIcon from '../../../../assets/icons/arrow_right_blue.svg';
import {
  COMPANY_NAME,
  COUNTRY,
  CUSTOMER_CENTERS_PATH,
  GOOGLE_ANALYTICS_CATEGORY_BUTTON,
  GOOGLE_ANALYTICS_CATEGORY_ICON,
  GOOGLE_ANALYTICS_CATEGORY_INPUT,
  GOOGLE_ANALYTICS_CATEGORY_LIST,
  GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP,
} from '../../../../constants';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Link from '../../../components/Link';
import Loader from '../../../components/Loader';
import Paragraph from '../../../components/Paragraph';
import { GoogleAnalyticsEvent } from '../../../../shared/components/GoogleAnalytics';
import SkeletonSideBar from './SkeletonSideBar';
import translations from '../../../../translations';
import { isIe } from '../../../../utils/browserDetection';
import { isMobile } from '../../../../utils/deviceDetection';

const Map = lazy(() => import('./Map'));
const NoActiveMap = lazy(() => import('./NoActiveMap'));

const StyledMapContent = styled.div`
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-shadow: ${(props) => props.theme.shadow.lighter};
  display: flex;
  height: inherit;
`;

const MapSidebar = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-radius: ${(props) => props.theme.borderRadius.small};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* The map popup has z-index 3 */
  z-index: 4;

  ${(props) => props.theme.device.tablet`
    border-radius: ${(props) => props.theme.borderRadius.small} 0 0
      ${(props) => props.theme.borderRadius.small};
    width: 330px;
  `}
`;

const CustomerCenterSearch = styled(Input)`
  border-radius: ${(props) => props.theme.borderRadius.small}
    ${(props) => props.theme.borderRadius.small} 0 0;
`;

const FilterOptions = styled.div`
  border-bottom: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.grayLight};
  display: flex;
  min-height: 62px;
  position: relative;
`;

const FilterButton = styled(Button)`
  background: rgba(0, 0, 0, 0);
  border-radius: ${(props) => props.theme.borderRadius.small};
  color: ${(props) =>
    props.selected ? props.theme.color.white : props.theme.color.grayDarker};
  flex-grow: 1;
  height: 45px;
  margin: ${(props) => props.theme.space['8px']};
  pointer-events: ${(props) => props.selected && 'none'};
  text-align: center;
  transition: 0.3s ease;
  width: 150px;
  z-index: 2;

  :focus,
  :hover {
    color: ${(props) =>
      props.selected ? props.theme.color.white : props.theme.color.orange};
    outline: ${(props) => props.selected && 'none'};
  }
`;

const SelectedFilterMarker = styled.div`
  background-color: ${(props) => props.theme.color.orange};
  border-radius: ${(props) => props.theme.borderRadius.small};
  cursor: not-allowed;
  height: 45px;
  margin: ${(props) => props.theme.space['8px']};
  position: absolute;
  transform: translateX(${(props) => (props.sortByDistance ? '108%' : '0')});
  transition: 0.7s ease;
  width: 46%;
`;

const LoadingContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;
`;

const CustomerCenterList = styled.div`
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.color.orange};
    border: ${(props) => props.theme.border['4px']} rgba(0, 0, 0, 0);
    border-radius: ${(props) => props.theme.borderRadius.large};
    min-height: 50px;
  }
`;

const CustomerCenter = styled(Button)`
  border-bottom: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.grayLight};
  display: flex;
  justify-content: space-between;
  margin: 0 ${(props) => props.theme.space['8px']};
  padding: ${(props) => props.theme.space['24px']}
    ${(props) => props.theme.space['12px']};
  pointer-events: none;
  width: 100%;

  :focus {
    outline: none;
  }

  ${(props) => props.theme.device.tablet`
    cursor: pointer;
    padding: ${(props) => props.theme.space['24px']}
      ${(props) => props.theme.space['12px']};
    padding-left: ${(props) => props.theme.space['16px']};
    pointer-events: auto;
    transition: background-color 0.3s ease;

    :focus, :hover {
      background-color: ${(props) => props.theme.color.grayLighter};
    }
  `}
`;

const HeaderContent = styled.div`
  margin-bottom: ${(props) => props.theme.space['8px']};
`;

const CustomerCenterName = styled.span`
  color: ${(props) => props.theme.color.grayDarker};
  font-weight: ${(props) => props.theme.font.weight.bold};
`;

const Distance = styled.span`
  color: ${(props) => props.theme.color.grayDark};
  margin-left: ${(props) => props.theme.space['8px']};
  white-space: nowrap;
`;

const ContactInformation = styled.div`
  margin-top: ${(props) => props.theme.space['8px']};
`;

const CustomerCenterLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  margin-top: ${(props) => props.theme.space['12px']};
  pointer-events: auto;
  white-space: nowrap;

  ${(props) => props.theme.device.tablet`
    display: none;
  `}
`;

const ArrowIcon = styled.img`
  height: ${(props) => props.theme.icon.size['18px']};
  margin-left: ${(props) => props.theme.space['8px']};
  width: ${(props) => props.theme.icon.size['18px']};
`;

const MapContainer = styled.div`
  overflow: hidden;
  ${(props) => props.theme.device.tablet`
    flex: 1;
    border-radius: 0 ${(props) => props.theme.borderRadius.small}
      ${(props) => props.theme.borderRadius.small} 0;
  `}
`;

const BrowserInformation = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const countryCenter = {
  EN: {
    longitude: 54.54,
    latitude: 25.19,
  },
  FI: {
    longitude: 21.9765232,
    latitude: 64.8093805,
  },
  NO: {
    longitude: 11.5021114,
    latitude: 64.0020195,
  },
  SE: {
    longitude: 16.321998712,
    latitude: 62.88583179,
  },
};

const MapContent = ({ customerCenters, loading, initialZoom, activeMap }) => {
  const [mapActive, setMapActive] = useState(activeMap);
  const [input, setInput] = useState('');
  const [mapPosition, setMapPosition] = useState({
    center: [countryCenter[COUNTRY].longitude, countryCenter[COUNTRY].latitude],
    zoom: [initialZoom || 3],
  });
  const [selectedCustomerCenter, setSelectedCustomerCenter] = useState(
    undefined
  );
  const [sortByDistance, setSortByDistance] = useState(undefined);
  const [userPosition, setUserPosition] = useState(undefined);
  const [userPositionLoading, setUserPositionLoading] = useState(false);

  const handleSortCustomerCentersClick = () => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_BUTTON,
      'Clicked Sort By Distance',
      GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP
    );

    setSortByDistance(true);

    if ('geolocation' in navigator && !userPosition) {
      setUserPositionLoading(true);
      if (!mapActive) {
        setMapActive(true);
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition([
            position.coords.longitude,
            position.coords.latitude,
          ]);
          setUserPositionLoading(false);
          setMapPosition({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: [10],
          });
        },
        (error) => {
          setSortByDistance(undefined);
          setUserPositionLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 }
      );
    }
  };

  const handleSortCustomerCentersByNameClick = () => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_BUTTON,
      'Clicked Sort By Name',
      GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP
    );

    setSortByDistance(undefined);
  };

  const handleMarkerClick = (customerCenter) => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_ICON,
      'Clicked Map Icon',
      GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP
    );

    setSelectedCustomerCenter(customerCenter);
    setMapPosition({
      center: [customerCenter.coords.lng, customerCenter.coords.lat],
      zoom: [14],
    });
  };

  const handleCustomerCenterHeaderClick = (customerCenter) => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_LIST,
      'Clicked List Object',
      GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP
    );
    if (!mapActive) {
      setMapActive(true);
    }
    setSelectedCustomerCenter(customerCenter);
    setMapPosition({
      center: [customerCenter.coords.lng, customerCenter.coords.lat],
      zoom: [14],
    });
  };

  const addDistanceFromUserToCustomerCenter = (customerCenter) => {
    if (!userPosition) {
      return customerCenter;
    }

    return {
      ...customerCenter,
      distance: distance(
        [customerCenter.coords.lng, customerCenter.coords.lat],
        userPosition
      ),
    };
  };

  const sortCustomerCentersByDistance = (location1, location2) =>
    location1.distance - location2.distance;

  const handleFocus = () => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_INPUT,
      'Use of Filter Input Field',
      GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP
    );
    if (!mapActive) {
      setMapActive(true);
    }
  };

  const getFilteredCustomerCenterList = () =>
    customerCenters
      ? customerCenters.filter(
          (customerCenter) =>
            customerCenter.name.toLowerCase().includes(input.toLowerCase()) ||
            customerCenter.address.toLowerCase().includes(input.toLowerCase())
        )
      : undefined;

  // Since IE (and browsers missing WebGL support) doesn't support the map we
  // redirect these to the customer center page instead
  if ((isIe() || !supported()) && selectedCustomerCenter) {
    return (
      <Navigate
        to={`${CUSTOMER_CENTERS_PATH}/${selectedCustomerCenter.slug}`}
      />
    );
  }

  return (
    <StyledMapContent>
      <MapSidebar data-cy="sidebar">
        {loading && <SkeletonSideBar />}
        {!loading && (
          <>
            <CustomerCenterSearch
              aria-label="Search customer center"
              search
              value={input}
              onChange={(event) => setInput(event.target.value)}
              onFocus={handleFocus}
              placeholder={translations.customerCentersBanner.searchPlaceholder}
            />
            <FilterOptions>
              {userPositionLoading && (
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              )}
              <SelectedFilterMarker sortByDistance={sortByDistance} />
              <FilterButton
                selected={!sortByDistance}
                onClick={handleSortCustomerCentersByNameClick}
              >
                {translations.customerCentersBanner.byName}
              </FilterButton>
              <FilterButton
                selected={sortByDistance}
                onClick={handleSortCustomerCentersClick}
              >
                {translations.customerCentersBanner.byDistance}
              </FilterButton>
            </FilterOptions>
          </>
        )}
        <CustomerCenterList data-cy="customerCenterList">
          {customerCenters &&
            getFilteredCustomerCenterList()
              .map(addDistanceFromUserToCustomerCenter)
              .sort(sortByDistance && sortCustomerCentersByDistance)
              .map((customerCenter) => (
                <CustomerCenter
                  key={customerCenter.name}
                  onClick={() =>
                    handleCustomerCenterHeaderClick(customerCenter)
                  }
                  selected={customerCenter === selectedCustomerCenter}
                >
                  <div>
                    <HeaderContent>
                      <CustomerCenterName>
                        {customerCenter.name.includes(COMPANY_NAME)
                          ? customerCenter.name
                          : `${COMPANY_NAME} ${customerCenter.name}`}
                      </CustomerCenterName>
                      {customerCenter.distance && (
                        <Distance inline>
                          ({Math.round(customerCenter.distance * 100) / 100} km)
                        </Distance>
                      )}
                    </HeaderContent>
                    <Paragraph inline>{customerCenter.address}</Paragraph>
                    <Paragraph inline>
                      {customerCenter.openHours
                        ? customerCenter.shortDescription
                        : customerCenter.description}
                    </Paragraph>
                    <ContactInformation>
                      <div>
                        <Link url={`mailto:${customerCenter.email}`}>
                          {customerCenter.email}
                        </Link>
                      </div>
                      <div>
                        <Link url={`tel:${customerCenter.phoneNumber}`}>
                          {customerCenter.phoneNumber}
                        </Link>
                      </div>
                    </ContactInformation>
                    <CustomerCenterLink
                      noUnderline
                      url={`${CUSTOMER_CENTERS_PATH}/${customerCenter.slug}`}
                    >
                      {translations.goToCustomerCenter}
                      <ArrowIcon alt="" src={arrowRightIcon} />
                    </CustomerCenterLink>
                  </div>
                </CustomerCenter>
              ))}
        </CustomerCenterList>
      </MapSidebar>
      {!isMobile() && (
        <MapContainer>
          {(isIe() || !supported()) && (
            <BrowserInformation>
              {translations.customerCentersBanner.noBrowserSupport}
            </BrowserInformation>
          )}
          {!isIe() && supported() && (
            <Suspense fallback={<></>}>
              {mapActive ? (
                <Map
                  setSelectedCustomerCenter={setSelectedCustomerCenter}
                  selectedCustomerCenter={selectedCustomerCenter}
                  mapPosition={mapPosition}
                  customerCenters={customerCenters}
                  filteredCustomerCenterList={getFilteredCustomerCenterList()}
                  handleMarkerClick={handleMarkerClick}
                  userPosition={userPosition}
                  initialZoom={initialZoom}
                />
              ) : (
                <NoActiveMap handleClick={() => setMapActive(true)} />
              )}
            </Suspense>
          )}
        </MapContainer>
      )}
    </StyledMapContent>
  );
};

MapContent.propTypes = {
  customerCenters: PropTypes.arrayOf(PropTypes.object),
  initialZoom: PropTypes.number,
  loading: PropTypes.bool,
};

export default MapContent;

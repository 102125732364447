import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import getFaqPostCollections from '../../../../api/contentful/getFaqPosts';
import BannerHeader from '../../../components/BannerHeader';
import FaqAccordion from '../../../components/accordion/FaqAccordion';
import AccordionBlock from '../../../components/accordion/AccordionBlock';
import SkeletonFaq from '../../../components/SkeletonFaq';

const RamiFaq = ({ children, title, collapsible }) => {
  const [loading, setLoading] = useState(true);
  const [faqPostCollections, setFaqPostCollections] = useState(undefined);

  useEffect(() => {
    const getData = async () => {
      const response = await getFaqPostCollections();
      setFaqPostCollections(response);
      setLoading(false);
    };
    getData();
  }, []);

  const faqSchema = (mainEntity) => (
    <Helmet
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'FAQPage',
            mainEntity: {
              name: mainEntity,
            },
          }),
        },
      ]}
    />
  );

  const getFAQ = () => {
    return (
      <>
        {loading && <SkeletonFaq />}
        {faqPostCollections &&
          faqPostCollections.map((faqPostCollection) => (
            <FaqAccordion
              key={faqPostCollection.title}
              faqPosts={faqPostCollection.faqPosts}
              title={faqPostCollection.title}
            />
          ))}
      </>
    );
  };
  if (collapsible) {
    return (
      <AccordionBlock title={title} subTitle={children[0]}>
        {faqSchema(title)}
        {getFAQ()}
      </AccordionBlock>
    );
  }
  return (
    <>
      {faqSchema(title)}
      <BannerHeader subTitle={children[0]} title={title} />
      {getFAQ()}
    </>
  );
};

export default RamiFaq;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import getNavigationBar from '../../api/contentful/getNavigationBar';
import companyLogo from '../../assets/icons/company_logo.svg';
import menuIcon from '../../assets/icons/menu_icon.svg';
import englishFlag from '../../assets/icons/flag-en.svg';
import swedishFlag from '../../assets/icons/flag-se.svg';
import Button from '../../shared/components/Button';
import NavigationMenuLink, {
  navigationMenuLinkStyles,
} from '../../shared/components/NavigationMenuLink';
import Link from '../../shared/components/Link';
import SearchBar from '../../shared/views/searchBar/SearchBar';
import { noPrintMixin } from '../../theme';
import translations from '../../translations';
import { useLocale } from '../../shared/hooks/useLocale';
import { getLink } from '../../utils/link';

import FEATURE_TOGGLES from '../../shared/views/featureToggle/featureToggles';
import { GoogleAnalyticsEvent } from '../../shared/components/GoogleAnalytics';
import {
  locales,
  GOOGLE_ANALYTICS_CATEGORY_LINK,
  GOOGLE_ANALYTICS_LABEL_ENGLISH_FLAG,
  GOOGLE_ANALYTICS_LABEL_SWEDISH_FLAG,
  COUNTRY,
} from '../../constants';
import useHideOnScrolled from '../../shared/hooks/useHideOnScroll';
import AnimateHeight from 'react-animate-height';
import NavContactFormButton from './components/NavContactFormButton';

const StyledNavigationBar = styled.nav`
  background-color: ${(props) => props.theme.color.yellow};
  padding: ${(props) => props.theme.space['16px']};
  padding-bottom: ${(props) =>
    props.hideSearchBar ? props.theme.space['4px'] : props.theme.space['32px']};
  position: sticky;
  top: 0;
  z-index: 6;
  transition: padding-bottom 0.5s;
  display: flex;
  flex-direction: column;
`;

const NavigationBarLinks = styled.div`
  align-items: center;
  color: ${(props) => props.theme.color.blue};
  display: flex;
  height: 58px;
  list-style: none;
  align-content: space-between;
  max-width: ${(props) => props.theme.page.maxWidth};

  ${(props) => props.theme.device.desktop`
    align-items: baseline;
    height: auto;
    margin: auto;
    justify-content: space-between;
    width: 100%;
  `}
`;

const HomeNavigationBarLink = styled(ReactRouterLink)`
  margin-right: 5%;
`;

const navigationBarLinkStyles = css`
  display: none;
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  padding: ${(props) => props.theme.space['12px']};
  min-width: max-content;
  ${(props) => props.theme.device.desktop`
    display: block;
  `}
`;

const NavigationBarLink = styled(NavigationMenuLink)`
  ${navigationBarLinkStyles};
`;

const NavigationBarExternalLink = styled(Link)`
  ${navigationMenuLinkStyles};
  ${navigationBarLinkStyles};
`;

const NavbarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  justify-content: flex-end;
`;

const CompanyLogo = styled.img`
  height: 18px;
  width: 120px;
`;

const MenuButton = styled(Button)`
  ${noPrintMixin};
  align-self: center;
  display: flex;
  margin-right: ${(props) => props.theme.space['12px']};

  ${(props) => props.theme.device.desktop`
    display: none;
  `}
`;

const MenuIcon = styled.img`
  height: ${(props) => props.theme.icon.size['32px']};
  width: ${(props) => props.theme.icon.size['32px']};
`;

const FlagIcon = styled.img`
  height: ${(props) => props.theme.icon.size['14px']};
  width: ${(props) => props.theme.icon.size['26px']};
  padding-left: 6px;
`;

const handleEnglishFlagLinkClick = (link) => {
  GoogleAnalyticsEvent(
    GOOGLE_ANALYTICS_CATEGORY_LINK,
    `Clicked ${link}`,
    GOOGLE_ANALYTICS_LABEL_ENGLISH_FLAG
  );
};

const handleSwedishFlagLinkClick = (link) => {
  GoogleAnalyticsEvent(
    GOOGLE_ANALYTICS_CATEGORY_LINK,
    `Clicked ${link}`,
    GOOGLE_ANALYTICS_LABEL_SWEDISH_FLAG
  );
};

const NavigationBar = ({ onHamburgerMenuClick, openContactForm }) => {
  const hideSearchBar = useHideOnScrolled(70);
  const [menuOption, setMenuOption] = useState([]);
  const [language, setLanguage] = useState('');
  const { locale } = useLocale();

  const handleLanguage = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getNavigationBar(language);
      setMenuOption(response?.menuOptions || []);
    };
    getData();
  }, [language]);

  return (
    <StyledNavigationBar hideSearchBar={hideSearchBar}>
      <NavigationBarLinks data-cy="navigationBarLinks">
        <MenuButton onClick={onHamburgerMenuClick}>
          <MenuIcon alt="Home" src={menuIcon} />
        </MenuButton>
        <HomeNavigationBarLink
          to=""
          {...(COUNTRY === 'FI' && { onClick: () => handleLanguage('') })}
        >
          <CompanyLogo
            src={companyLogo}
            alt={translations.home}
            data-cy="companyLogo"
          />
        </HomeNavigationBarLink>
        <NavbarWrapper data-cy="navigationBarWrapper">
          {menuOption?.map((link) => (
            <div key={link.id}>
              {link.link ? (
                <NavigationBarExternalLink
                  url={link.link}
                  noUnderline
                  data-cy="navigationBarLink"
                >
                  {link.title}
                </NavigationBarExternalLink>
              ) : (
                <>
                  <NavigationBarLink
                    to={getLink(link.slugList, locale)}
                    data-cy="navigationBarLink"
                  >
                    {link.title}
                  </NavigationBarLink>
                </>
              )}
            </div>
          ))}
        </NavbarWrapper>
        {FEATURE_TOGGLES.navbarContactForm && (
          <NavContactFormButton openContactForm={openContactForm} />
        )}
        {FEATURE_TOGGLES.ramirentInShort && (
          <div style={{ display: 'flex' }}>
            <NavLink
              to={`/en-ramirent-in-short?lang=${locales.EN}`}
              onClick={() => {
                handleEnglishFlagLinkClick(
                  `/en-ramirent-in-short?lang=${locales.EN}`
                );
                handleLanguage(locales.EN);
              }}
            >
              <FlagIcon alt="EN" src={englishFlag} />
            </NavLink>
            <NavLink
              to={`/se-ramirent-in-short?lang=${locales.SE}`}
              onClick={() => {
                handleSwedishFlagLinkClick(
                  `/se-ramirent-in-short?lang=${locales.SE}`
                );
                handleLanguage(locales.SE);
              }}
            >
              <FlagIcon alt="SE" src={swedishFlag} />
            </NavLink>
          </div>
        )}
      </NavigationBarLinks>
      <AnimateHeight duration={500} height={hideSearchBar ? 0 : 'auto'}>
        <SearchBar />
      </AnimateHeight>
    </StyledNavigationBar>
  );
};

NavigationBar.propTypes = {
  onHamburgerMenuClick: PropTypes.func.isRequired,
};

export default NavigationBar;

// PLEASE NOTE THAT SEO WILL BE CRITICALLY AFFECTED IF A ROUTE IS MODIFIED
const norwegianRoutePaths = {
  aboutPath: 'om oss',
  articlesPath: 'artikler',
  blogPath: 'artikler',
  coursesPath: 'kurs',
  customerCentersPath: 'kundesenter',
  customerServicePath: 'kundeservice',
  sustainabilityPath: 'barekraft',
  newsPath: 'presserom',
  pressroomPath: 'nyheter',
  referencesPath: 'referanser',
  rentPath: 'maskinutleie',
  rentPathProduct: 'produkt',
  searchPath: 'søk',
  servicesPath: 'tjenester',
};

export default norwegianRoutePaths;

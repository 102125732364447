import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { COUNTRY } from '../../constants';

const StyledRouterLink = styled(({ noUnderline, ...props }) => (
  <ReactRouterLink {...props} />
))`
  color: ${props => props.theme.color.blue};
  text-decoration: ${props => (props.noUnderline ? 'none' : 'underline')};
  transition: color 0.1s ease;
  word-break: break-word;

  :hover {
    color: ${props => props.theme.color.orange};
  }
`;

const StyledAnchorTag = styled.a`
  color: ${props => props.theme.color.blue};
  text-decoration: ${props => (props.noUnderline ? 'none' : 'underline')};
  transition: color 0.1s ease;
  word-break: break-word;

  :hover {
    color: ${props => props.theme.color.orange};
  }
`;

const Link = ({ children, url, ...rest }) => {
  // mailto or tel link
  if (url.includes('mailto:') || url.includes('tel:')) {
    return (
      <StyledAnchorTag href={url} {...rest}>
        {children}
      </StyledAnchorTag>
    );
  }

  // Internal link with full URL
  if (url.includes(`www.ramirent.${COUNTRY.toLowerCase()}`)) {
    const path = url.split(`.${COUNTRY.toLowerCase()}`)[1];

    return (
      <StyledRouterLink to={path} {...rest}>
        {children}
      </StyledRouterLink>
    );
  }

  // Internal path
  if (!url.includes('.')) {
    return (
      <StyledRouterLink to={url} {...rest}>
        {children}
      </StyledRouterLink>
    );
  }

  // External link
  return (
    <StyledAnchorTag href={url} rel="noopener" target="_blank" {...rest}>
      {children}
    </StyledAnchorTag>
  );
};

Link.propTypes = {
  url: PropTypes.string.isRequired
};

export default Link;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import initAdformClient from './clients/adformClient';
import apolloClient from './clients/apolloClient';
import initDeltaClient from './clients/deltaClient';
import initEmbedlyClient from './clients/embedlyClient';
import initFacebookPixelClient from './clients/facebookPixelClient';
import initHubspotClient from './clients/hubspotClient';
import initGoogleAnalyticsClient from './clients/googleAnalyticsClient';
import initGoogleTagManagerClient from './clients/googleTagManagerClient';
import initSentryClient from './clients/sentryClient';
import initInZynkClient from './clients/inZynkClient';
import {
  COUNTRY,
  REGIONAL_WEB_SITE_NORWAY,
  COOKIEBOT_DOMAIN_GROUP_ID,
} from './constants';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/components/ErrorBoundary';
import GlobalStyle from './shared/components/GlobalStyle';
import GoogleAnalytics from './shared/components/GoogleAnalytics';
import ScrollToTop from './shared/components/ScrollToTop';
import theme from './theme';
import CookieBot from 'react-cookiebot';
import './clients/cookieBot.css';

const render = (Component) => {
  initEmbedlyClient();
  initGoogleAnalyticsClient();
  initGoogleTagManagerClient();
  initSentryClient();

  // Init country specific tracking scripts
  if (COUNTRY === 'NO') {
    initInZynkClient();

    // Norway wanted a redirect for a temporary campaign.
    if (window.location.href === `${REGIONAL_WEB_SITE_NORWAY}/tilgjengelig`) {
      return window.location.replace(
        'https://kampanje.ramirent.no/tilgjengelig/'
      );
    }
  }

  if (COUNTRY === 'SE') {
    initAdformClient();
    initDeltaClient();
    initFacebookPixelClient();
    initHubspotClient();
  }

  return ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <GoogleAnalytics>
              <ScrollToTop>
                <GlobalStyle />
                <Component />
                <CookieBot domainGroupId={COOKIEBOT_DOMAIN_GROUP_ID} />
              </ScrollToTop>
            </GoogleAnalytics>
          </ThemeProvider>
        </ApolloProvider>
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

// Enables Hot Module Reloading
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

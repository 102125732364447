import styled, { css } from 'styled-components/macro';

const UnstyledHeader3 = styled.h3`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const header3Styles = css`
  color: ${(props) => {
    if (props.blue) return props.theme.color.blue;
    if (props.white) return props.theme.color.white;
    return props.theme.color.grayDarker;
  }};
  font-family: ${(props) => props.theme.font.family.boing};
  font-size: ${(props) => props.theme.font.size['20px']};
  font-weight: ${(props) => props.theme.font.weight.regular};
  padding-bottom: ${(props) => (props.inline ? 0 : '16px')};

  ${(props) => props.theme.device.tablet`
    font-size: ${(props) =>
      props.small
        ? props.theme.font.size['26px']
        : props.theme.font.size['28px']};
    padding-bottom: ${(props) => (props.inline ? 0 : '16px')};
  `}
`;

const Header3 = styled(UnstyledHeader3)`
  ${header3Styles}
`;

export default Header3;
export { header3Styles };

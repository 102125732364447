const englishTranslations = {
  aboutUs: 'About us',
  accessories: 'Accessories',
  articles: 'Articles',
  blog: 'Blog',
  blogPost: 'Blog post',
  career: 'Career',
  contactUs: 'Contact us',
  cookiePolicy: 'Cookiepolicy',
  courses: 'Courses',
  customerCenter: 'Customer center',
  customerPortal: 'Customer portal',
  customerService: 'Customer service',
  sustainability: 'Sustainability',
  day: 'Day',
  documents: 'Documents',
  excludingVat: 'ex. VAT',
  filterByYear: 'Filter by year',
  findCustomerCenter: 'Find customer center',
  forInvestors: 'For investors',
  goToCustomerCenter: 'Go to the customer center page',
  goToHome: 'Go to the homepage',
  home: 'Home',
  loadMore: 'Load more',
  marketing: 'Marketing',
  minimize: 'Minimize',
  necessary: 'Necessary',
  news: 'News',
  newsPost: 'News post',
  noResultsFound: 'No results found',
  openInGoogleMaps: 'Open in Google Maps',
  pressroom: 'Pressroom',
  privacyPolicy: 'Privacy policy',
  productNumber: 'Product number',
  products: 'Products',
  productType: 'Product type',
  published: 'Published',
  readMore: 'Read more',
  references: 'References',
  rent: 'Rent',
  rental: 'Rental',
  reportAbuse: 'Report abuse',
  reservation: 'Reservation',
  salesItem: 'Sales',
  salesItems: 'Sales items',
  seeAll: 'See all',
  search: 'Search',
  searchResults: 'Search results',
  searchResultsFor: 'Search results for',
  services: 'Services',
  shareThis: 'Share this',
  statistics: 'Statistics',
  technicalInformation: 'Technical information',
  termsAndConditions: 'Terms and conditions',
  articlesBanner: {
    subTitle: 'Stay updated on Ramirent',
    title: 'Articles',
  },
  blogBanner: {
    subTitle: 'Stay updated on Ramirent',
    title: 'Latest blog posts',
  },
  contactForm: {
    close: 'Close',
    email: 'E-mail',
    name: 'Name',
    phone: 'Phone number',
    location: 'Location',
    send: 'Send',
    yourMessage: 'Your message',
    successTitle: 'Thank you for your message!',
    successMessage: 'We will get back to you as soon as we can.',
    errorTitle: 'Something went wrong',
    errorMessage: 'Try again or contact customer service directly.',
    rules: {
      fullName: 'Full name (First and last name)',
      phone: 'Only phone numbers',
      required: 'Required',
      least6: 'must be at least 6 characters',
      max50: 'can be a maximum of 50 characters',
      max500: 'can be a maximum of 500 characters',
    },
  },
  contactPersonsBanner: {
    subTitle: '',
    title: 'Contact persons',
  },
  customerCenterPage: {
    address: 'Address',
    openingHours: 'Opening hours',
    specialOpeningHours: 'Special Opening hours',
    closed: 'Closed',
    weekDays: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    },
    description: 'Description',
  },
  customerCentersBanner: {
    byDistance: 'Distance',
    byName: 'A-Z',
    noBrowserSupport: 'Your browser does not support map view!',
    searchPlaceholder: 'Search by city, street or location',
    subTitle: 'You can rent and return the products here',
    title: 'Customer Center',
  },
  dataErrorBanner: {
    description:
      'Please wait and try again. If the error still occurs, contact customer service.',
    title: 'Error when loading data.',
  },
  emptyCategoryPage: {
    description: 'There are currently no products in this category.',
    goBackTo: 'Go back to',
    title: 'Empty!',
  },
  faqBanner: {
    subTitle: "We've collected the most common questions and answers here",
    title: 'Common questions',
  },
  footerHeaders: {
    apps: 'Apps',
    group: 'Group',
    ourOffer: 'Our offer',
    ramirentInTheWorld: 'Ramirent in the world',
  },
  informationBanner: {
    customerCenterDescription: 'Our staff can always help you',
    customerCenterTitle: 'Find customer center',
    emailDescription: 'We normally respond within 24 h',
    phoneDescription: 'Call us, we are here to help',
    commonQuestionsDescription:
      'Here we have gathered the answers to the most common questions',
    commonQuestionsTitle: 'Common questions and answers',
    newsletterDescription: 'Here you can subscribe to our newsletter',
    newsletterTitle: 'Subscribe to newsletter',
  },
  managementBanner: {
    subTitle: '',
    title: 'Management of Ramirent',
  },
  newsBanner: {
    subTitle: '',
    title: 'News',
  },
  notFoundPage: {
    description: "We couldn't find the page you were looking for.",
    title: 'Oops',
  },
  popularProductsBanner: {
    subTitle: 'See our product catalogue',
    title: 'Most popular',
  },
  referenceBanner: {
    subTitle: '',
    title: 'References',
  },
  referencePage: {
    factsTableHeader: 'Facts about the project',
    moreReferences: 'More references',
    productsUsedInProject: 'Products used in this project',
  },
  searchBar: {
    placeholder: 'Enter a product, service or customer center',
    seeAllResults: 'See all results',
    title: "We're here to help you",
  },
  usefulLinksBanner: {
    subTitle: 'Often visited',
    title: 'Useful links',
  },
  quickLinksBanner: {
    subTitle: 'Often visited',
    title: 'Quick links',
  },
};

export default englishTranslations;

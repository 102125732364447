import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components/macro';
import getFooter from '../../api/contentful/getFooter';
import companyLogo from '../../assets/icons/company_logo.svg';
import LinkGroup from './components/LinkGroup';
import SocialMediaLinks from './components/SocialMediaLinks';
import {
  COMPANY_NAME,
  GOOGLE_ANALYTICS_CATEGORY_DROPDOWN,
  GOOGLE_ANALYTICS_CATEGORY_LINK,
  GOOGLE_ANALYTICS_LABEL_FOOTER,
} from '../../constants';
import {
  APP_STORE_ICON,
  APP_STORE_LINK,
  COUNTRY_DROPDOWN_OPTIONS,
  DEFAULT_COUNTRY_DROPDOWN_VALUE,
  GOOGLE_PLAY_ICON,
  GOOGLE_PLAY_LINK,
} from './constants';
import BoldText from '../../shared/components/BoldText';
import { GoogleAnalyticsEvent } from '../../shared/components/GoogleAnalytics';
import Link from '../../shared/components/Link';
import Select from '../../shared/components/Select';
import { noPrintMixin } from '../../theme';
import { useLocale } from '../../shared/hooks/useLocale';
import { getLink } from '../../utils/link';

const StyledFooter = styled.footer`
  ${noPrintMixin};
  background-color: ${(props) => props.theme.color.yellow};
  color: ${(props) => props.theme.color.blue};
  padding: 0 ${(props) => props.theme.space['16px']};
`;

const FooterTopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 465px;
  padding-bottom: ${(props) => props.theme.space['24px']};
  padding-left: ${(props) => props.theme.space['12px']};
  padding-top: ${(props) => props.theme.space['48px']};

  ${(props) => props.theme.device.desktop`
    max-width: ${(props) => props.theme.page.maxWidth};
    padding-bottom: ${(props) =>
      props.countryBadges
        ? props.theme.space['24px']
        : props.theme.space['96px']};
  `}
`;

const CompanyLogo = styled.img`
  height: 18px;
  width: 120px;

  ${(props) => props.theme.device.desktop`
    margin-right: ${(props) => props.theme.space['48px']};
    margin-top: -3px;
  `}
`;

const DropDownWrapper = styled(LinkGroup)`
  margin-top: ${(props) => props.theme.space['12px']};
  width: 100%;

  ${(props) => props.theme.device.desktop`
    flex-grow: 1;
    margin: 0;
    width: auto;
  `}
`;

const DropDown = styled(Select)`
  margin-top: ${(props) => props.theme.space['24px']};
  max-width: 400px;
  left: -${(props) => props.theme.space['12px']};

  ${(props) => props.theme.device.desktop`
    left: 0;
    width: 220px;
  `}
`;

const LinkGroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${(props) => props.theme.device.desktop`
    flex-grow: 3;
  `}
`;

const FooterLink = styled(Link)`
  display: block;
  padding: ${(props) => props.theme.space['4px']} 0;
`;

const StyledLinkGroup = styled(LinkGroup)`
  width: 50%;
  max-width: 200px;

  ${(props) => props.theme.device.desktop`
    width: 20%;
  `}
`;

const AppStoreIcon = styled.img`
  height: 40px;
  width: 120px;
`;

const GooglePlayIcon = styled.img`
  height: 35px;
  width: 120px;
`;

const FooterMiddleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 465px;
  padding-bottom: ${(props) => props.theme.space['24px']};

  ${(props) => props.theme.device.desktop`
    max-width: ${(props) => props.theme.page.maxWidth};
  `}
`;

const CountryBadgeIcon = styled.img`
  height: 85px;
  max-width: 100%;
  padding-right: ${(props) => props.theme.space['16px']};
`;

const FooterBottomWrapper = styled.div`
  border-top: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.yellowDark};
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  max-width: ${(props) => props.theme.page.maxWidth};
  padding-top: ${(props) => props.theme.space['32px']};
  padding-bottom: ${(props) => props.theme.space['24px']};

  ${(props) => props.theme.device.desktop`
    align-items: flex-start;
    height: 100px;
    flex-direction: row;
    padding: ${(props) => props.theme.space['32px']} 0;
  `}
`;

const AdditionalinksWrapper = styled.div`
  ${(props) => props.theme.device.tablet`
    display: flex;
    flex-direction: row;
  `}
`;

const Copyright = styled(BoldText)`
  padding: ${(props) => props.theme.space['4px']} 0;
`;

const Divider = styled.div`
  display: none;

  ${(props) => props.theme.device.tablet`
    border-left: ${(props) => props.theme.border['1px']}
      ${(props) => props.theme.color.yellowDark};
    display: block;
    flex: none;
    margin: 0 ${(props) => props.theme.space['8px']};
  `}
`;

const AdditionalLinks = styled.div`
  a:not(:last-child) {
    margin-right: ${(props) => props.theme.space['8px']};
  }

  ${(props) => props.theme.device.tablet`
    display: flex;
    flex-wrap: wrap;
  `}
`;

const handleDropDownChange = (event) => {
  GoogleAnalyticsEvent(
    GOOGLE_ANALYTICS_CATEGORY_DROPDOWN,
    `Selected: ${event.value}`,
    GOOGLE_ANALYTICS_LABEL_FOOTER
  );

  window.open(event.value, '_self');
};

const handleLinkClick = (link) => {
  GoogleAnalyticsEvent(
    GOOGLE_ANALYTICS_CATEGORY_LINK,
    `Clicked: ${link}`,
    GOOGLE_ANALYTICS_LABEL_FOOTER
  );
};

const Footer = () => {
  const { locale, translations } = useLocale();
  const [footerFields, setfooterFields] = useState([]);
  const language = window.location.href.split('=')[1];

  useEffect(() => {
    const getData = async () => {
      const response = await getFooter(language);
      setfooterFields(response || []);
    };
    getData();
  }, [language]);

  return (
    <StyledFooter>
      <FooterTopWrapper
        countryBadges={footerFields.footerBadges}
        data-cy="footerTopWrapper"
      >
        <CompanyLogo src={companyLogo} alt={translations.home} />
        <DropDownWrapper title={translations.footerHeaders.ramirentInTheWorld}>
          <DropDown
            defaultValue={DEFAULT_COUNTRY_DROPDOWN_VALUE}
            options={COUNTRY_DROPDOWN_OPTIONS}
            onChange={(event) => handleDropDownChange(event)}
          />
        </DropDownWrapper>
        <LinkGroupsWrapper data-cy="linkGroupsWrapper">
          {footerFields.footerMenuLinks?.map((menu, i) => (
            <StyledLinkGroup
              title={menu.title}
              key={menu.title + i}
              data-cy="linkGroup"
            >
              {menu.links.map((item, i) => (
                <div key={item.title + i}>
                  {item.url ? (
                    <FooterLink
                      noUnderline
                      onClick={() => handleLinkClick(item.url)}
                      url={item.url}
                      data-cy="footerLink"
                    >
                      {item.title}
                    </FooterLink>
                  ) : (
                    <FooterLink
                      noUnderline
                      onClick={() => handleLinkClick(item.completeSlug)}
                      url={getLink(item.completeSlug, locale)}
                      data-cy="footerLink"
                    >
                      {item.title}
                    </FooterLink>
                  )}
                </div>
              ))}
            </StyledLinkGroup>
          ))}
          {(APP_STORE_ICON || GOOGLE_PLAY_ICON) && (
            <LazyLoad offset={50} once={true}>
              <StyledLinkGroup title={translations.footerHeaders.apps}>
                {GOOGLE_PLAY_ICON && (
                  <Link
                    onClick={() => handleLinkClick('Google Play')}
                    url={GOOGLE_PLAY_LINK}
                  >
                    <GooglePlayIcon alt="Google Play" src={GOOGLE_PLAY_ICON} />
                  </Link>
                )}
                {APP_STORE_ICON && (
                  <Link
                    onClick={() => handleLinkClick('App Store')}
                    url={APP_STORE_LINK}
                  >
                    <AppStoreIcon alt="App Store" src={APP_STORE_ICON} />
                  </Link>
                )}
              </StyledLinkGroup>
            </LazyLoad>
          )}
        </LinkGroupsWrapper>
      </FooterTopWrapper>
      {footerFields.footerBadges && (
        <LazyLoad offset={50} once={true}>
          <FooterMiddleWrapper>
            {footerFields.footerBadges?.map((countryBadge, index) => (
              <Link
                key={`${index}-${countryBadge.url}`}
                onClick={() => handleLinkClick(countryBadge.title)}
                url={getLink(countryBadge.url, locale)}
                data-cy="footerLink"
              >
                <CountryBadgeIcon
                  alt={countryBadge.title}
                  src={countryBadge.image.url}
                />
              </Link>
            ))}
          </FooterMiddleWrapper>
        </LazyLoad>
      )}
      <FooterBottomWrapper data-cy="footerBottomWrapper">
        <AdditionalinksWrapper>
          <Copyright inline>
            © {new Date().getFullYear()} {COMPANY_NAME}
          </Copyright>
          <Divider />
          <AdditionalLinks data-cy="additionalLinks">
            {footerFields.footerBottomLinks?.map((link, i) => (
              <FooterLink
                key={link.link + i}
                noUnderline
                onClick={() => handleLinkClick(link.link)}
                url={getLink(link.link, locale)}
                data-cy="footerLink"
              >
                {link.title}
              </FooterLink>
            ))}
          </AdditionalLinks>
        </AdditionalinksWrapper>
        <SocialMediaLinks {...footerFields.socialMediaLinks} />
      </FooterBottomWrapper>
    </StyledFooter>
  );
};

export default Footer;

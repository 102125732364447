import React from 'react';
import styled from 'styled-components/macro';

import Button from '../../../../components/Button';

const LoadMoreButton = styled(Button)`
  display: block;
  margin: 0 auto;
  margin-top: ${(props) => props.theme.space['24px']};
  width: 100%;

  ${(props) => props.theme.device.tablet`
    width: auto;
  `}
`;

const RamiLoadMoreButton = ({ children, disabled, onClick }) => (
  <LoadMoreButton
    disabled={disabled}
    onClick={onClick}
    primary
    data-cy="loadMoreButton"
  >
    {children}
  </LoadMoreButton>
);

export default RamiLoadMoreButton;

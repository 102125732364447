import React from 'react';
import styled from 'styled-components/macro';

import Link from '../Link';
import {
  contentfulImageUrlBuilder,
  sizesBuilder,
  sourceSetBuilder,
} from './ImageHelpers';
import { isMediaFromContentful } from '../../../utils/mediaFunctions';
import PropTypes from 'prop-types';
import Ramicon_Happy from '../../../assets/images/Ramicon_Happy.svg';
import Ramicon_Surprised from '../../../assets/images/Ramicon_Surprised.svg';

export const ImageType = {
  BANNER: 'BANNER',
  PORTRAIT: 'PORTRAIT',
  PORTRAIT_SMALL: 'PORTRAIT_SMALL',
  RECTANGLE: 'RECTANGLE',
  RECTANGLE_SMALL: 'RECTANGLE_SMALL',
  SQUARE: 'SQUARE',
};

export const ImageFormat = {
  JPG: 'jpg',
};

export const ImageDefaultImage = {
  HAPPY: 'HAPPY',
  SURPRISED: 'SURPRISED',
};

// Common desktop resultion is 2560.
// Desktop max-width is 1140
const IMAGE_MAX_WIDTH = 2280; // 1140 x 2 (pixel-device-ratio)
// Mobile viewport width is 320px, with pixel-device-ratio 2
const IMAGE_MIN_WIDTH = 640; // 320 x 2;
const DEFAULT_AMOUNT_OF_IMAGE_SIZES = 5;
// Use for CSS background count,
// since unit is x (pixel-device-ratio can be 1 or 2) and not pixels
const DEFAULT_AMOUNT_OF_IMAGE_SIZES_CSS_BACKGROUND = 2;

const DEFAULT_QUALITY = 70;
const DEFAULT_FIT = 'fill';

// Only IE (and opera) that do not understand srcset.
// Width is used in img src, hence making it half of maxWidth.
const createImageConfigObject = ({
  maxWidth = IMAGE_MAX_WIDTH,
  minWidth = IMAGE_MIN_WIDTH,
  count = DEFAULT_AMOUNT_OF_IMAGE_SIZES,
  fm,
  q = DEFAULT_QUALITY,
  fit = DEFAULT_FIT,
}) => {
  const configObject = {
    maxWidth: maxWidth,
    minWidth: minWidth,
    count: count,
    width: maxWidth / 2,
    quality: q,
    fit: fit,
  };
  if (fm) {
    configObject.format = fm;
  }
  return configObject;
};

const ImageTypeConfig = {
  BANNER: createImageConfigObject({
    fm: ImageFormat.JPG,
    q: '90',
  }),
  PORTRAIT: createImageConfigObject({
    maxWidth: 1464, // 732 * 2 (largest in mobile view)
    q: '100',
    fm: ImageFormat.JPG,
  }),
  PORTRAIT_SMALL: createImageConfigObject({
    maxWidth: 300, // 13 % of IMAGE_MAX_WIDTH
    minWidth: 160, // IMAGE_MIN_WIDTH / 4
    count: 2,
    fm: ImageFormat.JPG,
    q: '100',
    fit: 'thumb',
  }),
  RECTANGLE: createImageConfigObject({
    maxWidth: 1470, // 735 * 2 (mobile view not including padding)
    count: 4,
  }),
  RECTANGLE_SMALL: createImageConfigObject({
    maxWidth: 1470, // 735 * 2 (mobile view not including padding)
    count: 3,
    fm: ImageFormat.JPG,
    fit: 'thumb',
  }),
  SQUARE: createImageConfigObject({ fm: ImageFormat.JPG }),
};

const getImageTypeConfig = (image, type) => {
  return image.typeConfig
    ? createImageConfigObject({
        maxWidth: image.typeConfig.maxWidth
          ? image.typeConfig.maxWidth
          : ImageTypeConfig[type].maxWidth,
        minWidth: image.typeConfig.minWidth
          ? image.typeConfig.minWidth
          : ImageTypeConfig[type].minWidth,
        count: image.typeConfig.count
          ? image.typeConfig.count
          : ImageTypeConfig[type].count,
        q: image.typeConfig.quality
          ? image.typeConfig.quality
          : ImageTypeConfig[type].quality,
        fit: image.typeConfig.fit
          ? image.typeConfig.fit
          : ImageTypeConfig[type].fit,
        fm: image.typeConfig.format
          ? image.typeConfig.format
          : ImageTypeConfig[type].format,
      })
    : ImageTypeConfig[type];
};

const getImageSrc = (image, type, debug) => {
  if (!image || (image && !image.url)) {
    return getImageDefaultImage();
  }
  return isMediaFromContentful(image.url)
    ? contentfulImageUrlBuilder(
        image.url,
        getImageTypeConfig(image, type),
        debug
      )
    : image.url;
};

const getImageSrcSet = (image, type, isCSS, debug) => {
  if (isCSS) {
    image.typeConfig = image.typeConfig
      ? {
          ...image.typeConfig,
          count: DEFAULT_AMOUNT_OF_IMAGE_SIZES_CSS_BACKGROUND,
        }
      : {
          count: DEFAULT_AMOUNT_OF_IMAGE_SIZES_CSS_BACKGROUND,
        };
  }
  return sourceSetBuilder(
    {
      ...image,
      ...getImageTypeConfig(image, type),
      isCSS: isCSS,
    },
    debug
  ).join(',');
};

const getImageDefaultImage = (defaultImage) => {
  switch (defaultImage) {
    case ImageDefaultImage.SURPRISED:
      return Ramicon_Surprised;
    default:
      return Ramicon_Happy;
  }
};

export const ImageLink = styled(Link)`
  background-image: url(${(props) =>
    props.image &&
    getImageSrc(props.image.image, props.image.type, props.image.debug)});
  background-image: image-set(
    ${(props) =>
      props.image &&
      getImageSrcSet(
        props.image.image,
        props.image.type,
        true,
        props.image.debug
      )}
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: ${(props) => props.theme.borderRadius.medium};
  box-shadow: ${(props) => props.theme.shadow.normal};
  color: ${(props) => props.theme.color.white};
  display: block;
  text-decoration: none;
  transition: box-shadow 0.2s ease;

  :hover {
    box-shadow: ${(props) => props.theme.shadow.bold};
  }
`;

const StyledImageTag = styled.img`
  object-fit: cover;
  min-width: 100%;
  min-height: ${(props) => (props.isCustomerCenter ? '40%' : '100%')};
  max-width: 100%;
  max-height: ${(props) => (props.isCustomerCenter ? '50%' : '100%')};
  position: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Image = ({
  image,
  sizes,
  type,
  defaultImage,
  debug,
  customerCenter = false,
}) => {
  const imgProps = {};
  imgProps.src = image.url
    ? getImageSrc(image, type, debug)
    : getImageDefaultImage(defaultImage);

  if (image.url) {
    if (!image.typeConfig || (image.typeConfig && !image.typeConfig.noSrcSet)) {
      imgProps.srcSet = getImageSrcSet(image, type, false, debug);
    }

    if (sizes) {
      imgProps.sizes = sizesBuilder(sizes);
    }
  }

  if (image.alt) {
    imgProps.alt = image.alt;
  }

  return <StyledImageTag {...imgProps} isCustomerCenter={customerCenter} />;
};

Image.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  defaultImage: PropTypes.string,
};

export default Image;

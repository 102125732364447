export const ListStyle = {
  DEFAULT: 'Default',
  ARTICLE_BANNER_CARDS: 'Article banner card',
  ARTICLE_BANNER_CARDS_LARGE: 'Article banner card large',
  ARTICLE_CARDS: 'Article cards',
  CARDS: 'Cards',
  SMALL_CARDS: 'Small cards',
  LINKS: 'Links',
  LINKS_WITH_IMAGE: 'Links with image',
  LINKS_WITH_IMAGE_AND_TEASER: 'Links with image and teaser',
};

export const ListConfiguration = {
  DISPLAY_LATEST_SUBPAGES: 'Display latest subpages',
};

export const formatList = (id, list) => ({
  type: 'list',
  id: id,
  title: list.collapsible || list.showTitle ? list.title : null,
  subTitle: list.subtitle,
  style: list.style ? list.style : ListStyle.DEFAULT,
  collapsible: list.collapsible,
  configuration: list.configuration,
  maxNumberOfItems: list.maxNumberOfItems ? list.maxNumberOfItems : 0,
});

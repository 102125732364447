import * as contentful from 'contentful';

import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  ENVIRONMENT
} from '../constants';

const contentfulClient = contentful.createClient({
  accessToken: CONTENTFUL_ACCESS_TOKEN,
  environment: CONTENTFUL_ENVIRONMENT,
  host:
    ENVIRONMENT === 'TEST' ? 'preview.contentful.com' : 'cdn.contentful.com',
  space: CONTENTFUL_SPACE_ID
});

export default contentfulClient;

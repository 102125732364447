import dateFormat from '../../../utils/dateFunctions';
import { getBasePathFromType } from '../../../utils/link';

export const formatBlogPost = (blogPost) => ({
  type: 'blogPost',
  author: blogPost.author && {
    email: blogPost.author.fields.email,
    image: {
      url: `https:${blogPost.author.fields.image.fields.file.url}`,
      title: blogPost.author.fields.image.fields.title,
    },
    name: blogPost.author.fields.name,
    role: blogPost.author.fields.role,
  },
  copy: blogPost.copy,
  date: dateFormat(new Date(blogPost.date)),
  images:
    blogPost.images &&
    blogPost.images.map((image) => {
      return {
        url: `https:${image.fields.file.url}`,
        title: image.fields.title,
      };
    }),
  imageThumb:
    blogPost.images &&
    `https:${blogPost.images[0].fields.file.url}?fm=jpg&q=70&w=1140&fit=thumb`,
  primaryLink: blogPost.primaryLink,
  primaryLinkText: blogPost.primaryLinkText,
  slug: blogPost.slug,
  completeSlug: `${getBasePathFromType('blogPost')}${blogPost.slug}`,
  teaser: blogPost.teaser,
  title: blogPost.title,
});

const FORMATS = ['.jpeg', '.jpg', '.png', '.gif'];

const imageFormat = (string) => {
  if (string) {
    for (let i = 0; i < FORMATS.length; i++) {
      if (string.includes(FORMATS[i])) {
        string = string.replace(FORMATS[i], `_500.jpeg`);
        break;
      }
    }
  } else {
    string = '';
  }
  return string;
};

const contentful = '.ctfassets.net';
const isMediaFromContentful = (url) => url.indexOf(contentful) !== -1;
const isMediaVideo = (url) => url.startsWith('https://video');

export { imageFormat, contentful, isMediaFromContentful, isMediaVideo };

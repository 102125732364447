import facebookIcon from '../../assets/icons/facebook_icon.svg';
import instagramIcon from '../../assets/icons/instagram_icon.svg';
import linkedinIcon from '../../assets/icons/linkedin_icon.svg';
import twitterIcon from '../../assets/icons/twitter_icon.svg';
import youtubeIcon from '../../assets/icons/youtube_icon.svg';
import {
  COUNTRY,
  EXTERNAL_WEB_SITE_FACEBOOK,
  EXTERNAL_WEB_SITE_INSTAGRAM,
  EXTERNAL_WEB_SITE_LINKEDIN,
  EXTERNAL_WEB_SITE_TWITTER,
  EXTERNAL_WEB_SITE_YOUTUBE,
  EXTERNAL_WEB_SITE_HR_MANGER,
  EXTERNAL_WEB_SITE_SE_COURSES,
  GLOBAL_WEB_SITE_ABOUT,
  GLOBAL_WEB_SITE_CAREERS,
  GLOBAL_WEB_SITE_INVESTORS,
  GLOBAL_WEB_SITE_NEWS,
  REGIONAL_WEB_SITE_CZECHREPUBLIC,
  REGIONAL_WEB_SITE_ESTONIA,
  REGIONAL_WEB_SITE_FINLAND,
  REGIONAL_WEB_SITE_LATVIA,
  REGIONAL_WEB_SITE_LITHUANIA,
  REGIONAL_WEB_SITE_NORWAY,
  REGIONAL_WEB_SITE_POLAND,
  REGIONAL_WEB_SITE_SLOVAKIA,
  REGIONAL_WEB_SITE_SWEDEN,
  ABOUT_PATH,
  COURSES_PATH,
  CUSTOMER_CENTERS_PATH,
  CUSTOMER_SERVICE_PATH,
  NEWS_PATH,
  RENT_PATH,
  SERVICES_PATH,
} from '../../constants';
import translations from '../../translations';
import slugify from '../../utils/slug';

let APP_STORE_ICON;
const APP_STORE_LINK =
  COUNTRY === 'NO'
    ? 'https://apps.apple.com/us/app/ramirent/id1437528624?l=no&ls=1'
    : 'https://apps.apple.com/us/app/ramirent/id1437528624?l=sv&ls=1';
let COUNTRY_BADGES;
let GOOGLE_PLAY_ICON;
let GOOGLE_PLAY_LINK =
  COUNTRY === 'NO'
    ? 'https://play.google.com/store/apps/details?id=se.ramirent.availability&hl=no'
    : 'https://play.google.com/store/apps/details?id=se.ramirent.availability&hl=en';

if (COUNTRY === 'FI') {
  COUNTRY_BADGES = [
    {
      image: require('../../assets/images/iso_logo.png'),
      text: 'ISO 9001 ISO 14001 ISO 45001 Bureau Veritas Certification',
      url: '/tietoa-ramirentista/laatu-ymparisto-ja-turvallisuus',
    },
    {
      image: require('../../assets/images/rala_logo.jpg'),
      text: 'Rala Pätevyys',
      url: '/tietoa-ramirentista/laatu-ymparisto-ja-turvallisuus',
    },
  ];
}

if (COUNTRY === 'SE') {
  APP_STORE_ICON =
    require('../../assets/icons/app_store_badge_se.svg')?.default;
  GOOGLE_PLAY_ICON =
    require('../../assets/icons/google_play_badge_se.svg')?.default;
  COUNTRY_BADGES = [
    {
      image: require('../../assets/images/Badge_KF2022_se.png'),
      text: 'Karriärföretag 2022',
      url: EXTERNAL_WEB_SITE_HR_MANGER,
    },
    {
      image: require('../../assets/images/gather_logo.png'),
      text: 'Gather 2019',
      url: 'https://www.gatherfestival.com/',
    },
  ];
}

if (COUNTRY === 'NO') {
  APP_STORE_ICON =
    require('../../assets/icons/app_store_badge_no.svg')?.default;
  GOOGLE_PLAY_ICON =
    require('../../assets/icons/google_play_badge_no.svg')?.default;
}

const COUNTRY_DROPDOWN_OPTIONS = [
  {
    label: 'Finland',
    value: REGIONAL_WEB_SITE_FINLAND,
    countryCode: 'FI',
  },
  { label: 'Sweden', value: REGIONAL_WEB_SITE_SWEDEN, countryCode: 'SE' },
  { label: 'Norway', value: REGIONAL_WEB_SITE_NORWAY, countryCode: 'NO' },
  {
    label: 'Estonia',
    value: REGIONAL_WEB_SITE_ESTONIA,
    countryCode: 'EE',
  },
  { label: 'Latvia', value: REGIONAL_WEB_SITE_LATVIA, countryCode: 'LV' },
  {
    label: 'Lithuania',
    value: REGIONAL_WEB_SITE_LITHUANIA,
    countryCode: 'LT',
  },
  { label: 'Poland', value: REGIONAL_WEB_SITE_POLAND, countryCode: 'PL' },
  {
    label: 'Slovakia',
    value: REGIONAL_WEB_SITE_SLOVAKIA,
    countryCode: 'SK',
  },
  {
    label: 'Czech Republic',
    value: REGIONAL_WEB_SITE_CZECHREPUBLIC,
    code: 'CZ',
  },
];

const DEFAULT_COUNTRY_DROPDOWN_VALUE = COUNTRY_DROPDOWN_OPTIONS.find(
  (countryDropDownOption) => countryDropDownOption.countryCode === COUNTRY
);

const LINKS = {
  ramirent: [
    {
      label: 'aboutUs',
      url: ABOUT_PATH,
    },
    {
      label: 'customerService',
      url: CUSTOMER_SERVICE_PATH,
    },
    {
      label: 'news',
      url: NEWS_PATH,
    },
  ],
  offer: [
    {
      label: 'rent',
      url: RENT_PATH,
    },
    {
      label: 'services',
      url: SERVICES_PATH,
    },
    {
      label: 'courses',
      url: COURSES_PATH,
    },
  ],
  group: [
    {
      label: 'aboutUs',
      url: GLOBAL_WEB_SITE_ABOUT,
    },
    {
      label: 'forInvestors',
      url: GLOBAL_WEB_SITE_INVESTORS,
    },
    {
      label: 'news',
      url: GLOBAL_WEB_SITE_NEWS,
    },
  ],
  socialMedia: [
    {
      icon: facebookIcon,
      label: 'Facebook',
      url: EXTERNAL_WEB_SITE_FACEBOOK,
    },
    {
      icon: instagramIcon,
      label: 'Instagram',
      url: EXTERNAL_WEB_SITE_INSTAGRAM,
    },
    {
      icon: twitterIcon,
      label: 'Twitter',
      url: EXTERNAL_WEB_SITE_TWITTER,
    },
    {
      icon: youtubeIcon,
      label: 'Youtube',
      url: EXTERNAL_WEB_SITE_YOUTUBE,
    },
    {
      icon: linkedinIcon,
      label: 'Linkedin',
      url: EXTERNAL_WEB_SITE_LINKEDIN,
    },
  ],
  additional: [
    {
      label: 'privacyPolicy',
      url: `${ABOUT_PATH}/${slugify(translations.privacyPolicy)}`,
    },
  ],
};

if (COUNTRY === 'SE') {
  LINKS.additional.unshift({
    label: 'cookiePolicy',
    url: '/om-oss/cookies',
  });
  LINKS.additional.push({
    label: 'termsAndConditions',
    url: '/om-oss/anvandarvillkor',
  });

  const courseIndex = LINKS.offer.findIndex(
    (offerLink) => offerLink.label === 'courses'
  );
  if (courseIndex > -1) {
    LINKS.offer[courseIndex].url = EXTERNAL_WEB_SITE_SE_COURSES;
  }
  LINKS.offer.push({
    label: 'customerCenter',
    url: CUSTOMER_CENTERS_PATH,
  });

  LINKS.ramirent.push({
    label: 'career',
    url: EXTERNAL_WEB_SITE_HR_MANGER,
  });

  const twitterIndex = LINKS.socialMedia.findIndex(
    (socialMediaLink) => socialMediaLink.label === 'Twitter'
  );
  if (twitterIndex > -1) {
    LINKS.socialMedia.splice(twitterIndex, 1);
  }
}

if (COUNTRY === 'FI') {
  LINKS.additional.unshift({
    label: 'termsAndConditions',
    url: `${ABOUT_PATH}/${slugify(translations.termsAndConditions)}`,
  });
  LINKS.additional.push({
    label: 'reportAbuse',
    url: 'https://ramirent.whistleblowernetwork.net',
  });
  LINKS.group.push({
    label: 'career',
    url: GLOBAL_WEB_SITE_CAREERS,
  });

  const investorIndex = LINKS.group.findIndex(
    (investorLink) => investorLink.url === GLOBAL_WEB_SITE_INVESTORS
  );
  if (investorIndex > -1) {
    LINKS.group.splice(investorIndex, 1);
  }

  const customerServiceIndex = LINKS.ramirent.findIndex(
    (customerServiceLink) => customerServiceLink.url === CUSTOMER_SERVICE_PATH
  );
  if (customerServiceIndex > -1) {
    LINKS.ramirent[customerServiceIndex].url = `${ABOUT_PATH}/asiakaspalvelu`;
  }
}

if (COUNTRY === 'NO') {
  LINKS.additional.unshift({
    label: 'cookiePolicy',
    url: '/om-oss/informasjonskapsler',
  });
  LINKS.additional.push({
    label: 'reservation',
    url: '/om-oss/forbehold',
  });
  LINKS.ramirent.push({
    label: 'career',
    url: '/om-oss/karriere',
  });
  LINKS.offer.push({
    label: 'customerPortal',
    url: 'https://customerportal.ramirent.no/',
  });
}

export {
  APP_STORE_ICON,
  APP_STORE_LINK,
  COUNTRY_BADGES,
  COUNTRY_DROPDOWN_OPTIONS,
  DEFAULT_COUNTRY_DROPDOWN_VALUE,
  GOOGLE_PLAY_ICON,
  GOOGLE_PLAY_LINK,
  LINKS,
};

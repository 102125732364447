import React, { useState, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import Downshift from 'downshift';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { debounce } from 'throttle-debounce';
import { groupPages } from '../../../utils/grouping';
import GET_SUGGESTIONS from '../../../api/graphQL/queries/getSearchSuggestions';
import closeIcon from '../../../assets/icons/close_icon.svg';
import searchIcon from '../../../assets/icons/search_icon_white.svg';
import Button from '../../components/Button';
import { GoogleAnalyticsEvent } from '../../components/GoogleAnalytics';
import Loader from '../../components/Loader';
import {
  COUNTRY,
  GOOGLE_ANALYTICS_CATEGORY_INPUT,
  GOOGLE_ANALYTICS_LABEL_SEARCH_BAR,
  SEARCH_PATH,
} from '../../../constants';
import { getSuggestionPath, suggestionToString } from './SearchBarUtils';
import { noPrintMixin } from '../../../theme';
import translations from '../../../translations';
import SectionTitle from './components/SectionTitle';
import Suggestion from './components/Suggestion';

const MAX_VISIBLE_IN_EACH_CATEGORY = 10;

const StyledSearchBar = styled.form`
  ${noPrintMixin};
  background-color: ${(props) => props.theme.color.white};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-shadow: 0 2px 4px 0 rgba(63, 65, 66, 0.24);
  margin: 0 auto;
  max-width: ${(props) => props.theme.page.maxWidth};
  position: relative;

  > div {
    display: flex;
    width: 100%;
  }
`;

const StyledInput = styled.input`
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.small} 0 0
    ${(props) => props.theme.borderRadius.small};
  color: ${(props) => props.theme.color.grayDarker};
  font: inherit;
  margin: 0;
  padding: ${(props) => props.theme.space['12px']};
  /* Adjust for the loader and clear button */
  padding-right: 65px;
  text-overflow: ellipsis;
  width: 100%;

  ::placeholder {
    color: ${(props) => props.theme.color.gray};
  }

  ::-ms-clear {
    display: none;
  }

  ${(props) => props.theme.device.tablet`
    padding: ${(props) => props.theme.space['12px']}
      ${(props) => props.theme.space['24px']};
  `}
`;

const ClearButton = styled(Button)`
  background-image: url(${closeIcon});
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  height: 100%;
  padding: 10px;
  position: absolute;
  width: 40px;
  flex: none;
  right: 50px;
  z-index: 1;
`;

const SearchButton = styled(Button)`
  background-color: ${(props) => props.theme.color.blue};
  background-image: url(${searchIcon});
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px 17px;
  border-radius: 0 ${(props) => props.theme.borderRadius.small}
    ${(props) => props.theme.borderRadius.small} 0;
  padding: 10px;
  width: 46px;
  flex: none;
`;

const InputLoader = styled(Loader)`
  bottom: 21px;
  left: auto;
  right: 60px;
  z-index: 1;
`;

const SuggestionsContainer = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.gray};
  border-radius: ${(props) => props.theme.borderRadius.small};
  color: ${(props) => props.theme.color.blue};
  display: flex;
  flex-wrap: wrap;
  max-height: 420px;
  overflow-y: scroll;
  padding: ${(props) => props.theme.space['16px']};
  padding-top: ${(props) => props.theme.space['4px']};
  position: absolute;
  top: 52px;
  width: 100%;
  /* The map search has z-index 4 */
  z-index: 5;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.color.orange};
    border: ${(props) => props.theme.border['4px']} rgba(0, 0, 0, 0);
    border-radius: ${(props) => props.theme.borderRadius.large};
  }

  ${(props) => props.theme.device.tablet`
    max-height: 600px;
    padding: ${(props) => props.theme.space['8px']} ${(props) =>
    props.theme.space['24px']};
  `}
`;

const StyledSectionContainer = styled.div`
  width: 100%;
`;

const Section = styled.ul`
  padding: 0;

  ${(props) => props.theme.device.desktop`
    column-count: ${(props) => (props.oneColumn ? '1' : '2')};
    column-width: auto;
    flex-direction: row;
  `}
`;

const SearchBar = () => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const navigate = useNavigate();
  const client = useApolloClient();
  const textInput = useRef();

  const blurInput = () => {
    textInput.current.blur();
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    blurInput();

    if (inputValue.length > 2) {
      navigate(`${SEARCH_PATH}?q=${inputValue}`);
    }

    handleSuggestionsClearRequested();
  };

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      // Handle isOpen
      case Downshift.stateChangeTypes.changeInput:
        if (
          !changes.inputValue ||
          changes.inputValue === '' ||
          changes.inputValue.length < 2
        ) {
          return { ...changes, isOpen: false };
        }
        return changes;

      // Handle enter click
      case Downshift.stateChangeTypes.keyDownEnter:
        navigate(getSuggestionPath(changes.selectedItem, state.inputValue));
        blurInput();
        return { ...changes, onFocus: false };

      // Handle item click
      case Downshift.stateChangeTypes.clickItem:
        navigate(getSuggestionPath(changes.selectedItem, state.inputValue));
        blurInput();
        return { ...changes, onFocus: false };

      // Handle outside of menu click
      case Downshift.stateChangeTypes.mouseUp:
        blurInput();
        return changes;

      default:
        if (state.inputValue === '' || state.inputValue < 2) {
          return { ...changes, isOpen: false };
        }
        return changes;
    }
  };

  const loadSuggestions = async (searchTerm) => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_INPUT,
      `Searched For: ${searchTerm}`,
      GOOGLE_ANALYTICS_LABEL_SEARCH_BAR
    );

    try {
      setSuggestionsLoading(true);
      const { data } = await client.query({
        query: GET_SUGGESTIONS,
        variables: {
          country: COUNTRY,
          query: searchTerm,
          limit: 100,
        },
      });
      setSuggestionsLoading(false);

      const { blogPosts, catclasses, faq, locations, news, references, pages } =
        data.search;
      const visibleSuggestions = [];
      const groupedPages = groupPages(pages);
      if (catclasses.length > 0) {
        visibleSuggestions.push({
          title: translations.products,
          suggestions: [...catclasses].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (locations.length > 0) {
        visibleSuggestions.push({
          title: translations.customerCenter,
          suggestions: [...locations].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (references.length > 0) {
        visibleSuggestions.push({
          title: translations.references,
          suggestions: [...references].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (blogPosts.length > 0) {
        visibleSuggestions.push({
          title: translations.blogPost,
          suggestions: [...blogPosts].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (news.length > 0) {
        visibleSuggestions.push({
          title: translations.newsPost,
          suggestions: [...news].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (groupedPages.length > 0) {
        groupedPages.forEach((parentPage) => {
          visibleSuggestions.push({
            title: parentPage.title,
            suggestions: [...parentPage.pages].slice(
              0,
              MAX_VISIBLE_IN_EACH_CATEGORY
            ),
          });
        });
      }

      if (faq.length > 0) {
        visibleSuggestions.push({
          title: translations.faqBanner.title,
          suggestions: [...faq].slice(0, MAX_VISIBLE_IN_EACH_CATEGORY),
        });
      }

      if (visibleSuggestions.length === 0) {
        visibleSuggestions.push({
          title: translations.noResultsFound,
          suggestions: [translations.noResultsFound],
        });
      }

      visibleSuggestions.push({
        title: translations.searchBar.seeAllResults,
        suggestions: [translations.searchBar.seeAllResults],
      });
      setSuggestions(visibleSuggestions);
    } catch (error) {
      setSuggestionsLoading(false);
      setSuggestions([]);
    }
  };

  const loadSuggestionsDebounced = useRef();
  if (!loadSuggestionsDebounced.current) {
    loadSuggestionsDebounced.current = debounce(500, loadSuggestions);
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);

    if (event.target.value.length > 2) {
      loadSuggestionsDebounced.current(event.target.value);
    }
  };

  const handleInputClear = () => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_INPUT,
      'Clicked clear input field',
      GOOGLE_ANALYTICS_LABEL_SEARCH_BAR
    );

    setInputValue('');
    handleSuggestionsClearRequested();
  };

  const seeAllResults = translations.searchBar.seeAllResults;

  return (
    <StyledSearchBar onSubmit={handleSubmit} data-cy="searchBar">
      <Downshift
        inputValue={inputValue}
        itemToString={suggestionToString}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
        }) => (
          <div>
            <StyledInput
              ref={textInput}
              {...getInputProps({
                inputValue,
                isOpen,
                onChange: handleChange,
                onFocus: openMenu,
                placeholder: translations.searchBar.placeholder,
              })}
            />
            {!isOpen || !suggestions || suggestions.length < 1 ? null : (
              <SuggestionsContainer>
                {
                  suggestions.reduce(
                    (result, section, sectionIndex) => {
                      result.sections.push(
                        inputValue && (
                          <StyledSectionContainer
                            key={sectionIndex}
                            data-cy="sectionContainer"
                          >
                            <SectionTitle title={section.title} />
                            <Section
                              oneColumn={section.title === seeAllResults}
                            >
                              {section.suggestions.map(
                                (suggestion, suggestionIndex) => {
                                  const index = result.itemIndex++;
                                  return (
                                    suggestion && (
                                      <Suggestion
                                        key={suggestionIndex}
                                        suggestion={suggestion}
                                        country={COUNTRY}
                                        {...getItemProps({
                                          key: index,
                                          index: index,
                                          item: suggestion,
                                          style: {
                                            backgroundColor:
                                              section.title !== seeAllResults &&
                                              highlightedIndex === index
                                                ? '#f8f9fa' // grayLighter
                                                : 'white',
                                          },
                                        })}
                                      />
                                    )
                                  );
                                }
                              )}
                            </Section>
                          </StyledSectionContainer>
                        )
                      );

                      return result;
                    },
                    { sections: [], itemIndex: 0 }
                  ).sections
                }
              </SuggestionsContainer>
            )}
            {suggestionsLoading && <InputLoader small />}
            {!suggestionsLoading && inputValue && (
              <ClearButton onClick={handleInputClear} type="button" />
            )}
            <SearchButton
              aria-label="Search"
              type="submit"
              data-cy="searchButton"
              id="searchButton"
            />
          </div>
        )}
      </Downshift>
    </StyledSearchBar>
  );
};

export default SearchBar;

import crypto from 'crypto-browserify';
import routeTranslations from '../routeTranslations';
import { COUNTRY } from '../constants';
import slugify from './slug';

export const RENTAL_KEYS = {
  SLUG_ID: 'slugId',
  RENTAL_ITEM_ID: 'rentalItemId',
};

export const createSlugId = (str) => {
  return crypto.createHash('sha256').update(str).digest('hex');
};

export const rentPathRewrite = (country) => {
  return {
    SE: 'SE_PRODUKTER',
    FI: 'FI_TUOTTEET',
    NO: 'NO_PRODUKT',
  }[country];
};

export const removeBeginningAndEndingSlashes = (str) =>
  str.replace(/^\//, '').replace(/\/$/, '');

export const getRentPathQueryKey = (path) => {
  const rentRegex = new RegExp(routeTranslations.rentPath, 'g');
  return path.replace(rentRegex, rentPathRewrite(COUNTRY));
};

export const createNodeQueryPath = (input) => {
  return {
    [RENTAL_KEYS.SLUG_ID]: createSomething(input),
  };
};

const createSomething = (arg) =>
  createSlugId(removeBeginningAndEndingSlashes(getRentPathQueryKey(arg)));

const createNodeIdForRentalItemSearch = (input) => ({
  ...input,
  [RENTAL_KEYS.SLUG_ID]: createSomething(
    splitPathByProductId(input[RENTAL_KEYS.SLUG_ID])[0]
  ),
});

// eslint-disable-next-line no-sequences
const zipObject = (arr1, arr2) =>
  arr1.reduce((acc, value, i) => ((acc[value] = arr2[i]), acc), {});

const createCrumbsWithLinks = (acc, curr) => {
  let link =
    acc.length > 0 ? acc[acc.length - 1].link + '/' + curr : '/' + curr;
  const key = acc.length > 0 ? acc[acc.length - 1].key + 1 : 0;

  const obj = {
    key,
    link,
  };
  return acc.concat(obj);
};

export const splitPathByProductId = (path) =>
  path.split(new RegExp(/(\/\d{5}[0-9]*)/gm));

export const splitAncestorNames = (names) => names.split('#_#');

export const getProductIdFromPath = (path) => path.split('/').find(Number);

export const createProductQueryPath = (path) => {
  const parts = splitPathByProductId(path);
  const mappedParts = parts.map(removeBeginningAndEndingSlashes);
  const zipped = zipObject(Object.values(RENTAL_KEYS), mappedParts);
  return createNodeIdForRentalItemSearch(zipped);
};

export const getIsProductPage = (path) =>
  getProductIdFromPath(path)?.length > 0;

export const createRentalItemId = (path) => {
  return `${COUNTRY}_${getProductIdFromPath(path)}`;
};

export const constructQuery = (query) =>
  getIsProductPage(query)
    ? createProductQueryPath(query)
    : createNodeQueryPath(query);

export const createBreadcrumbRentalLinks = (pathname) =>
  removeBeginningAndEndingSlashes(pathname)
    .split('/')
    .reduce(createCrumbsWithLinks, []);

export const createRentalBreadcrumbs = ({
  path,
  rootName,
  ancestorNames,
  currentName,
}) => {
  const modifiedAncestorNames = [
    rootName,
    ...splitAncestorNames(ancestorNames).slice(1),
  ];

  const createCrumbItem = (item, index) => ({
    ...item,
    name: modifiedAncestorNames[index],
  });

  return [
    ...removeBeginningAndEndingSlashes(path)
      .split('/')
      .slice(0, -1)
      .reduce(createCrumbsWithLinks, [])
      .map(createCrumbItem),
    {
      key: modifiedAncestorNames.length,
      name: currentName,
    },
  ];
};

const removeProductNameIfExists = (path) => {
  const productId = getProductIdFromPath(path);
  const pathArray = path.split('/');
  return pathArray[pathArray.length - 1] === productId
    ? path
    : pathArray.slice(0, -1).join('/');
};

export const createRentalBreadcrumbsForProduct = (
  parentConfig,
  productName
) => {
  const productPathWithoutName = removeProductNameIfExists(
    removeBeginningAndEndingSlashes(parentConfig.path)
  );

  return createRentalBreadcrumbs({
    rootName: parentConfig.rootName,
    path: productPathWithoutName,
    ancestorNames: [parentConfig.ancestorNames, parentConfig.currentName].join(
      '#_#'
    ),
    currentName: productName,
  });
};

export const createRentalBreadcrumbsForSalesItem = (
  { path, rootName, ancestorNames, rentalItemParentNode, rentalItem },
  productName
) => {
  const urlSegments = removeBeginningAndEndingSlashes(path).split('/');
  const modifiedAncestorNames = [
    rootName,
    ...ancestorNames.split('#_#').slice(1),
  ];
  const fullAncestorNames = [
    ...modifiedAncestorNames,
    rentalItemParentNode.name,
    '', // Pad names array to compensate for the fact that the url has redundant segments
    rentalItem.name,
  ];

  const createCrumbObject = (name, i) => ({
    key: i,
    link: `/${urlSegments.slice(0, i + 1).join('/')}`,
    name,
  });
  const crumbs = fullAncestorNames.map(createCrumbObject);
  crumbs.splice(crumbs.length - 2, 1); // Remove the padding from above

  return [
    ...crumbs,
    {
      key: crumbs.length + 1,
      name: productName,
    },
  ];
};

export const rewriteParentPath = (parentSlug) => {
  return parentSlug.replace(
    rentPathRewrite(COUNTRY),
    routeTranslations.rentPath
  );
};

export const createProductLink = (node, product) => {
  const slugifiedProductName = slugify(product.name);
  const slugifiedNodeName = slugify(node.name);
  const parentPathRewrite = rewriteParentPath(node.parentSlug);

  const rentalItemIdNoCountryCode = product.rentalItemId.split('_')[1];
  return `/${parentPathRewrite}/${slugifiedNodeName}/${rentalItemIdNoCountryCode}/${slugifiedProductName}`;
};

export const createSalesItemLink = (parentRentalItem, translations, id) => {
  const parentLink = createProductLink(
    {
      parentSlug: parentRentalItem.node.parentSlug,
      name: parentRentalItem.node.name,
    },
    { name: parentRentalItem.name, rentalItemId: parentRentalItem.rentalItemId }
  );

  return `${parentLink}/${slugify(translations.salesItem)}/${extractPimId(id)}`;
};

const extractPimId = (itemId) => itemId.split('_')[1];

export const isSalesItemUrl = (url, translations) =>
  url.split('/').slice(-2)[0].includes(slugify(translations.salesItem));

export const extractSalesItem = (path, data) => {
  const salesItemName = path.split('/').slice(-1)[0];
  const rentalItem = data?.nodeRentalItems && data.nodeRentalItems[0];
  return rentalItem?.salesItems?.find(
    (item) => extractPimId(item.salesItemId) === salesItemName
  );
};

import React from 'react';
import styled from 'styled-components/macro';

import Link from '../../../../components/Link';

const StyledRamiPromotionLink = styled.div`
  /* Remove top margin */
  margin-top: -${(props) => props.theme.page.marginTop};
  text-align: center;

  ${(props) => props.theme.device.desktop`
    min-height: 70px;
  `};
`;

const PromotionLinkWrapper = styled.div`
  padding: ${(props) => props.theme.space['24px']} 0;
`;

const PromotionLink = styled(Link)`
  border-bottom: ${(props) => props.theme.border['2px']}
    ${(props) => props.theme.color.orange};
  color: ${(props) => props.theme.color.orange};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  line-height: 1.5;
  padding-bottom: ${(props) => props.theme.space['4px']};
  text-decoration: none;
  ${(props) => {
    if (props.large) return `font-size: ${props.theme.font.size['28px']}`;
  }}
`;

const Label = styled.span`
  color: ${(props) => props.theme.color.grayDarker};
`;

const RamiPromotionLink = ({ children, large, ...props }) => (
  <StyledRamiPromotionLink data-cy="ramiPromotionLink">
    <PromotionLinkWrapper>
      <PromotionLink large={large ? 'true' : undefined} {...props}>
        <>
          {props.label && <Label>{props.label} </Label>}
          {children}
        </>
      </PromotionLink>
    </PromotionLinkWrapper>
  </StyledRamiPromotionLink>
);

export default RamiPromotionLink;

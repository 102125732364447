const finnishTranslations = {
  aboutUs: 'Tietoa Ramirentistä',
  accessories: 'Lisätarvikkeet',
  articles: 'Artikkelit',
  blog: 'Blogi',
  blogPost: 'Blogipostaus',
  career: 'Ura',
  contactUs: 'Ota yhteyttä',
  cookiePolicy: 'Cookiepolicy',
  courses: 'Koulutukset',
  customerCenter: 'Etsi lähin vuokraamosi',
  customerPortal: 'Customer Portal',
  customerService: 'Asiakaspalvelu',
  sustainability: 'Kestävyys',
  day: 'päivä',
  documents: 'Asiakirjat',
  excludingVat: 'alv 0%',
  factsTableHeader: '',
  filterByYear: 'Lajittele vuosiluvun mukaan',
  findCustomerCenter: 'Etsi lähin vuokraamo',
  forInvestors: 'Sijoittajat',
  goToCustomerCenter: 'Siirry vuokraamoon',
  goToHome: 'Siirry aloitussivulle',
  home: 'Koti',
  loadMore: 'Lataa lisää',
  marketing: 'Markkinointi',
  minimize: 'Minimoida',
  necessary: 'Välttämätön',
  news: 'Uutiset',
  newsPost: 'Uutinen',
  noResultsFound: 'Tuloksia ei löytynyt',
  openInGoogleMaps: 'Google Maps',
  pressroom: 'Ajankohtaista',
  privacyPolicy: 'Tietosuoja',
  productNumber: 'Tuotenumero',
  products: 'Tuotteet',
  productType: 'Tuotetyyppi',
  published: 'Julkaistu',
  readMore: 'Lue lisää',
  references: 'Referenssit',
  rent: 'Vuokraa',
  rental: 'Vuokraus',
  reportAbuse: 'Raportoi väärinkäytöksestä',
  reservation: 'Reservation',
  salesItem: 'Myynti',
  salesItems: 'Myyntierät',
  seeAll: 'Katso lisää',
  search: 'Etsi',
  searchResults: 'Hakutulos',
  searchResultsFor: 'Etsi tuloksia',
  services: 'Palvelut',
  shareThis: 'Jaa tämä',
  statistics: 'Tilastot',
  technicalInformation: 'Tekniset tiedot',
  termsAndConditions: 'Ehdot',
  articlesBanner: {
    subTitle: '',
    title: 'Artikkelit',
  },
  blogBanner: {
    subTitle: '',
    title: 'Blogit',
  },
  contactForm: {
    close: 'Close',
    email: 'E-mail',
    name: 'Name',
    phone: 'Phone number',
    location: 'Location',
    send: 'Send',
    yourMessage: 'Your message',
    successTitle: 'Thank you for your message!',
    successMessage: 'We will get back to you as soon as we can.',
    errorTitle: 'Something went wrong',
    errorMessage: 'Try again or contact customer service directly.',
    rules: {
      fullName: 'Full name (First and last name)',
      phone: 'Only phone numbers',
      required: 'Required',
      least6: 'must be at least 6 characters',
      max50: 'can be a maximum of 50 characters',
      max500: 'can be a maximum of 500 characters',
    },
  },
  contactPersonsBanner: {
    subTitle: '',
    title: 'Yhteyshenkilöt',
  },
  customerCenterPage: {
    address: 'Osoite',
    openingHours: 'Avoinna',
    specialOpeningHours: 'Poikkeavat aukioloajat',
    closed: 'Suljettu',
    weekDays: {
      mon: 'Maanantai',
      tue: 'Tiistai',
      wed: 'Keskiviikko',
      thu: 'Torstai',
      fri: 'Perjantai',
      sat: 'Lauantai',
      sun: 'Sunnuntai',
    },
    description: 'Kuvaus',
  },
  customerCentersBanner: {
    byDistance: 'Etäisyys',
    byName: 'A-Ö',
    noBrowserSupport: 'Selaimesi ei tue karttanäkymää!',
    searchPlaceholder: 'Etsi kaupungin, kadun tai sijainnin mukaan',
    subTitle:
      'Palvelujen hinta soittaessa 0207 - alkuisiin numeroihin: kiinteästä verkosta: 8,35 snt/puh. + 7,02 sn/min (alv 24%), matkapuhelimesta: 8,35 snt/puh. + 17,17 sn/min (alv 24%), ulkomailta: tavallinen ulkomaanpuheluhinta.',
    title: 'Etsi vuokraamoa',
  },
  dataErrorBanner: {
    description:
      'Odota ja yritä uudelleen. Jos virhe esiintyy edelleen, ota yhteyttä asiakastukeen.',
    title: 'Virhe ladattaessa tietoja.',
  },
  emptyCategoryPage: {
    description: 'Tässä kategoriassa ei tällä hetkellä ole tuotteita.',
    goBackTo: 'Palaa takaisin',
    title: 'Tyhjä!',
  },
  faqBanner: {
    subTitle:
      'Olemme keränneet sinulle vastaukset useimmin kysyttyihin kysymyksiin',
    title: 'Usein kysytyt kysymykset',
  },
  footerHeaders: {
    apps: 'Sovellukset',
    group: 'Group',
    ourOffer: 'Palvelumme',
    ramirentInTheWorld: 'Valitse maa',
  },
  informationBanner: {
    customerCenterDescription: 'Työntekijämme auttavat sinua aina mielellään',
    customerCenterTitle: 'Etsi lähin vuokraamosi',
    emailDescription: 'Vastaamme tavallisesti vuorokauden sisällä',
    phoneDescription: 'Soita meille, olemme täällä auttaaksemme sinua',
    commonQuestionsDescription:
      'Olemme koonneet tänne vastaukset tavallisimpiin kysymyksiin',
    commonQuestionsTitle: 'Yleisimmät kysymykset ja vastaukset',
    newsletterDescription: 'Täällä voit ilmoittautua uutiskirjeemme',
    newsletterTitle: 'Tilaa uutiskirje',
  },
  managementBanner: {
    subTitle: '',
    title: 'Hallinto',
  },
  newsBanner: {
    subTitle: '',
    title: 'Uutiset',
  },
  notFoundPage: {
    description: 'Valitettavasti emme löytäneet etsimääsi sivua.',
    title: 'Oho!',
  },
  popularProductsBanner: {
    subTitle: '',
    title: 'Suosittuja tuotteita',
  },
  referenceBanner: {
    subTitle: '',
    title: 'Referenssit',
  },
  referencePage: {
    factsTableHeader: 'Projektin tiedot',
    moreReferences: 'Lisää referenssejä',
    productsUsedInProject: 'Projektissa käytetyt tuotteet',
  },
  searchBar: {
    placeholder: 'Etsi tuotteita, palveluita ja vuokraamoita',
    seeAllResults: 'Katso kaikki tulokset',
  },
  usefulLinksBanner: {
    subTitle: 'Viimeisimmät',
    title: 'Hyödyllisiä linkkejä',
  },
  quickLinksBanner: {
    subTitle: 'Viimeisimmät',
    title: 'Pikalinkit',
  },
};

export default finnishTranslations;

import React, { useState } from 'react';
import {
  Accordion as UnstyledAccordion,
  AccordionItem as UnstyledAccordionItem,
  AccordionItemButton as UnstyledAccordionItemButton,
  AccordionItemHeading as UnstyledAccordionItemHeading,
  AccordionItemPanel as UnstyledAccordionItemPanel,
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import caretDownIcon from '../../../assets/icons/caret_down_icon_blue.svg';
import {
  GOOGLE_ANALYTICS_CATEGORY_BUTTON,
  GOOGLE_ANALYTICS_LABEL_FAQ,
} from '../../../constants';
import { GoogleAnalyticsEvent } from '../GoogleAnalytics';
import Header4 from '../Header4';
import HorizontalRule from '../HorizontalRule';
import CustomMarkdown from '../../views/customMarkdown/CustomMarkdown';

const StyledAccordion = styled(UnstyledAccordion)`
  display: flex;
  flex-direction: column;
`;

const StyledAccordionItemHeading = styled(UnstyledAccordionItemHeading)`
  padding-bottom: ${(props) => props.theme.space['16px']};
  position: relative;
`;

const StyledAccordionItemButton = styled(UnstyledAccordionItemButton)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  margin-left: ${(props) => props.theme.space['48px']};
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  width: ${(props) => props.theme.icon.size['32px']};

  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `}
`;

const Accordion = ({ items }) => {
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleAccordionChange = (uuids) => {
    setExpandedPanels([...uuids]);

    // Only track on expand
    if (uuids.length) {
      GoogleAnalyticsEvent(
        GOOGLE_ANALYTICS_CATEGORY_BUTTON,
        `Expanded: ${uuids[0]}`,
        GOOGLE_ANALYTICS_LABEL_FAQ
      );
    }
  };

  return (
    <StyledAccordion
      allowZeroExpanded={true}
      onChange={handleAccordionChange}
      data-cy="accordionWrapper"
    >
      {items.map((item) => {
        const accordionKey = item.title.replace(/\s/g, "");
        return (
          <UnstyledAccordionItem
            key={accordionKey}
            uuid={accordionKey}
            data-cy="accordionItem"
          >
            <StyledAccordionItemHeading>
              <StyledAccordionItemButton data-cy="accordionItemButton">
                <Header4 inline>{item.title}</Header4>
                <Icon
                  alt=""
                  expanded={expandedPanels.includes(accordionKey)}
                  src={caretDownIcon}
                />
              </StyledAccordionItemButton>
            </StyledAccordionItemHeading>
            <UnstyledAccordionItemPanel data-cy="accordionPanel">
              <AnimateHeight
                duration={500}
                height={expandedPanels.includes(accordionKey) ? 'auto' : 0}
              >
                <CustomMarkdown>{item.copy}</CustomMarkdown>
              </AnimateHeight>
            </UnstyledAccordionItemPanel>
            <HorizontalRule />
          </UnstyledAccordionItem>
        );
      })}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Accordion;

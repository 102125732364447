import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  /* The search results has z-index 5 */
  z-index: 7;
  display: flex;
  justify-content: center;
`;

const Overlay = ({ onClick, visible, children }) => {
  if (!visible) {
    return null;
  }

  return <StyledOverlay onClick={onClick}>{children}</StyledOverlay>;
};

Overlay.propTypes = {
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Overlay;

import React from 'react';

import BannerHeader from '../../../components/BannerHeader';

const getSubtitle = (mdData) => {
  if (typeof mdData === 'string') {
    return mdData;
  } else if (
    typeof mdData === 'object' &&
    mdData &&
    mdData.props?.children &&
    mdData.props?.children[0] &&
    typeof mdData.props?.children[0] === 'string'
  ) {
    return mdData.props?.children[0];
  } else {
    return null;
  }
};

const RamiHeader = ({ children, ...props }) => (
  <BannerHeader subTitle={getSubtitle(children[0])} {...props} />
);

export default RamiHeader;

import { COUNTRY } from '../../../constants';
import dateFormat from '../../../utils/dateFunctions';
import { getBasePathFromType } from '../../../utils/link';

const formatFactsTable = (tableData) => {
  let newTableData = tableData
    .map((data) => ({ type: data[0], value: data[1] }))
    .filter((data) => data.type || data.value);

  // Delete first row since it consists of header values
  newTableData.shift();

  if (newTableData.length === 0) {
    return undefined;
  }

  return newTableData;
};

const formatReferenceEntity = (referenceEntity) => ({
  bannerImage: {
    url: `https:${referenceEntity.bannerImage.fields.file.url}`,
    alt: referenceEntity.bannerImage.fields.title,
  },
  imageThumb: `https:${referenceEntity.bannerImage.fields.file.url}?fm=jpg&q=70&w=1140&fit=thumb`,
  copy: referenceEntity.copy,
  date: dateFormat(new Date(referenceEntity.date)),
  factsTable:
    referenceEntity.factsTable &&
    formatFactsTable(referenceEntity.factsTable.tableData),
  images:
    referenceEntity.images &&
    referenceEntity.images.map(
      (image) => `https:${image.fields.file.url}?fm=jpg&q=70&w=1140`
    ),
  mapCoordinates: referenceEntity.location && {
    lat: referenceEntity.location.lat,
    lng: referenceEntity.location.lon,
  },
  preamble: referenceEntity.preamble,
  primaryLink: referenceEntity.primaryLink,
  primaryLinkText: referenceEntity.primaryLinkText,
  products:
    referenceEntity.products &&
    referenceEntity.products
      .replace(/\s/g, '')
      .split(',')
      .map((product) => `${COUNTRY}_${product}`),
  showLatestReferenceEntities: referenceEntity.showLatestReferenceEntities,
  slug: referenceEntity.slug,
  teaser: referenceEntity.teaser,
  title: referenceEntity.title,
});

const formatReferenceEntityForList = (referenceEntity) => {
  const referenceObject = formatReferenceEntity(referenceEntity);
  return {
    ...referenceObject,
    type: 'referenceEntity',
    images: [referenceObject.bannerImage],
    completeSlug: `${getBasePathFromType('referenceEntity')}${
      referenceEntity.slug
    }`,
  };
};

export { formatReferenceEntity, formatReferenceEntityForList };

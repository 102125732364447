import { gql } from '@apollo/client';

const GET_SUGGESTIONS = gql`
  query Search($country: Country!, $query: String!, $limit: Int) {
    search(
      country: $country
      type: [BLOGPOSTS, CATCLASSES, FAQ, LOCATIONS, NEWS, REFERENCES, PAGES]
      query: $query
      limit: $limit
    ) {
      blogPosts {
        slug
        teaser
        title
      }
      catclasses {
        rentalItemId
        images {
          url
        }
        make
        model
        name
        hideMakeModelFi
        hideMakeModelNo
        hideMakeModelSe
        isRamiGreenFi
        isRamiGreenNo
        isRamiGreenSe
        parent {
          name
          slug
        }
        specifications {
          name
          value
        }
      }
      faq {
        copy
        title
      }
      locations {
        address
        description
        email
        id
        name
        phoneNumber
        slug
      }
      news {
        slug
        teaser
        title
      }
      references {
        slug
        teaser
        title
      }
      pages {
        slug
        teaser
        title
        completeSlug
        mainTitle
        icon
      }
    }
  }
`;

export default GET_SUGGESTIONS;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';
import closeIcon from '../../assets/icons/close_icon.svg';
import ContactInformation from './components/ContactInformation';
import SocialMediaLinks from './components/SocialMediaLinks';
import Button from '../../shared/components/Button';
import HorizontalRule from '../../shared/components/HorizontalRule';
import getNavigationBar from '../../api/contentful/getNavigationBar';
import getSideBar from '../../api/contentful/getSideBar';
import NavigationMenuLink, {
  navigationMenuLinkStyles,
} from '../../shared/components/NavigationMenuLink';
import Link from '../../shared/components/Link';

const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
`;

const StyledSidebar = styled.div`
  animation: ${(props) => (props.open ? slideInLeft : slideOutLeft)} 0.2s ease;
  background-color: ${(props) => props.theme.color.yellow};
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 100vh;
  overflow-y: auto;
  padding: ${(props) => props.theme.space['24px']};
  position: fixed;
  top: 0;
  transition: visibility 0.2s ease;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  /* The overlay has z-index 6 */
  z-index: 7;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 24px;
  top: 24px;
`;

const CloseIcon = styled.img`
  height: ${(props) => props.theme.icon.size['20px']};
  width: ${(props) => props.theme.icon.size['20px']};
`;

const TopWrapper = styled.div`
  margin-top: ${(props) => props.theme.space['32px']};
`;

const sidebarLinkStyles = css`
  display: inline-block;
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  padding-bottom: ${(props) => props.theme.space['24px']};
`;

const SidebarLink = styled(NavigationMenuLink)`
  ${sidebarLinkStyles};
`;

const SidebarExternalLink = styled(Link)`
  ${navigationMenuLinkStyles};
  ${sidebarLinkStyles};
`;

const Divider = styled(HorizontalRule)`
  background-color: ${(props) => props.theme.color.yellowDark};
  flex: none;
  margin: ${(props) => props.theme.space['24px']} 0;
  margin-top: auto;
`;

const BottomWrapper = styled.div`
  display: flex;
`;

const Sidebar = ({ onClose, open }) => {
  const [menuOption, setMenuOption] = useState([]);
  const [sideBarFields, setSideBarFields] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getNavigationBar();
      const sideRes = await getSideBar();
      setSideBarFields(sideRes || []);
      setMenuOption(response?.menuOptions || []);
    };
    getData();
  }, []);

  return (
    <StyledSidebar open={open}>
      <CloseButton onClick={onClose}>
        <CloseIcon alt="Close" src={closeIcon} />
      </CloseButton>
      <TopWrapper>
        {menuOption?.map((link) => (
          <div key={link.id}>
            {link.link ? (
              <SidebarExternalLink
                url={link.link}
                noUnderline
                onClick={onClose}
              >
                {link.title}
              </SidebarExternalLink>
            ) : (
              <SidebarLink to={link.slugList} onClick={onClose}>
                {link.title}
              </SidebarLink>
            )}
          </div>
        ))}
        <Divider />
      </TopWrapper>
      <BottomWrapper>
        <ContactInformation {...sideBarFields.contactInformation} />
        <SocialMediaLinks {...sideBarFields.socialMediaLinks} />
      </BottomWrapper>
    </StyledSidebar>
  );
};
Sidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Sidebar;

import { round } from '../../../utils/math';
import { contentful } from '../../../utils/mediaFunctions';

/**
 * A helper function used to add URL parameters in order to interact with Contentful's API
 * @param url: Base Contentful url, string
 * @param width: Width of image, number
 * @param height: Height of image, number
 * @param format: Image file format. ["jpg", "webp", "png"].
 * @param quality: Image quality where 100 means full quality, number
 * @param fit: Image fit. ["pad", "crop", "fill", "thumb", "scale"]
 * @constructor
 */
export const contentfulImageUrlBuilder = (
  url,
  { width, height, format, quality, fit = 'fill' },
  debug = false
) => {
  const imageUrl = url.replace(`downloads${contentful}`, `images${contentful}`);
  const options = {};
  if (width) {
    options.w = width;
  }
  if (height) {
    options.h = height;
  }
  if (format) {
    options.fm = format;
  }
  if (quality) {
    options.q = quality;
  }
  if (fit) {
    options.fit = fit;
  }
  options.fm = 'webp';

  const query = Object.keys(options)
    .filter((key) => options[key] !== undefined && options[key] !== null)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`
    )
    .join('&');

  const separator = imageUrl.includes('?') ? '&' : '?';

  return !debug
    ? `${imageUrl}${separator}${query}`
    : `https://via.placeholder.com/${options.w}`;
};

/**
 * @summary A helper method for creating the "sizes" property for an <img> tag, which is used together with srcset to dynamically pick an appropriately sized image based on browser size.
 * @param sizes: { [key: number]: number }. E.g. { '768': 1/2, '980': 1/3 }
 *      A key-value object where the key represents a min-width (MQ) for the size to be applied.
 *      It is recommended to use the media query object for the keys. It is important that it is a number.
 *      The value represents the target size of the image. It can be represented as a fraction of the viewport e.g. 1/2 or 1/4, or a an integer if you want to specify pixels, e.g. 375 or 1140.
 *      The recommended way is to use a fraction, as it will make the code a lot less complex and work better with retina displays.
 *      Read MDN documentation for more info. The key/value represent argument one and two of the 'sizes' property on an <img> tag.
 * @returns {string}
 */
export const sizesBuilder = (sizes) => {
  if (sizes === undefined) {
    return null;
  }

  const mapper = (mq) => {
    const query = `(min-width: ${mq}px)`;
    const mqPixels = parseInt(mq, 10);
    const viewportWidth = sizes[mqPixels];
    const isPixels = viewportWidth > 1;

    if (isPixels) {
      return `${query} ${viewportWidth}px`;
    }

    return `${query} ${round(viewportWidth * 100, 2)}vw`;
  };

  let sizesDefault = 100;
  if (sizes['default']) {
    sizesDefault = sizes['default'];
    delete sizes['default'];
  }

  let sizeItems = Object.keys(sizes) // Object.keys return number keys in numeric order
    .map(mapper)
    .reverse(); // Reverse list so that the largest media query comes first
  sizeItems.push(`${sizesDefault}vw`);

  return sizeItems.join(',');
};

/**
 *
 * @param image
 * @param maxWidth
 * @param minWidth
 * @param count
 * @param width
 * @param height
 * @param format
 * @param quality
 * @param fit
 * @returns {Array}
 */
export const sourceSetBuilder = (
  { url, format, fit, quality, maxWidth, minWidth, count, isCSS = false },
  debug = false
) => {
  if (!url) {
    return [];
  }
  const stepSize = (maxWidth - minWidth) / (count - 1);
  const imageSet = [];

  for (let n = 0; n < count; n++) {
    const width = minWidth + stepSize * n;
    const widthRounded = round(width);
    const imageUrl = contentfulImageUrlBuilder(
      url,
      {
        width: widthRounded,
        format: format,
        quality: quality,
        fit: fit,
      },
      debug
    );
    const imageUrlFormat = isCSS ? `url(${imageUrl})` : imageUrl;
    const resolutionFormat = isCSS ? `${n + 1}x` : `${widthRounded}w`;
    imageSet.push(`${imageUrlFormat} ${resolutionFormat}`);
  }
  return imageSet;
};

import styled from 'styled-components/macro';

const Line = styled.div`
  background-color: ${props => props.theme.color.yellow};
  flex: none;
  height: 6px;
  margin-bottom: ${props => props.theme.space['16px']};
  margin-top: ${props => props.theme.space['4px']};
  width: ${props => (props.large ? '100px' : '52px')};
`;

export default Line;

import styled from 'styled-components/macro';
import CustomMarkdown from '../../../shared/views/customMarkdown/CustomMarkdown';
import BoldText from '../../../shared/components/BoldText';
const styleOverrides = {
  strong: {
    component: BoldText,
  },
};

const StyledContactInformation = styled.div`
  padding-right: ${(props) => props.theme.space['32px']};
`;

const StyledCustomMarkdown = styled.div`
  padding-top: ${(props) => props.theme.space['2px']};}
`;

const ContactInformation = (contactInformation) => {
  const list = contactInformation.copy?.split('\n\n');
  return (
    <StyledContactInformation>
      {(list || []).map((row, i) => (
        <StyledCustomMarkdown key={i}>
          <CustomMarkdown extraOverrides={styleOverrides}>{row}</CustomMarkdown>
        </StyledCustomMarkdown>
      ))}
    </StyledContactInformation>
  );
};

export default ContactInformation;

import styled from 'styled-components/macro';

const UnstyledButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
`;

const Button = styled(UnstyledButton)`
  color: ${props => {
    if (props.primary || props.secondary) return props.theme.color.white;
    if (props.tertiary) return props.theme.color.blue;
    return props.theme.color.blue;
  }};
  background-color: ${props => {
    if (props.primary) return props.theme.color.orange;
    if (props.secondary) return props.theme.color.blue;
    return 'none';
  }};
  border: ${props => props.theme.border['4px']}
    ${props => {
      if (props.primary) return props.theme.color.orange;
      if (props.secondary || props.tertiary) return props.theme.color.blue;
      return 'none';
    }};
  font-weight: ${props => {
    if (props.primary || props.secondary || props.tertiary)
      return props.theme.font.weight.semiBold;
    return props.theme.font.weight.regular;
  }};
  outline: ${props => {
    if (props.primary || props.secondary || props.tertiary) return 0;
  }};
  padding: ${props => {
    if (props.primary || props.secondary || props.tertiary)
      return `${props.theme.space['8px']} ${props.theme.space['16px']}`;
    return 0;
  }};
  border-radius: ${props => {
    if (props.primary || props.secondary || props.tertiary)
      return props.theme.borderRadius.larger;
    return 0;
  }};
  text-align: ${props => {
    if (props.primary || props.secondary || props.tertiary) return 'center';
    return 'left';
  }};
  transition: all 0.3s ease;
  transition-property: background-color, border;

  :disabled {
    color: ${props => {
      if (props.primary || props.secondary) return props.theme.color.white;
      if (props.tertiary) return props.theme.color.grayLight;
      return props.theme.color.grayLight;
    }};
    background-color: ${props => {
      if (props.primary || props.secondary) return props.theme.color.grayLight;
      if (props.tertiary) return props.theme.color.white;
      return props.theme.color.grayLight;
    }};
    border: ${props => props.theme.border['4px']}
      ${props => props.theme.color.grayLight};
    cursor: not-allowed;

    :hover {
      background-color: ${props => props.theme.color.gray};
      border-color: ${props => props.theme.color.gray};
    }
  }

  :focus {
    background-color: ${props => {
      if (props.primary) return props.theme.color.orangeDark;
      if (props.secondary) return props.theme.color.blueDark;
      return 'none';
    }};
    border: ${props => props.theme.border['4px']}
      ${props => {
        if (props.primary) return props.theme.color.orangeDark;
        if (props.secondary) return props.theme.color.blueLight;
        if (props.tertiary) return props.theme.color.orange;
        return 'none';
      }};
    color: ${props => {
      if (props.primary || props.secondary) return props.theme.color.white;
      if (props.tertiary) return props.theme.color.blue;
      return props.theme.color.orange;
    }};
  }

  :hover {
    background-color: ${props => {
      if (props.primary) return props.theme.color.orangeLight;
      if (props.secondary) return props.theme.color.blueLight;
      return 'none';
    }};
    border: ${props => props.theme.border['4px']}
      ${props => {
        if (props.primary) return props.theme.color.orangeLight;
        if (props.secondary) return props.theme.color.blueLight;
        if (props.tertiary) return props.theme.color.orange;
        return 'none';
      }};
    color: ${props => {
      if (props.primary || props.secondary) return props.theme.color.white;
      if (props.tertiary) return props.theme.color.blue;
      return props.theme.color.orange;
    }};
  }

  ${props => props.theme.device.tablet`
      padding: ${props => {
        if (props.primary || props.secondary || props.tertiary)
          return `${props.theme.space['12px']} ${props.theme.space['32px']}`;
        return 0;
      }};
  `};
`;

export default Button;

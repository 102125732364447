import React from 'react';
import styled from 'styled-components/macro';

import arrowRightIcon from '../../../../../assets/icons/arrow_right_blue.svg';
import Link from '../../../../components/Link';

const StyledRamiLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  margin-top: ${(props) => props.theme.space['24px']};
  pointer-events: auto;
`;

const ArrowIcon = styled.img`
  height: ${(props) => props.theme.icon.size['18px']};
  margin-right: ${(props) => props.theme.space['8px']};
  width: ${(props) => props.theme.icon.size['18px']};
`;

const RamiLink = ({ children, arrow, ...props }) => (
  <StyledRamiLink noUnderline {...props}>
    {arrow && <ArrowIcon alt="" src={arrowRightIcon} />}
    {children}
  </StyledRamiLink>
);

export default RamiLink;

import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { initDynamicsMarketing } from '../../../../clients/dynamicsMarketing';

const StyledDynamicsMarketingForm = styled.div`
  display: flex;
  justify-content: ${(props) => props.align === 'center' && 'center'};
`;

const DynamicsMarketingForm = ({
  dynamicsDataFormBlockId,
  dynamicsWebsiteId,
  align,
}) => {
  useEffect(() => {
    initDynamicsMarketing(dynamicsDataFormBlockId, dynamicsWebsiteId);
  }, [dynamicsDataFormBlockId, dynamicsWebsiteId]);

  return (
    <StyledDynamicsMarketingForm align={align}>
      <div id={dynamicsWebsiteId} />
    </StyledDynamicsMarketingForm>
  );
};

export default DynamicsMarketingForm;

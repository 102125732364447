import { LOCALE } from '../constants';

const dateFormat = string => {
  var dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  return string.toLocaleDateString(LOCALE, dateOptions);
};

const getLocalIsoTime = () => {
  const timezoneOffsetInMilliseconds = new Date().getTimezoneOffset() * 60000;

  return new Date(Date.now() - timezoneOffsetInMilliseconds)
    .toISOString()
    .slice(0, -1);
};

export default dateFormat;
export { getLocalIsoTime };

import TagManager from 'react-gtm-module';

import { ENVIRONMENT, GOOGLE_TAG_MANAGER_ID } from '../constants';

const googleTagManagerClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_ID,
    });
  }
};

export default googleTagManagerClient;

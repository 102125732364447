export const formatProfile = (profile) => ({
  type: 'profile',
  alternativePhoneNumber: profile.alternativePhoneNumber,
  email: profile.email,
  image: profile.image && {
    url: `https:${profile.image.fields.file.url}`,
    title: profile.image.fields.title,
  },
  name: profile.name,
  phoneNumber: profile.phoneNumber,
  role: profile.role,
});

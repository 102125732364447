const FORMATS = ['.jpeg', '.jpg', '.png', '.gif'];

const imageFormat = string => {
  if (string) {
    for (let i = 0; i < FORMATS.length; i++) {
      if (string.includes(FORMATS[i])) {
        string = string.replace(FORMATS[i], `_500.jpeg`);
        break;
      }
    }
  } else {
    string = '';
  }
  return string;
};

export default imageFormat;

import React from 'react';
import styled from 'styled-components/macro';

import quoteIcon from '../../assets/icons/quote_icon.svg';

const UnstyledBlockquote = styled.blockquote`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  position: relative;
`;

const StyledBlockquote = styled(UnstyledBlockquote)`
  color: ${props => props.theme.color.grayDarker};
  font-family: ${props => props.theme.font.family.messina};
  font-size: ${props => props.theme.font.size['20px']};
  font-style: italic;
  padding: ${props => props.theme.space['48px']}
    ${props => props.theme.space['48px']};
  white-space: pre-wrap;

  ${props => props.theme.device.tablet`
    padding: ${props => props.theme.space['48px']}
      ${props => props.theme.space['96px']};
  `};
`;

const QuoteIcon = styled.img`
  left: 0px;
  position: absolute;
  top: 34px;
  width: 42px;

  ${props => props.theme.device.tablet`
    left: 35px;
    top: 28px;
    width: 52px;
  `};
`;

const Blockquote = ({ children }) => (
  <StyledBlockquote>
    <QuoteIcon alt="" src={quoteIcon} />
    {children}
  </StyledBlockquote>
);

export default Blockquote;

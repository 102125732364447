const norwegianTranslations = {
  aboutUs: 'Om oss',
  accessories: 'Tilbehør',
  articles: 'Artikler',
  blog: 'Artikler',
  blogPost: 'Artikkel',
  career: 'Karriere',
  contactUs: 'Kontakt oss',
  cookiePolicy: 'Informasjonskapsler',
  courses: 'Kurs',
  customerCenter: 'Kundesenter',
  customerPortal: 'Kundeportalen',
  customerService: 'FAQ',
  sustainability: 'Bærekraft',
  day: 'Dag',
  documents: 'Dokument',
  excludingVat: 'eks. moms',
  filterByYear: 'Filtrer på år',
  findCustomerCenter: 'Ta kontakt med ditt lokale kundesenter',
  forInvestors: 'For investorer',
  goToCustomerCenter: 'Gå til kundesenter',
  goToHome: 'Gå til startsiden',
  home: 'Hjem',
  loadMore: 'Last inn mer',
  marketing: 'Markedsføring',
  minimize: 'Minimere',
  necessary: 'Nødvendig',
  news: 'Presserom',
  newsPost: 'Nyhet',
  noResultsFound: 'Ingen resultat finnes',
  openInGoogleMaps: 'Åpne i Google Maps',
  pressroom: 'Nyheter',
  privacyPolicy: 'Personvern',
  productNumber: 'Produktnummer',
  products: 'Produkter',
  productType: 'Produkttype',
  published: 'Publisert',
  readMore: 'Les mer',
  references: 'Referanser',
  rent: 'Leie',
  rental: 'Utleie',
  reportAbuse: 'Rapporter misbruk',
  reservation: 'Forbehold',
  salesItem: 'Salg',
  salesItems: 'Salgsartikler',
  seeAll: 'Se alle',
  search: 'Søk',
  searchResults: 'Søkeresultat',
  searchResultsFor: 'Søkeresultat for',
  services: 'Tjenester',
  shareThis: 'Dele dette',
  statistics: 'Statistikk',
  technicalInformation: 'Teknisk informasjon',
  termsAndConditions: 'Vilkår',
  articlesBanner: {
    subTitle: 'Artikler, verktøy og inspirasjon til byggebransjen.',
    title: 'Artikler',
  },
  blogBanner: {
    subTitle: 'Artikler, verktøy og inspirasjon til byggebransjen.',
    title: 'Artikler',
  },
  contactForm: {
    close: 'Close',
    email: 'E-mail',
    name: 'Name',
    phone: 'Phone number',
    location: 'Location',
    send: 'Send',
    yourMessage: 'Your message',
    successTitle: 'Thank you for your message!',
    successMessage: 'We will get back to you as soon as we can.',
    errorTitle: 'Something went wrong',
    errorMessage: 'Try again or contact customer service directly.',
    rules: {
      fullName: 'Full name (First and last name)',
      phone: 'Only phone numbers',
      required: 'Required',
      least6: 'must be at least 6 characters',
      max50: 'can be a maximum of 50 characters',
      max500: 'can be a maximum of 500 characters',
    },
  },
  contactPersonsBanner: {
    subTitle: '',
    title: 'Kontaktpersoner',
  },
  customerCenterPage: {
    address: 'Adresse',
    openingHours: 'Åpningstider',
    specialOpeningHours: 'Avvikende åpningstider',
    closed: 'Stengt',
    weekDays: {
      mon: 'Mandag',
      tue: 'Tirsdag',
      wed: 'Onsdag',
      thu: 'Torsdag',
      fri: 'Fredag',
      sat: 'Lørdag',
      sun: 'Søndag',
    },
    description: 'Beskrivelse',
  },
  customerCentersBanner: {
    byDistance: 'Avstand',
    byName: 'A-Å',
    noBrowserSupport: 'Nettleseren din støtter ikke kartvisning!',
    searchPlaceholder: 'Søk på by, gate eller sted',
    subTitle: 'Her kan du leie og levere tilbake produktene',
    title: 'Kundesenter',
  },
  dataErrorBanner: {
    description:
      'Vennligst vent og prøv igjen. Hvis feilen vedvarer, kontakt kundeservice.',
    title: 'Feil ved innlesning av data.',
  },
  emptyCategoryPage: {
    description:
      'Det er beklageligvis ingen produkter i denne kategorien. Kanskje de er utleid?',
    goBackTo: 'Gå tilbake til',
    title: 'Tomt!',
  },
  faqBanner: {
    subTitle: 'Her har vi samlet svarene på de vanligste spørsmålene',
    title: 'Vanlige spørsmål',
  },
  footerHeaders: {
    apps: 'Apper',
    group: 'Konsernet',
    ourOffer: 'Vårt tilbud',
    ramirentInTheWorld: 'Ramirent i verden',
  },
  informationBanner: {
    customerCenterDescription: 'Våre medarbeidere kan alltid hjelpe deg',
    customerCenterTitle: 'Finn kundesenter',
    emailDescription: 'Vi svarer vanligvis innen 24 timer',
    phoneDescription: 'Ring oss, vi er her for å hjelpe deg',
    commonQuestionsDescription:
      'Her har vi samlet svarene på de vanligste spørsmålene',
    commonQuestionsTitle: 'Vanlige spørsmål og svar',
    newsletterDescription: 'Her kan du registrere deg for vårt nyhetsbrev',
    newsletterTitle: 'Registrer deg for nyhetsbrevet',
  },
  managementBanner: {
    subTitle: '',
    title: 'Ramirents ledergruppe',
  },
  newsBanner: {
    subTitle: 'Hold deg oppdatert om Ramirent',
    title: 'Presserom',
  },
  notFoundPage: {
    description:
      'Siden du leter etter er ikke tilgjengelig. Kanskje den er utleid? Finner du ikke det du leter etter: prøv igjen fra startsiden eller ta kontakt med oss, så skal vi hjelpe deg.',
    title: 'Ooops!',
  },
  popularProductsBanner: {
    subTitle: 'Et utvalg av våre mest leide produkter',
    title: 'Populære produkter',
  },
  referenceBanner: {
    subTitle: '',
    title: 'Referanser',
  },
  referencePage: {
    factsTableHeader: 'Fakta om prosjektet',
    moreReferences: 'Flere referanser',
    productsUsedInProject: 'Produkter brukt i prosjektet',
  },
  searchBar: {
    placeholder: 'Søk produkter, tjenester og kundesenter',
    seeAllResults: 'Se alle resultater',
  },
  usefulLinksBanner: {
    subTitle: 'Disse sidene besøker ofte andre kunder',
    title: 'Nyttige lenker',
  },
  quickLinksBanner: {
    subTitle: 'Disse sidene besøker ofte andre kunder',
    title: 'Hurtigkobling ',
  },
};

export default norwegianTranslations;

import dateFormat from '../../../utils/dateFunctions';
import { getBasePathFromType } from '../../../utils/link';

export const formatArticlePost = (articlePost) => ({
  type: 'articlePost',
  author: articlePost.author && {
    email: articlePost.author.fields.email,
    image: {
      url: `https:${articlePost.author.fields.image.fields.file.url}`,
      title: articlePost.author.fields.image.fields.title,
    },
    name: articlePost.author.fields.name,
    role: articlePost.author.fields.role,
  },
  copy: articlePost.copy,
  date: dateFormat(new Date(articlePost.date)),
  images:
    articlePost.images &&
    articlePost.images.map((image) => {
      return {
        url: `https:${image.fields.file.url}`,
        title: image.fields.title,
      };
    }),
  imageThumb:
    articlePost.images &&
    `https:${articlePost.images[0].fields.file.url}?fm=jpg&q=70&w=1140&fit=thumb`,
  primaryLink: articlePost.primaryLink,
  primaryLinkText: articlePost.primaryLinkText,
  slug: articlePost.slug,
  completeSlug: `${getBasePathFromType('articlePost')}${articlePost.slug}`,
  teaser: articlePost.teaser,
  title: articlePost.title,
});

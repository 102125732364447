import React from 'react';
import styled from 'styled-components/macro';

import RamiColumn from './RamiColumn';

const StyledRamiColumns = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.device.tablet`
    flex-direction: row;
  `};
`;
const isReactComponent = (comp) => !!comp && typeof comp !== 'string';

const RamiColumns = ({ children }) => (
  <StyledRamiColumns>
    {children.filter(isReactComponent).map((child, index) => (
      <RamiColumn
        key={index}
        number={children.length}
        isLastColumn={index + 1 === children.length}
        {...child.props}
      >
        {child.props.children}
      </RamiColumn>
    ))}
  </StyledRamiColumns>
);

export default RamiColumns;

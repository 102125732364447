import contentfulClient from '../../clients/contentfulClient';
import { getLocalIsoTime } from '../../utils/dateFunctions';
import logError from '../../utils/logger';
import { formatArticlePost } from './model';

const getArticlePosts = async (limit, skip, filter) => {
  let min, max;

  if (filter && filter.toString() !== 'all') {
    min = `${filter}-01-01`;
    // If filter is the current year do not display
    // blogs with publish time later than current time
    max = getLocalIsoTime().includes(filter)
      ? getLocalIsoTime()
      : `${filter}-12-31`;
  }

  try {
    const response = await contentfulClient.getEntries({
      content_type: 'articlePost',
      'fields.date[lte]': max ? max : getLocalIsoTime(),
      'fields.date[gte]': min,
      limit,
      skip,
      order: '-fields.date',
      select:
        'fields.author,fields.date,fields.images,fields.slug,fields.teaser,fields.title',
    });
    const articlePosts = response.items.map((articlePost) =>
      formatArticlePost(articlePost.fields)
    );
    return { articlePosts, total: response.total };
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getArticlePostBySlug = async (slug) => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'articlePost',
      'fields.slug': slug,
      limit: 1,
    });

    if (response.items[0]) {
      return formatArticlePost(response.items[0].fields);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export { getArticlePosts, getArticlePostBySlug };

import { ENVIRONMENT } from '../constants';

/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* Delta Projects pageView script */
const initDeltaClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    window._d7 = window._d7 || [];

    window._d7.push({
      action: 'pageView',
      pageId: 'YOUR_PAGE_ID' //PLEASE MAKE NO CHANGES TO PAGEID UNLESS INSTRUCTED OTHERWISE
    });

    (function() {
      var d = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      window._d7.id = '11442';
      window._d7.p =
        'https:' == document.location.protocol ? 'https://' : 'http://';
      d.src = window._d7.p + 'tb.de17a.com/d7.js';
      d.type = 'text/javascript';
      d.async = 1;
      s.parentNode.insertBefore(d, s);
    })();
  }
};

export default initDeltaClient;

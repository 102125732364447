import styled, { css } from 'styled-components/macro';

const UnstyledHeader2 = styled.h2`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const header2Styles = css`
  color: ${props => {
    if (props.blue) return props.theme.color.blue;
    if (props.white) return props.theme.color.white;
    return props.theme.color.grayDarker;
  }};
  font-family: ${props => props.theme.font.family.boing};
  font-size: ${props => props.theme.font.size['20px']};
  font-weight: ${props => props.theme.font.weight.semiBold};
  padding-bottom: ${props => (props.inline ? 0 : '16px')};

  ${props => props.theme.device.tablet`
    font-size: ${props => props.theme.font.size['40px']};
    padding-bottom: ${props => (props.inline ? 0 : '20px')};
  `}
`;

const Header2 = styled(UnstyledHeader2)`
  ${header2Styles}
`;

export default Header2;
export { header2Styles };

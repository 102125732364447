import contentfulClient from '../../clients/contentfulClient';
import { getLocalIsoTime } from '../../utils/dateFunctions';
import logError from '../../utils/logger';
import { formatNewsPost } from './model';

const getNewsPosts = async (limit, skip, filter) => {
  let min = undefined;
  let max = undefined;

  if (filter && filter.toString() !== 'all') {
    min = `${filter}-01-01`;
    // If filter is the current year do not display
    // news with publish time later than current time
    max = getLocalIsoTime().includes(filter)
      ? getLocalIsoTime()
      : `${filter}-12-31`;
  }

  try {
    const response = await contentfulClient.getEntries({
      content_type: 'newsPost',
      'fields.date[lte]': max ? max : getLocalIsoTime(),
      'fields.date[gte]': min,
      limit,
      skip,
      order: '-fields.date',
      select:
        'fields.author,fields.date,fields.images,fields.slug,fields.teaser,fields.title',
    });
    const newsPosts = response.items.map((newsPost) =>
      formatNewsPost(newsPost.fields)
    );
    return { newsPosts, total: response.total };
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getNewsPostBySlug = async (slug) => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'newsPost',
      'fields.slug': slug,
      limit: 1,
    });

    if (response.items[0]) {
      return formatNewsPost(response.items[0].fields);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export { getNewsPosts, getNewsPostBySlug };

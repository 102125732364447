import React from 'react';

import {
  GOOGLE_ANALYTICS_CATEGORY_BUTTON,
  GOOGLE_ANALYTICS_LABEL_PAGE,
} from '../../../../constants';
import { useListState } from '../../../hooks/useListState';
import { getLatestSubpages } from '../../../../api/contentful/getLatestSubpages';
import {
  SET_PAGES,
  SET_ERROR,
  SET_LOAD_MORE,
} from '../../../contextProviders/PageProvider';
import { GoogleAnalyticsEvent } from '../../../components/GoogleAnalytics';
import RamiLoadMoreButton from '../components/links/RamiLoadMoreButton';

const DEFAULT_NUMBER = 12;

const RamiLoadMoreButtonContainer = ({ children, id, number }) => {
  const { state, dispatch, locale } = useListState(id);
  const nrToLoad = isNaN(Number(number)) ? DEFAULT_NUMBER : Number(number);

  const onLoadMore = async () => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_BUTTON,
      'Clicked Load More Button',
      GOOGLE_ANALYTICS_LABEL_PAGE(state.data.parentSlug)
    );

    try {
      dispatch({ type: SET_LOAD_MORE });

      const response = await getLatestSubpages(
        locale,
        state.data.parentSlug,
        nrToLoad,
        state.pages.length,
        state.selectedFilterOption && state.selectedFilterOption.value
      );

      dispatch({
        type: SET_PAGES,
        payload: {
          pages: response.pages,
          totalPages: response.totalPages,
        },
      });
    } catch (error) {
      dispatch({ type: SET_ERROR });
    }
  };

  if (state.error) {
    return null;
  }

  return (
    <RamiLoadMoreButton
      disabled={
        state.loadMore || !state.pages || state.pages.length >= state.totalPages
      }
      onClick={onLoadMore}
    >
      {children}
    </RamiLoadMoreButton>
  );
};

export default RamiLoadMoreButtonContainer;

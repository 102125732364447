// PLEASE NOTE THAT SEO WILL BE CRITICALLY AFFECTED IF A ROUTE IS MODIFIED
const swedishRoutePaths = {
  aboutPath: 'om oss',
  articlesPath: 'artiklar',
  blogPath: 'blogg',
  coursesPath: 'utbildningar',
  customerCentersPath: 'kundcenter',
  customerServicePath: 'kundtjänst',
  sustainabilityPath: 'hållbarhet',
  newsPath: 'nyheter',
  pressroomPath: 'pressrum',
  referencesPath: 'referenser',
  rentPath: 'hyrmaskiner',
  rentPathProduct: 'produkt',
  searchPath: 'sök',
  servicesPath: 'tjänster',
  privacyPolicyPath: 'integritetspolicy',
  cookiePolicyPath: 'cookies',
  termsAndConditionsPath: 'anvandarvillkor',
};

export default swedishRoutePaths;

import { COUNTRY } from '../../../constants';

const featureToggles = {
  EN: {
    articles: false,
    blog: true,
    popularProducts: true,
    productCTA: false,
    ramirentInShort: false,
    references: true,
    navbarContactForm: false,
  },
  FI: {
    articles: true,
    blog: true,
    popularProducts: true,
    productCTA: true,
    ramirentInShort: true,
    references: true,
    navbarContactForm: false,
  },
  NO: {
    articles: false,
    blog: true,
    popularProducts: true,
    productCTA: true,
    ramirentInShort: false,
    references: true,
    navbarContactForm: false,
  },
  SE: {
    articles: false,
    blog: true,
    popularProducts: true,
    productCTA: true,
    ramirentInShort: false,
    references: true,
    navbarContactForm: true,
  },
};

const FEATURE_TOGGLES = featureToggles[COUNTRY];

export default FEATURE_TOGGLES;

import styled from 'styled-components/macro';

const UnstyledParagraph = styled.p`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const Paragraph = styled(UnstyledParagraph)`
  color: ${props => {
    if (props.blue) return props.theme.color.blue;
    if (props.supporting) return props.theme.color.grayDark;
    if (props.white) return props.theme.color.white;
    return props.theme.color.grayDarker;
  }};
  font-family: ${props => props.theme.font.family.messina};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size['16px']};
  padding-bottom: ${props =>
    props.inline ? 0 : props.theme.font.size['16px']};
  white-space: pre-wrap;
`;

export default Paragraph;

import styled from 'styled-components/macro';

const UnstyledTable = styled.table`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const Table = styled(UnstyledTable)`
  background-color: ${props => props.theme.color.white};
  border-collapse: collapse;
  border-radius: ${props => props.theme.borderRadius.medium};
  box-shadow: ${props => props.theme.shadow.lighter};
  color: ${props => props.theme.color.grayDarker};
  display: block;
  font-family: ${props => props.theme.font.family.messina};
  font-weight: ${props => props.theme.font.weight.regular};
  margin: ${props => props.theme.space['24px']}
    ${props => props.theme.space['8px']} ${props => props.theme.space['42px']};
  overflow-x: scroll;
  padding: ${props => props.theme.space['4px']}
    ${props => props.theme.space['20px']} ${props => props.theme.space['16px']};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 14px;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${props => props.theme.color.orange};
    border: ${props => props.theme.border['4px']} rgba(0, 0, 0, 0);
    border-radius: ${props => props.theme.borderRadius.large};
    min-width: 50px;
  }

  br {
    line-height: 200%;
  }

  td {
    min-width: 292px;
    padding: ${props => props.theme.space['24px']}
      ${props => props.theme.space['8px']};
  }

  th {
    padding: ${props => props.theme.space['24px']}
      ${props => props.theme.space['8px']};
  }

  thead {
    border-bottom: ${props => props.theme.border['1px']} rgba(0, 0, 0, 0.1);
    text-align: left;
  }

  tr {
    border-bottom: ${props => props.theme.border['1px']} lightgray;
    vertical-align: text-top;
  }

  tr:last-of-type {
    border-bottom: none;
  }

  ${props => props.theme.device.desktop`
    margin: ${props => props.theme.space['24px']} 0 ${props =>
    props.theme.space['42px']};
  `};
`;

export default Table;

import styled from 'styled-components/macro';

const UnstyledUnorderedList = styled.ul`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const UnorderedList = styled(UnstyledUnorderedList)`
  color: ${props => props.theme.color.grayDarker};
  font-family: ${props => props.theme.font.family.messina};
  font-size: ${props => props.theme.font.size['16px']};
  font-weight: ${props => props.theme.font.weight.regular};
  padding-bottom: ${props => props.theme.font.size['16px']};
  white-space: pre-wrap;
`;

export default UnorderedList;

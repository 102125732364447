import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const navigationMenuLinkStyles = css`
  color: ${(props) => props.theme.color.blue};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.orange} !important;
  }
  &:visited {
    color: ${(props) => props.theme.color.blue};
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    background: ${(props) => props.theme.color.orange};
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
`;

const StyledNavigationMenuLink = styled(NavLink)`
  ${navigationMenuLinkStyles};
`;

const NavigationMenuLink = ({ children, to, exact, ...rest }) => (
  <StyledNavigationMenuLink to={to} exact={(!!exact).toString()} {...rest}>
    {children}
  </StyledNavigationMenuLink>
);

NavigationMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default NavigationMenuLink;

import { ApolloClient, InMemoryCache } from '@apollo/client';

import { GRAPHQL_API_URL } from '../constants';

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: GRAPHQL_API_URL,
});

export default apolloClient;

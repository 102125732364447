import React from 'react';
import styled from 'styled-components/macro';

const StyledRamiContentContainer = styled.div`
  background-color: white;
  margin-top: 1%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px lightgrey;
`;
const RamiContentContainer = ({ children }) => (
  <StyledRamiContentContainer>{children}</StyledRamiContentContainer>
);

export default RamiContentContainer;

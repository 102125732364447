import React from 'react';
import styled from 'styled-components/macro';
import Header4 from '../../../components/Header4';
import Button from '../../../components/Button';
import translations from '../../../../translations';

export const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.space['16px']};
`;

export const FormMessage = ({ title, children, buttonAction }) => (
  <>
    <Header4>{title}</Header4>
    {children}
    <SendButtonWrapper>
      <Button name="close" onClick={buttonAction}>
        {translations.contactForm.close}
      </Button>
    </SendButtonWrapper>
  </>
);

export default FormMessage;

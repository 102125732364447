import React from 'react';
import styled from 'styled-components/macro';

import Skeleton from '../../../../components/Skeleton';

const DropDownContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.space['24px']};
  ${(props) => props.theme.device.tablet`
    margin-bottom: ${(props) => props.theme.space['24px']};
    ${'' /* correct margin top from page header */}
    margin-top: -6px;
  `}
`;

const DropDown = styled(Skeleton)`
  height: 50px;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  ${(props) => props.theme.device.tablet`
    height: 56px;
    margin-right: ${(props) => props.theme.space['24px']};
    max-width: 260px;
    width: 300px;
  `}
`;

const RamiDropdownFilterSkeleton = () => (
  <DropDownContainer>
    <DropDown />
  </DropDownContainer>
);

export default RamiDropdownFilterSkeleton;

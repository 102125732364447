import React from 'react';
import styled from 'styled-components/macro';

import Skeleton from './Skeleton';

const Card = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius.small};
  display: flex;
  flex: none;
  flex-direction: column;
  height: 240px;
  margin: ${props => props.theme.space['4px']} 0;
  padding: ${props => props.theme.space['16px']};
  text-decoration: none;
  width: 100%;

  ${props => props.theme.device.tablet`
    margin: 0.66%;
    width: 32%;
  `}

  ${props => props.theme.device.desktop`
    border-radius: ${props => props.theme.borderRadius.small};
    height: 330px;
    margin: 0.5%;
    padding: ${props => props.theme.space['24px']} 
        ${props => props.theme.space['16px']};
    padding-bottom: ${props => props.theme.space['4px']};
    width: 24%;
  `}
`;

const Image = styled(Skeleton)`
  height: 100px;
  width: 100px;

  ${props => props.theme.device.desktop`
    height: 150px;
    width: 150px;
  `}
`;

const Title = styled(Skeleton)`
  margin: ${props => props.theme.space['24px']} 0;
  max-width: 250px;
  width: 100%;

  ${props => props.theme.device.desktop`
    margin: ${props => props.theme.space['32px']} 0;
  `}
`;

const Specifications = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 200px;
`;

const Specification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: ${props => props.theme.space['8px']};
`;

const StyledSpecification = styled(Skeleton)`
  width: 70px;
`;

const Divider = styled.div`
  background-color: ${props => props.theme.color.grayLighter};
  flex: none;
  height: 40px;
  margin: 0 ${props => props.theme.space['12px']};
  width: 4px;
`;

const SkeletonProductCard = () => (
  <Card>
    <Image />
    <Title type={'header3'} />
    <Specifications>
      <Specification>
        <StyledSpecification type={'paragraph'} />
        <StyledSpecification type={'paragraph'} />
      </Specification>
      <Divider />
      <Specification>
        <StyledSpecification type={'paragraph'} />
        <StyledSpecification type={'paragraph'} />
      </Specification>
    </Specifications>
  </Card>
);

export default SkeletonProductCard;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import FlexGrid from './FlexGrid';
import SkeletonPageHeader from './SkeletonPageHeader';
import SkeletonProductCard from './SkeletonProductCard';

const StyledSkeletonProductsGrid = styled.div`
  & div {
    :nth-of-type(n + 4) {
      display: none;
    }

    ${props => props.theme.device.tablet`
      :nth-of-type(n + 6) {
        display: flex;
      }
    `};

    ${props => props.theme.device.desktop`
      :nth-of-type(n) {
        display: flex;
      }
    `};
  }
`;

const StyledFlexGrid = styled(FlexGrid)`
  justify-content: flex-end;
`;

const SkeletonProductsGrid = ({ hasPageHeader }) => {
  const Cards = () => {
    const objects = [];
    for (let i = 0; i < 8; i++) {
      objects.push(<SkeletonProductCard key={i} />);
    }
    return objects;
  };

  return (
    <>
      {hasPageHeader && <SkeletonPageHeader />}
      <StyledSkeletonProductsGrid>
        <StyledFlexGrid onlyTabletAndDesktop>
          <Cards />
        </StyledFlexGrid>
      </StyledSkeletonProductsGrid>
    </>
  );
};

SkeletonProductsGrid.propTypes = {
  hasPageHeader: PropTypes.bool
};

export default SkeletonProductsGrid;

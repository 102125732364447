import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BoldText from '../../components/BoldText';
import Link from '../../components/Link';
import Paragraph from '../../components/Paragraph';
import Specifications from './components/Specifications';
import imageFormat from '../../../utils/imageFormat';

const StyledProductCard = styled(Link)`
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  border-radius: ${(props) => props.theme.borderRadius.small};
  box-shadow: ${(props) => props.theme.shadow.light};
  display: flex;
  flex: none;
  flex-direction: column;
  height: 240px;
  margin: ${(props) => props.theme.space['4px']} 0;
  padding: ${(props) => props.theme.space['16px']};
  text-decoration: none;
  transition: box-shadow 0.2s ease;
  width: 100%;

  :hover {
    box-shadow: ${(props) => props.theme.shadow.bold};
    color: ${(props) => props.theme.color.blue};
  }

  ${(props) => props.theme.device.tablet`
    margin: 0.66%;
    width: 32%;
  `}

  ${(props) => props.theme.device.desktop`
    border-radius: ${(props) => props.theme.borderRadius.small};
    height: 330px;
    margin: 0.5%;
    padding: ${(props) => props.theme.space['24px']} ${(props) =>
    props.theme.space['16px']};
    width: 24%;
  `}
`;

const Header = styled(BoldText)`
  align-self: flex-start;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 110px;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.space['8px']};
  width: 110px;

  img {
    max-height: 110px;
    max-width: 110px;
  }

  ${(props) => props.theme.device.desktop`
    height: 160px;
    margin-bottom: ${(props) => props.theme.space['24px']};
    width: 160px;

    img {
      max-height: 160px;
      max-width: 160px;
    }
  `}
`;

const ProductCard = ({
  className,
  image,
  link,
  make,
  model,
  specifications,
  title,
  shouldHideMakeModel,
  shouldShowRamiGreenIcon,
}) => (
  <StyledProductCard className={className} url={link} data-cy="productCard">
    <ImageWrapper>
      <img src={imageFormat(image)} alt={title} />
    </ImageWrapper>
    <Header inline data-cy="productHeader">
      {title}
    </Header>
    {(make || model) && !shouldHideMakeModel && (
      <Paragraph inline>
        {make} {model}
      </Paragraph>
    )}
    {specifications && specifications.length > 0 && (
      <Specifications
        specifications={specifications}
        shouldShowRamiGreenIcon={shouldShowRamiGreenIcon}
      />
    )}
  </StyledProductCard>
);

ProductCard.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  make: PropTypes.string,
  model: PropTypes.string,
  specifications: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default ProductCard;

import { isMediaVideo } from '../../../utils/mediaFunctions';

export const BannerStyle = {
  DEFAULT: 'Default',
  FULL_WIDTH: 'Full width',
  FULL_WIDTH_BLUE: 'Full width blue',
  IMAGE_GALLERY: 'Image gallery',
  IMAGE_GALLERY_LARGE: 'Image gallery large',
  PROMOTION: 'Promotion',
};

export const formatBanner = (banner) => {
  const mediaImages = [];
  let mediaVideo = null;
  if (banner.mediaReferences && banner.mediaReferences.length) {
    banner.mediaReferences.forEach((media, i) => {
      const url = `https:${media.fields.file.url}`;
      const title = media.fields.title;
      const isVideo = isMediaVideo(url);
      if (isVideo && mediaVideo === null) {
        mediaVideo = { url, title };
      } else {
        mediaImages.push({ url, title });
      }
    });
  }
  return {
    type: 'banner',
    copy: banner.copy,
    mediaImages: mediaImages,
    mediaAutoplay: banner.mediaAutoplay,
    mediaLoop: banner.mediaLoop,
    mediaVideo: mediaVideo,
    video: banner.videoLink,
    style: banner.style ? banner.style : BannerStyle.DEFAULT,
  };
};

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { locales } from '../../constants';
import translations from '../../translations';
import englishTranslations from '../../translations/en';
import swedishTranslations from '../../translations/se';

import FEATURE_TOGGLES from '../views/featureToggle/featureToggles';

/**
 * @returns {Object} { locale, translations }
 */
export const useLocale = () => {
  const queryParams = useLocation().search;
  const locale =
    FEATURE_TOGGLES.ramirentInShort && queryString.parse(queryParams).lang;

  const getTranslations = (locale) => {
    if (locale === locales.EN) {
      return englishTranslations;
    } else if (locale === locales.SE) {
      return swedishTranslations;
    } else {
      return translations;
    }
  };

  return {
    locale,
    translations: getTranslations(locale),
  };
};

import React from 'react';
import styled from 'styled-components/macro';

import CustomerCentersMap from '../../customerCentersMap/CustomerCentersMap';

const BannerMap = styled(CustomerCentersMap)`
  height: 490px;
  max-width: 460px;
  margin: 0 auto;

  ${(props) => props.theme.device.tablet`
    height: 450px;
    max-width: 100%;
  `}
`;

const PageMap = styled(CustomerCentersMap)`
  height: 70vh;
`;

const RamiCustomerCentersMap = ({ banner, active }) => {
  const mapProps = active
    ? {
        activeMap: true,
        initialZoom: 3.5,
      }
    : {
        activeMap: false,
      };
  return banner ? <BannerMap {...mapProps} /> : <PageMap {...mapProps} />;
};

export default RamiCustomerCentersMap;

import React from 'react';
import messageIcon from '../../../assets/icons/message_icon_blue.svg';
import styled from 'styled-components/macro';
import translations from '../../../translations';

const StyledNavContactForm = styled.div`
  position: absolute;
  right: ${(props) => props.theme.space['16px']};
  top: ${(props) => props.theme.space['24px']};
  ${(props) => props.theme.device.desktop`
    top: ${(props) => props.theme.space['8px']};
    position: relative;
    right: unset;
  `}
  height: ${(props) => props.theme.icon.size['32px']};
  width: ${(props) => props.theme.icon.size['32px']};
  &:hover {
    cursor: pointer;
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    background: ${(props) => props.theme.color.orange};
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
  }
`;
const Icon = styled.img`
  max-height: ${(props) => props.theme.icon.size['32px']};
  max-width: ${(props) => props.theme.icon.size['32px']};
`;

const NavContactFormButton = ({ openContactForm }) => (
  <StyledNavContactForm
    data-cy="navContactFormButton"
    title={translations.contactUs}
    onClick={() => openContactForm()}
  >
    <Icon alt="" src={messageIcon} />
  </StyledNavContactForm>
);

export default NavContactFormButton;

import routeTranslations from './routeTranslations';
import slugify from './utils/slug';

const socialMediaLinks = {
  EN: {
    facebook: 'https://www.facebook.com/ramirentcorporate',
    instagram: 'https://www.instagram.com/ramirent_group/',
    linkedin: 'https://www.linkedin.com/company/ramirent-group',
    twitter: 'https://twitter.com/ramirent',
    youtube: 'https://www.youtube.com/user/RamirentGroup',
  },
  FI: {
    facebook: 'https://www.facebook.com/ramirent.finland',
    instagram: 'https://www.instagram.com/ramirent_finland/',
    linkedin: 'https://www.linkedin.com/company/ramirent-finland-oy/',
    twitter: 'https://twitter.com/ramirent',
    youtube: 'https://www.youtube.com/channel/UCnPaLDKDO3P_Q7Gu8r1JPfQ',
  },
  NO: {
    facebook: 'https://www.facebook.com/ramirentnorge',
    instagram: 'https://www.instagram.com/ramirent_norge/',
    linkedin: 'https://www.linkedin.com/company/ramirent-norge',
    twitter: 'https://twitter.com/ramirent',
    youtube: 'https://www.youtube.com/user/ramirentnorge',
  },
  SE: {
    facebook: 'https://www.facebook.com/ramirentsweden',
    instagram: 'https://www.instagram.com/ramirent_sweden/?hl=sv',
    linkedin: 'https://www.linkedin.com/company/ramirent-ab/',
    twitter: 'https://twitter.com/ramirent',
    youtube: 'https://www.youtube.com/user/RamirentSweden',
  },
};

const contactInformation = {
  EN: {
    city: 'Helsinki',
    country: 'Finland',
    email: 'asiakaspalvelu@ramirent.fi',
    phone: '+358 20 750 200',
    streetAddress: 'Tapulikaupungintie 37',
    zipCode: '00750',
  },
  FI: {
    city: 'Helsinki',
    country: 'Suomi',
    email: 'asiakaspalvelu@ramirent.fi',
    phone: '0800 171 414',
    phoneLabel: 'Asiakaspalvelu',
    phoneOpeningHour: 'Avoinna arkisin klo 6:30-17:00',
    streetAddress: 'Tapulikaupungintie 37',
    zipCode: '00750',
  },
  NO: {
    city: 'Skjetten',
    country: 'Norge',
    email: 'kundeservice@ramirent.no',
    phone: '(+47) 987 09890',
    streetAddress: 'Hvamsvingen 20',
    zipCode: '2013',
  },
  SE: {
    city: 'Kungsängen',
    country: 'Sverige',
    email: 'kundservice@ramirent.se',
    phone: '031-57 84 00',
    streetAddress: 'Frekvensvägen 2',
    zipCode: '196 92',
  },
};

const hiddenCenterIds = {
  EN: [],
  NO: [],
  FI: ['72'],
  SE: [],
};

export const locales = {
  EN: 'en-US',
  FI: 'fi-FI',
  NO: 'nb-NO',
  SE: 'sv-SE',
};

export const COUNTRY = process.env.REACT_APP_COUNTRY_DOMAIN;
export const CONTACT_INFORMATION = contactInformation[COUNTRY];
export const HIDDEN_CENTER_IDS = hiddenCenterIds[COUNTRY];
export const CONTENTFUL_ACCESS_TOKEN =
  process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
export const CONTENTFUL_ENVIRONMENT =
  process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
export const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const GOOGLE_TAG_MANAGER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
export const LOCALE = locales[COUNTRY];
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SPLIT_ACCESS_KEY = process.env.REACT_APP_SPLIT_ACCESS_KEY;
export const COOKIEBOT_DOMAIN_GROUP_ID =
  process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID;

// PLEASE NOTE THAT SEO WILL BE CRITICALLY AFFECTED IF A ROUTE IS MODIFIED
export const ABOUT_PATH = `/${slugify(routeTranslations.aboutPath)}`;
export const ARTICLES_PATH = `/${slugify(routeTranslations.articlesPath)}`;
export const BLOG_PATH = `/${slugify(routeTranslations.blogPath)}`;
export const COURSES_PATH = `/${slugify(routeTranslations.coursesPath)}`;
export const CUSTOMER_CENTERS_PATH = `/${slugify(
  routeTranslations.customerCentersPath
)}`;
export const CUSTOMER_SERVICE_PATH = `/${slugify(
  routeTranslations.customerServicePath
)}`;
export const SUSTAINABILITY_PATH = `/${slugify(
  routeTranslations.sustainabilityPath
)}`;
export const HOME_PATH = '/';
export const NEWS_PATH = `/${slugify(routeTranslations.newsPath)}`;
export const PRESSROOM_PATH = `/${slugify(routeTranslations.pressroomPath)}`;
export const REFERENCES_PATH = `/${slugify(routeTranslations.referencesPath)}`;
export const RENT_PATH = `/${slugify(routeTranslations.rentPath)}`;
export const SEARCH_PATH = `/${slugify(routeTranslations.searchPath)}`;
export const SERVICES_PATH = `/${slugify(routeTranslations.servicesPath)}`;

export const COMPANY_NAME = 'Ramirent';
export const GOOGLE_ANALYTICS_CATEGORY_BUTTON = 'Button';
export const GOOGLE_ANALYTICS_CATEGORY_DROPDOWN = 'Dropdown';
export const GOOGLE_ANALYTICS_CATEGORY_ICON = 'Icon';
export const GOOGLE_ANALYTICS_CATEGORY_INPUT = 'Input';
export const GOOGLE_ANALYTICS_CATEGORY_LINK = 'Link';
export const GOOGLE_ANALYTICS_CATEGORY_LIST = 'List';
export const GOOGLE_ANALYTICS_LABEL_PAGE = (slug) => `Page: ${slug}`;
export const GOOGLE_ANALYTICS_LABEL_RAMI_TAG = (ramiTag) =>
  `Rami-tag: ${ramiTag}`;
export const GOOGLE_ANALYTICS_LABEL_ARTICLES_GRID = 'Articles Grid';
export const GOOGLE_ANALYTICS_LABEL_BLOG_GRID = 'Blog Grid';
export const GOOGLE_ANALYTICS_LABEL_BREADCRUMBS = 'Breadcrumbs';
export const GOOGLE_ANALYTICS_LABEL_CONTACT_BAR = 'Contact Bar';
export const GOOGLE_ANALYTICS_LABEL_COOKIE_BANNER = 'Cookie Banner';
export const GOOGLE_ANALYTICS_LABEL_CUSTOMER_CENTERS_MAP =
  'Customer Centers Map';
export const GOOGLE_ANALYTICS_LABEL_ENGLISH_FLAG = 'English Flag';
export const GOOGLE_ANALYTICS_LABEL_SWEDISH_FLAG = 'Swedish Flag';
export const GOOGLE_ANALYTICS_LABEL_ENGLISH_FLAG_SMALL_IMAGE_CARD =
  'English Flag - Product/Service Card';
export const GOOGLE_ANALYTICS_LABEL_FAQ = 'FAQ';
export const GOOGLE_ANALYTICS_LABEL_FOOTER = 'Footer';
export const GOOGLE_ANALYTICS_LABEL_NEWS_GRID = 'News Grid';
export const GOOGLE_ANALYTICS_LABEL_PRODUCT = 'Product';
export const GOOGLE_ANALYTICS_LABEL_PROMOTION_BANNER = 'Promotion Banner';
export const GOOGLE_ANALYTICS_LABEL_REFERENCES_GRID = 'References Grid';
export const GOOGLE_ANALYTICS_LABEL_SEARCH_BAR = 'Search Bar';

export const GLOBAL_WEB_SITE = 'https://www.ramirent.com';
export const GLOBAL_WEB_SITE_ABOUT = 'https://www.ramirent.com/about';
export const GLOBAL_WEB_SITE_CAREERS = 'https://www.ramirent.com/careers';
export const GLOBAL_WEB_SITE_INVESTORS = 'https://www.ramirent.com/investors';
export const GLOBAL_WEB_SITE_NEWS = 'https://www.ramirent.com/news';

export const REGIONAL_WEB_SITE_CZECHREPUBLIC = 'http://www.ramirent.cz';
export const REGIONAL_WEB_SITE_ESTONIA = 'https://www.ramirent.ee';
export const REGIONAL_WEB_SITE_FINLAND = 'https://www.ramirent.fi';
export const REGIONAL_WEB_SITE_LATVIA = 'https://www.ramirent.lv';
export const REGIONAL_WEB_SITE_LITHUANIA = 'https://www.ramirent.lt';
export const REGIONAL_WEB_SITE_NORWAY = 'https://www.ramirent.no';
export const REGIONAL_WEB_SITE_POLAND = 'https://www.ramirent.pl';
export const REGIONAL_WEB_SITE_SLOVAKIA = 'http://www.ramirent.sk';
export const REGIONAL_WEB_SITE_SWEDEN = 'https://www.ramirent.se';

export const EXTERNAL_WEB_SITE_FACEBOOK = socialMediaLinks[COUNTRY].facebook;
export const EXTERNAL_WEB_SITE_INSTAGRAM = socialMediaLinks[COUNTRY].instagram;
export const EXTERNAL_WEB_SITE_LINKEDIN = socialMediaLinks[COUNTRY].linkedin;
export const EXTERNAL_WEB_SITE_TWITTER = socialMediaLinks[COUNTRY].twitter;
export const EXTERNAL_WEB_SITE_YOUTUBE = socialMediaLinks[COUNTRY].youtube;
export const EXTERNAL_WEB_SITE_HR_MANGER = 'https://jobb.ramirent.se';

export const EXTERNAL_WEB_SITE_SE_COURSES = 'https://utbildning.ramirent.se';
export const EXTERNAL_WEB_SITE_SE_RAMICHECK = 'https://ramicheck.ramirent.se';

const fbVerificationCode = {
  SE: 'y00wgcfmml20n56d1jhk4rgqdj95qo',
  NO: 'vafk5juv0tkhfjrkkthwnavuhxn1aw',
};
export const FACEBOOK_DOMAIN_VERIFICATION_CODE = fbVerificationCode[COUNTRY];

export const NEWSLETTER_LINK = 'https://nyhetsbrev.ramirent.se';

import styled, { css } from 'styled-components/macro';

const FlexGrid = styled.div`
  ${props => {
    if (props.onlyDesktop) {
      return css`
        ${props => props.theme.device.desktop`
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
            `}
      `;
    }

    if (props.onlyTabletAndDesktop) {
      return css`
        ${props => props.theme.device.tablet`
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
            `}
      `;
    }

    return css`
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    `;
  }}
`;

export default FlexGrid;

import contentfulClient from '../../clients/contentfulClient';
import logError from '../../utils/logger';
import { formatContactBarCollection } from './model/ContactBar';
import { formatContactBarCollectionFI } from './model/ContactBarFI';
import { COUNTRY } from '../../constants';

const getContactBar = async (language) => {
  try {
    const response = await contentfulClient.getEntries({
      include: 10,
      content_type: 'contactBar',
      ...(COUNTRY === 'FI' && { locale: '*' }),
    });
    if (response.items) {
      return COUNTRY === 'FI'
        ? formatContactBarCollectionFI(response.items[0].fields, language)
        : formatContactBarCollection(response.items[0].fields);
    } else {
      return [];
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export default getContactBar;

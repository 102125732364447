import {
  COUNTRY,
  ARTICLES_PATH,
  BLOG_PATH,
  NEWS_PATH,
  REFERENCES_PATH,
} from '../constants';

export const getLink = (link, locale) =>
  locale &&
  (link.startsWith('/') || link.startsWith(`https://www.ramirent.${COUNTRY}`))
    ? `${link}?lang=${locale}`
    : link;

export const formatUrl = (url) => {
  return url.startsWith('https://') ? url : `https://${url}`;
};

export const getBasePathFromType = (type) => {
  let basePath;
  switch (type) {
    case 'articlePost':
      basePath = ARTICLES_PATH;
      break;
    case 'blogPost':
      basePath = BLOG_PATH;
      break;
    case 'newsPost':
      basePath = NEWS_PATH;
      break;
    case 'referenceEntity':
      basePath = REFERENCES_PATH;
      break;
    default:
      basePath = '';
  }
  return basePath ? `${basePath}/` : basePath;
};

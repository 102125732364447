import contentfulClient from '../../clients/contentfulClient';
import logError from '../../utils/logger';
import { formatSideBarCollection } from './model/SideBar';

const getSideBar = async () => {
  try {
    const response = await contentfulClient.getEntries({
      include: 10,
      content_type: 'sideBar',
    });
    if (response.items) {
      return response.items.map((option) =>
        formatSideBarCollection(option.fields)
      )[0];
    } else {
      return [];
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export default getSideBar;

import styled, { css } from 'styled-components/macro';

import searchIcon from '../../assets/icons/search_icon_gray.svg';

const Input = styled.input`
  background-color: ${props => props.theme.color.white};
  border: 0;
  border-bottom: ${props => props.theme.border['1px']}
    ${props => props.theme.color.grayLight};
  border-radius: 0;
  color: ${props => props.theme.color.grayDarker};
  font: inherit;
  margin: 0;
  padding: ${props => props.theme.space['16px']};

  ::placeholder {
    color: ${props => props.theme.color.gray};
  }

  ${props =>
    props.search &&
    css`
      background-image: url(${searchIcon});
      background-repeat: no-repeat;
      background-origin: content-box;
      background-position: right -20px center;
      background-size: ${props => props.theme.icon.size['16px']};
      padding-left: ${props => props.theme.space['12px']};
      padding-right: ${props => props.theme.space['32px']};
      text-overflow: ellipsis;
    `}
`;

export default Input;

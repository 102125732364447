// PLEASE NOTE THAT SEO WILL BE CRITICALLY AFFECTED IF A ROUTE IS MODIFIED
const englishRoutePaths = {
  aboutPath: 'about us',
  articlesPath: 'articles',
  blogPath: 'blog',
  coursesPath: 'courses',
  customerCentersPath: 'customer centers',
  customerServicePath: 'customer service',
  sustainabilityPath: 'sustainability',
  newsPath: 'news',
  pressroomPath: 'pressroom',
  referencesPath: 'references',
  rentPath: 'rental machines',
  rentPathProduct: 'produkt',
  searchPath: 'search',
  servicesPath: 'services',
};

export default englishRoutePaths;

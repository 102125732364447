import { ENVIRONMENT } from '../constants';

/* Ad inZynk tracking */
const initInZynkClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    (function () {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://tags.inzynk.io/gefuhdhl/iztag.js';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  }
};

export default initInZynkClient;

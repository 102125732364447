const formatFaqPost = (faqPost) => ({
  copy: faqPost.copy,
  title: faqPost.title,
});

export const formatFaqPostCollection = (faqPostCollection) => ({
  type: 'faqPosts',
  faqPosts: faqPostCollection.faqPosts.map((faqPost) =>
    formatFaqPost(faqPost.fields)
  ),
  title: faqPostCollection.title,
});

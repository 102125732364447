import { ENVIRONMENT } from '../constants';

const initHubspotClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    (function(d) {
      var n = 'script';
      var e = d.createElement(n);
      e.id = 'hs-script-loader';
      e.async = 1;
      e.defer = 1;
      e.src =
        ('https:' === document.location.protocol ? 'https' : 'http') +
        '://js.hs-scripts.com/4367546.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    })(document);
  }
};

export default initHubspotClient;

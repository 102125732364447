import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Header2 from './Header2';
import Header4 from './Header4';
import Line from './Line';

const StyledBannerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space['16px']};
  text-align: center;

  ${(props) => props.theme.device.desktop`
    text-align: left;
  `}
`;

const CenteredLine = styled(Line)`
  margin-left: auto;
  margin-right: auto;

  ${(props) => props.theme.device.desktop`
    margin-left: 0;
  `}
`;

const BannerHeader = ({ subTitle, title, noUnderline }) => (
  <StyledBannerHeader>
    <Header2 blue inline data-cy="bannerHeader">
      {title}
    </Header2>
    {!noUnderline && <CenteredLine large />}
    {subTitle && (
      <Header4 as="h3" inline>
        {subTitle}
      </Header4>
    )}
  </StyledBannerHeader>
);

BannerHeader.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default BannerHeader;

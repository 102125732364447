import styled from 'styled-components/macro';

const BoldText = styled.div`
  color: ${(props) => {
    if (props.blue) return props.theme.color.blue;
    if (props.supporting) return props.theme.color.grayDark;
    if (props.yellow) return props.theme.color.yellow;
    if (props.white) return props.theme.color.white;
    return props.theme.color.grayDarker;
  }};
  white-space: ${(props) => props.nowrap && 'nowrap'};
  font-family: ${(props) => props.theme.font.family.boing};
  font-size: ${(props) => props.theme.font.size['16px']};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  margin: 0;
  padding-bottom: ${(props) => (props.inline ? 0 : '8px')};
`;

export default BoldText;

import * as Sentry from '@sentry/browser';

const logError = (e, msg = 'ERROR') => {
  let error = e;
  if (e) {
    if (!(error instanceof Error)) {
      error = new Error();
    }
    error.message = msg + ' | ' + (e.message ? e.message : e);

    Sentry.withScope(function(scope) {
      scope.setExtra('error', error);
      Sentry.captureException(error);
    });
  } else {
    Sentry.captureMessage(msg);
  }
};

export default logError;

import { gql } from '@apollo/client';

const GET_POPULAR_PRODUCTS = gql`
  query PopularProducts($id: String!) {
    nodeRentalItems(id: $id) {
      rentalItemId
      images {
        url
      }
      name
      specifications {
        name
        index
        unit
        value
      }
      make
      model
      hideMakeModelFi
      hideMakeModelNo
      hideMakeModelSe
      isRamiGreenFi
      isRamiGreenNo
      isRamiGreenSe
      popularProductParent {
        name
        slug
      }
    }
  }
`;

export default GET_POPULAR_PRODUCTS;

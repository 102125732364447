import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BoldText from '../../../components/BoldText';
import Header4 from '../../../components/Header4';
import HorizontalRule from '../../../components/HorizontalRule';
import translations from '../../../../translations';

const StyledSectionTitle = styled.div`
  margin-top: ${props => props.theme.space['16px']};
  width: 100%;
`;

const NoResultsFound = styled.div`
  margin-left: ${props => props.theme.space['12px']};
  margin-top: ${props => props.theme.space['32px']};
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  margin: ${props => props.theme.space['4px']};
`;

const SectionTitle = ({ title }) => {
  // No title or more suggestions available
  if (!title || title === translations.searchBar.seeAllResults) {
    return null;
  }

  // No suggestions
  if (title === translations.noResultsFound) {
    return (
      <NoResultsFound>
        <BoldText>{translations.noResultsFound}</BoldText>
      </NoResultsFound>
    );
  }

  return (
    <StyledSectionTitle data-cy="sectionHeader">
      <Header4 as="div" inline>
        {title}
      </Header4>
      <StyledHorizontalRule />
    </StyledSectionTitle>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionTitle;

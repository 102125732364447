const formatContactInformation = (fields) => ({
  title: fields.title,
  copy: fields.copy,
});

const formatSocialMediaLinks = (fields) => ({
  title: fields.title,
  links: fields.socialMediaLinkList,
});

export const formatSideBarCollection = (sideBarCollection) => ({
  type: 'sideBar',
  contactInformation:
    sideBarCollection.contactInformation &&
    formatContactInformation(sideBarCollection.contactInformation.fields),
  socialMediaLinks:
    sideBarCollection.socialMediaLinks &&
    formatSocialMediaLinks(sideBarCollection.socialMediaLinks.fields),
  title: sideBarCollection.title,
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BoldText from '../../../shared/components/BoldText';

const StyledLinkGroup = styled.div`
  padding-top: ${props => props.theme.space['24px']};

  ${props => props.theme.device.desktop`
    padding-bottom: 0;
    padding-top: 0;
  `}
`;

const Title = styled(BoldText)`
  margin-bottom: ${props => props.theme.space['16px']};
`;

const LinkGroup = ({ children, className, title }) => (
  <StyledLinkGroup className={className} data-cy="linkGroup">
    <Title inline>{title}</Title>
    {children}
  </StyledLinkGroup>
);

LinkGroup.propTypes = {
  title: PropTypes.string.isRequired
};

export default LinkGroup;

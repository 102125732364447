import React from 'react';
import styled from 'styled-components/macro';

import aboutIcon from '../../../../assets/icons/about-icon.svg';
import blogIcon from '../../../../assets/icons/blog-icon.svg';
import coursesIcon from '../../../../assets/icons/courses-icon.svg';
import newsIcon from '../../../../assets/icons/news-icon.svg';
import servicesIcon from '../../../../assets/icons/services-icon.svg';
import referenceCasesIcon from '../../../../assets/icons/reference-cases-icon.svg';
import defaultImage from '../../../../assets/images/Ramicon_Happy.svg';
import { pageIconFactory } from '../SearchBarUtils';
import Button from '../../../components/Button';
import HorizontalRule from '../../../components/HorizontalRule';
import CustomerCenterSuggestion from './CustomerCenterSuggestion';
import FaqSuggestion from './FaqSuggestion';
import IconSuggestion from './IconSuggestion';
import ProductSuggestion from './ProductSuggestion';
import translations from '../../../../translations';
import shorten from '../../../../utils/shorten';

const StyledSuggestion = styled.li`
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  padding: 0 ${(props) => props.theme.space['12px']};
  width: 100%;
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  margin: ${(props) => props.theme.space['4px']};
`;

const SeeAllSuggestions = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.space['24px']};
`;

const Suggestion = ({ suggestion, ...rest }) => {
  // About page
  if (suggestion.__typename === 'About') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={aboutIcon}
          teaser={shorten(suggestion.teaser, 150)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }
  // Course
  if (suggestion.__typename === 'Course') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={coursesIcon}
          teaser={shorten(suggestion.teaser, 150)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }
  // Service
  if (suggestion.__typename === 'Service') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={servicesIcon}
          teaser={shorten(suggestion.teaser, 158)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }

  // Blog post
  if (suggestion.__typename === 'BlogPost') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={blogIcon}
          teaser={shorten(suggestion.teaser, 150)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }

  // Page
  if (suggestion.__typename === 'Page') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={pageIconFactory(suggestion.icon)}
          teaser={shorten(suggestion.teaser, 150)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }

  // Product
  if (suggestion.__typename === 'RentalItem') {
    const shouldHideMakeModel = !!(
      (suggestion?.hideMakeModelFi && rest.country === 'FI') ||
      (suggestion?.hideMakeModelNo && rest.country === 'NO') ||
      (suggestion?.hideMakeModelSe && rest.country === 'SE')
    );

    const shouldShowRamiGreenIcon = !!(
      (suggestion?.isRamiGreenFi && rest.country === 'FI') ||
      (suggestion?.isRamiGreenNo && rest.country === 'NO') ||
      (suggestion?.isRamiGreenSe && rest.country === 'SE')
    );

    return (
      <StyledSuggestion {...rest}>
        <ProductSuggestion
          image={suggestion.images[0] ? suggestion.images[0].url : defaultImage}
          make={suggestion.make}
          model={suggestion.model}
          specifications={suggestion.specifications}
          title={suggestion.name}
          shouldHideMakeModel={shouldHideMakeModel}
          shouldShowRamiGreenIcon={shouldShowRamiGreenIcon}
        />
      </StyledSuggestion>
    );
  }

  // FAQ
  if (suggestion.__typename === 'Faq') {
    return (
      <StyledSuggestion {...rest}>
        <FaqSuggestion copy={suggestion.copy} title={suggestion.title} />
      </StyledSuggestion>
    );
  }

  // Customer center
  if (suggestion.__typename === 'Location') {
    return (
      <StyledSuggestion {...rest}>
        <CustomerCenterSuggestion
          address={suggestion.address}
          description={suggestion.description}
          email={suggestion.email}
          name={suggestion.name}
          phoneNumber={suggestion.phoneNumber}
        />
      </StyledSuggestion>
    );
  }

  // News post
  if (suggestion.__typename === 'News') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={newsIcon}
          teaser={shorten(suggestion.teaser, 150)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }

  // Reference case
  if (suggestion.__typename === 'Reference') {
    return (
      <StyledSuggestion {...rest}>
        <IconSuggestion
          image={referenceCasesIcon}
          teaser={shorten(suggestion.teaser, 158)}
          title={suggestion.title}
        />
      </StyledSuggestion>
    );
  }

  // More suggestions available
  if (suggestion === translations.searchBar.seeAllResults) {
    return (
      <StyledSuggestion {...rest}>
        <SeeAllSuggestions data-cy="seeAllSuggestions">
          <StyledHorizontalRule />
          <StyledButton>{translations.searchBar.seeAllResults}</StyledButton>
        </SeeAllSuggestions>
      </StyledSuggestion>
    );
  }

  return null;
};

export default Suggestion;

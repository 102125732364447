import React from 'react';
import styled from 'styled-components/macro';

import { isMediaFromContentful } from '../../../../utils/mediaFunctions';
import {
  Image as ImageComponent,
  ImageType,
} from '../../../components/Image/Image';

const ImageDiv = styled.div``;

const StyledImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
`;

const Image = ({ ...props }) => {
  if (isMediaFromContentful(props.src)) {
    return (
      <ImageDiv {...props}>
        <ImageComponent
          image={{ url: props.src, alt: props.alt }}
          type={ImageType.RECTANGLE}
        />
      </ImageDiv>
    );
  }
  return <StyledImage {...props} />;
};

export default Image;

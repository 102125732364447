import {
  ABOUT_PATH,
  COURSES_PATH,
  SERVICES_PATH,
  BLOG_PATH,
  CUSTOMER_CENTERS_PATH,
  CUSTOMER_SERVICE_PATH,
  NEWS_PATH,
  REFERENCES_PATH,
  SEARCH_PATH,
} from '../../../constants';
import translations from '../../../translations';
import { createProductLink } from '../../../utils/rentUtil';
import aboutIcon from '../../../assets/icons/about-icon.svg';
import blogIcon from '../../../assets/icons/blog-icon.svg';
import coursesIcon from '../../../assets/icons/courses-icon.svg';
import newsIcon from '../../../assets/icons/news-icon.svg';
import servicesIcon from '../../../assets/icons/services-icon.svg';
import referenceCasesIcon from '../../../assets/icons/reference-cases-icon.svg';
import defaultImage from '../../../assets/images/Ramicon_Happy.svg';

const getSuggestionPath = (suggestion, inputValue) => {
  // About page
  if (suggestion.__typename === 'About') {
    const { slug } = suggestion;
    return `${ABOUT_PATH}/${slug}`;
  }
  // Course
  if (suggestion.__typename === 'Course') {
    const { slug } = suggestion;
    return `${COURSES_PATH}/${slug}`;
  }
  // Service
  if (suggestion.__typename === 'Service') {
    const { slug } = suggestion;
    return `${SERVICES_PATH}/${slug}`;
  }

  // Blog post
  if (suggestion.__typename === 'BlogPost') {
    const { slug } = suggestion;
    return `${BLOG_PATH}/${slug}`;
  }

  // Page
  if (suggestion.__typename === 'Page') {
    const { completeSlug } = suggestion;
    return `/${completeSlug}`;
  }

  // Product
  if (suggestion.__typename === 'RentalItem') {
    const { parent, name, rentalItemId } = suggestion;
    return createProductLink(
      {
        parentSlug: parent.slug,
        name: parent.name,
      },
      { name, rentalItemId }
    );
  }

  // FAQ
  if (suggestion.__typename === 'Faq') {
    return CUSTOMER_SERVICE_PATH;
  }

  // Customer center
  if (suggestion.__typename === 'Location') {
    const { slug } = suggestion;
    return `${CUSTOMER_CENTERS_PATH}/${slug}`;
  }

  // News post
  if (suggestion.__typename === 'News') {
    const { slug } = suggestion;
    return `${NEWS_PATH}/${slug}`;
  }

  // Reference case
  if (suggestion.__typename === 'Reference') {
    const { slug } = suggestion;
    return `${REFERENCES_PATH}/${slug}`;
  }

  // See all suggestions
  if (suggestion === translations.searchBar.seeAllResults) {
    return `${SEARCH_PATH}?q=${inputValue}`;
  }
};

const suggestionToString = (i) => (i ? i.name : '');

const pageIconFactory = (icon) => {
  switch (icon) {
    case 'About':
      return aboutIcon;
    case 'Blog Post':
      return blogIcon;
    case 'Courses':
      return coursesIcon;
    case 'News':
      return newsIcon;
    case 'Services':
      return servicesIcon;
    case 'References':
      return referenceCasesIcon;
    case 'Rami Happy Guy':
      return defaultImage;
    default:
      return newsIcon;
  }
};

export { getSuggestionPath, suggestionToString, pageIconFactory };

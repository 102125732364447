import React from 'react';
import styled from 'styled-components/macro';

import Link from './Link';

const ButtonLink = styled(({ primary, noBorder, ...props }) => (
  <Link {...props} />
))`
  background: ${props =>
    props.primary ? props.theme.color.orange : props.theme.color.blue};
  border: ${props => props.theme.border['4px']}
    ${props =>
      props.primary ? props.theme.color.orange : props.theme.color.white};
  border-radius: ${props => props.theme.borderRadius.larger};
  border-width: ${props => props.noBorder && 0};
  color: ${props => props.theme.color.white};
  display: block;
  font-size: ${props => props.theme.font.size['16px']};
  font-weight: ${props => props.theme.font.weight.semiBold};
  padding: ${props => props.theme.space['8px']}
    ${props => props.theme.space['16px']};
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;

  :focus {
    background-color: ${props =>
      props.primary ? props.theme.color.orangeDark : props.theme.color.blue};
    border: ${props => props.theme.border['4px']}
      ${props =>
        props.primary
          ? props.theme.color.orangeLight
          : props.theme.color.orange};
    border-radius: ${props => props.theme.borderRadius.larger};
    border-width: ${props => props.noBorder && 0};
    box-shadow: ${props => props.noBorder && props.theme.shadow.normal};
    color: ${props =>
      props.primary ? props.theme.color.white : props.theme.color.orange};
  }

  :hover {
    background-color: ${props =>
      props.primary ? props.theme.color.orangeLight : props.theme.color.blue};
    border: ${props => props.theme.border['4px']}
      ${props =>
        props.primary
          ? props.theme.color.orangeLight
          : props.theme.color.orange};
    border-radius: ${props => props.theme.borderRadius.larger};
    border-width: ${props => props.noBorder && 0};
    box-shadow: ${props => props.noBorder && props.theme.shadow.normal};
    color: ${props =>
      props.primary ? props.theme.color.white : props.theme.color.orange};
  }

  ${props => props.theme.device.tablet`
    font-size: ${props => props.theme.font.size['20px']};
    padding: ${props => props.theme.space['12px']}
      ${props => props.theme.space['32px']};
  `};
`;

export default ButtonLink;

import React from 'react';
import styled from 'styled-components/macro';

import Skeleton from './Skeleton';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.space['48px']} 0;
`;

const Title = styled(Skeleton)`
  max-width: 300px;
`;

const Accordion = styled.div`
  align-items: center;
  border-bottom: ${(props) => props.theme.border['1px']}
    ${(props) => props.theme.color.grayLight};
  display: flex;
  flex-direction: row;
  height: 48px;
  margin-top: ${(props) => props.theme.space['16px']};
  padding-bottom: ${(props) => props.theme.space['16px']};

  :nth-of-type(1n + 7) {
    display: none;
  }

  ${(props) => props.theme.device.tablet`
    :nth-of-type(n) {
      display: flex;
    }
  `}
`;

const Question = styled(Skeleton)`
  flex-grow: 1;
  width: 80%;
`;

const Button = styled(Skeleton)`
  height: 32px;
  margin: ${(props) => props.theme.space['12px']};
  margin-right: 0;
  width: 32px;
`;

const SkeletonFaq = () => {
  const Accordions = () => {
    const objects = [];
    for (let i = 0; i < 6; i++) {
      objects.push(
        <Accordion key={i}>
          <Question type={'paragraph'} inline />
          <Button />
        </Accordion>
      );
    }
    return objects;
  };

  return (
    <StyledWrapper>
      <Title type={'header2'} inline />
      <Accordions />
    </StyledWrapper>
  );
};

export default SkeletonFaq;

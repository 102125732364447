import React, { useEffect } from 'react';

import {
  GOOGLE_ANALYTICS_CATEGORY_DROPDOWN,
  GOOGLE_ANALYTICS_LABEL_PAGE,
} from '../../../../constants';
import {
  getLatestSubpages,
  getFilterOptions,
} from '../../../../api/contentful/getLatestSubpages';
import {
  SET_FILTER_OPTIONS,
  SET_PAGES,
  CHANGE_FILTER,
} from '../../../contextProviders/PageProvider';
import { useListState } from '../../../hooks/useListState';
import { GoogleAnalyticsEvent } from '../../../components/GoogleAnalytics';
import RamiDropdownFilter from '../components/dropdown/RamiDropdownFilter';
import logError from '../../../../utils/logger';

const RamiDropdownFilterContainer = ({ children, id }) => {
  const { state, dispatch, locale } = useListState(id);

  useEffect(() => {
    const getData = async () => {
      try {
        const filterOptionList = await getFilterOptions(state.pages[0].type);
        const filterOptions = [
          {
            label: children,
            value: 'all',
          },
          ...filterOptionList,
        ];

        dispatch({ type: SET_FILTER_OPTIONS, payload: { filterOptions } });
      } catch (error) {
        logError(error);
      }
    };

    if (state.filterOptionsLoading && state.pages.length !== 0) {
      getData();
    }
  }, [state.filterOptionsLoading, state.pages, children, dispatch]);

  useEffect(() => {
    const getDataForSelectedFilter = async () => {
      try {
        const response = await getLatestSubpages(
          locale,
          state.data.parentSlug,
          state.data.maxNumberOfItems,
          state.pages.length,
          state.selectedFilterOption && state.selectedFilterOption.value
        );
        dispatch({
          type: SET_PAGES,
          payload: {
            pages: response.pages,
            totalPages: response.totalPages,
          },
        });
      } catch (error) {
        logError(error);
      }
    };

    if (state.selectedFilterOption && state.pages.length === 0) {
      getDataForSelectedFilter();
    }
  }, [state.selectedFilterOption, state.pages, state.data, locale, dispatch]);

  const onFilterOptionChange = async (event) => {
    GoogleAnalyticsEvent(
      GOOGLE_ANALYTICS_CATEGORY_DROPDOWN,
      `Selected Filter: ${event.value}`,
      GOOGLE_ANALYTICS_LABEL_PAGE(state.data.parentSlug)
    );

    dispatch({
      type: CHANGE_FILTER,
      payload: {
        filterOption: event,
      },
    });
  };

  if (state.error) {
    return null;
  }

  return (
    <RamiDropdownFilter
      options={state.filterOptions}
      defaultOption={state.filterOptions.find(
        (filterOption) => filterOption === state.selectedFilterOption
      )}
      loading={state.filterOptionsLoading}
      onFilterOptionChange={(event) => onFilterOptionChange(event)}
      placeholder={children}
    />
  );
};

export default RamiDropdownFilterContainer;

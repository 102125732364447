import { useContext, useCallback } from 'react';

import {
  PageContext,
  initialStateForAList,
} from '../contextProviders/PageProvider';
import { useLocale } from './useLocale';

export const useListState = (id) => {
  const [state, dispatch] = useContext(PageContext);
  const { locale } = useLocale();
  const listState = state[id] ? state[id] : initialStateForAList;

  const dispatchForId = useCallback(
    (dispatchObject) => {
      dispatch({
        type: dispatchObject.type,
        payload: dispatchObject.payload
          ? {
              id: id,
              ...dispatchObject.payload,
            }
          : { id: id },
      });
    },
    [id, dispatch]
  );

  return {
    state: listState,
    dispatch: dispatchForId,
    locale,
  };
};

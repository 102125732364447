import theme from '../theme';
export const initDynamicsMarketing = (
  dynamicsDataFormBlockId,
  dynamicsWebsiteId
) => {
  if (window.MsCrmMkt) {
    window.MsCrmMkt = function () {
      return false;
    };
  }

  const formLoaderScript = document.createElement('script');
  const wsTrackingScript = document.createElement('script');
  const dynamicsIdDiv = document.createElement('div');
  const mktConfigDiv = document.createElement('div');
  const formDiv = document.createElement('div');

  formLoaderScript.classList.add('marketing-form-script');
  wsTrackingScript.classList.add('marketing-form-script');
  dynamicsIdDiv.classList.add('marketing-form-script');
  mktConfigDiv.classList.add('marketing-form-script');

  formDiv.setAttribute('data-form-block-id', dynamicsDataFormBlockId);
  formLoaderScript.src =
    'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.77.1005';
  wsTrackingScript.src =
    'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.77.1005';
  formLoaderScript.async = true;
  wsTrackingScript.async = true;
  dynamicsIdDiv.id = `d${dynamicsWebsiteId}`;
  mktConfigDiv.setAttribute('data-website-id', dynamicsWebsiteId);
  mktConfigDiv.setAttribute(
    'data-hostname',
    'edc8889873524312bbc0ce8225a4923d.svc.dynamics.com'
  );
  mktConfigDiv.className = 'd365-mkt-config';
  mktConfigDiv.style.display = 'none';

  document.getElementById(dynamicsWebsiteId).appendChild(formDiv);
  document.getElementById(dynamicsWebsiteId).appendChild(formLoaderScript);
  document.getElementById(dynamicsWebsiteId).appendChild(wsTrackingScript);
  document.getElementById(dynamicsWebsiteId).appendChild(dynamicsIdDiv);
  document.getElementById(dynamicsWebsiteId).appendChild(mktConfigDiv);

  const searchButton = document.getElementById('searchButton');
  searchButton.style.backgroundColor = theme.color.blue;
  searchButton.style.borderRadius = `0 ${theme.borderRadius.small} ${theme.borderRadius.small} 0`;
  searchButton.style.padding = theme.space['10px'];
  searchButton.style.width = theme.space['46px'];
  searchButton.style.height = theme.space['43px'];
  searchButton.style.border = 'none';

  const searchInput = document.getElementById('downshift-0-input');
  searchInput.style.border = 'none';
  searchInput.style.borderRadius = `${theme.borderRadius.small} 0 0 ${theme.borderRadius.small}`;
  searchInput.style.height = theme.space['43px'];
  searchInput.style.textIndent = '0';
  searchInput.style.fontFamily = theme.font.family.boing;
  searchInput.style.fontSize = theme.font.size['16px'];
};

import { getLink } from '../Utils/link';

const formatContactMenu = (option) => ({
  title: option.fields.title,
  contactWay: option.fields.contactWay,
  copy: option.fields.copy,
  icon: option.fields.icon,
  contactInfo:
    option.fields.contactWay === 'Link'
      ? getLink(option.fields.link)
      : option.fields.contactInformation,
});

export const formatContactBarCollection = (contactBarCollection) => ({
  type: 'contactBar',
  contactMenu:
    contactBarCollection.contactOption &&
    contactBarCollection.contactOption.map((option) =>
      formatContactMenu(option)
    ),
  title: contactBarCollection.title,
});

import React, { useState } from 'react';
import {
  Accordion as UnstyledAccordion,
  AccordionItem as UnstyledAccordionItem,
  AccordionItemButton as UnstyledAccordionItemButton,
  AccordionItemHeading as UnstyledAccordionItemHeading,
  AccordionItemPanel as UnstyledAccordionItemPanel,
} from 'react-accessible-accordion';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import caretDownIcon from '../../../assets/icons/caret_down_icon_blue.svg';
import {
  GOOGLE_ANALYTICS_CATEGORY_BUTTON,
  GOOGLE_ANALYTICS_LABEL_FAQ,
} from '../../../constants';
import { GoogleAnalyticsEvent } from '../GoogleAnalytics';
import Header2 from '../Header2';
import Header4 from '../Header4';
import HorizontalRule from '../HorizontalRule';

const StyledAccordion = styled(UnstyledAccordion)`
  display: flex;
  flex-direction: column;
`;

const StyledAccordionItemHeading = styled(UnstyledAccordionItemHeading)`
  padding-bottom: ${(props) => props.theme.space['16px']};
  position: relative;
`;

const StyledAccordionItemButton = styled(UnstyledAccordionItemButton)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  margin-left: ${(props) => props.theme.space['48px']};
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  width: ${(props) => props.theme.icon.size['32px']};

  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledHorizontalRule = styled(HorizontalRule)`
  background-color: ${(props) => props.theme.color.blue};
  height: 2px;
`;

const AccordionBlock = ({ children, title, subTitle }) => {
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleAccordionChange = (uuids) => {
    setExpandedPanels([...uuids]);

    // Only track on expand
    if (uuids.length) {
      GoogleAnalyticsEvent(
        GOOGLE_ANALYTICS_CATEGORY_BUTTON,
        `Expanded: ${uuids[0]}`,
        GOOGLE_ANALYTICS_LABEL_FAQ
      );
    }
  };

  return (
    <StyledAccordion
      allowZeroExpanded={true}
      onChange={handleAccordionChange}
      data-cy="accordionDivWrapper"
    >
      <UnstyledAccordionItem key={title} uuid={title} data-cy="accordionItem">
        <StyledAccordionItemHeading>
          <StyledAccordionItemButton data-cy="accordionItemButton">
            <Header2 inline blue>
              {title}
            </Header2>
            <Icon
              alt=""
              expanded={expandedPanels.includes(title)}
              src={caretDownIcon}
            />
          </StyledAccordionItemButton>
        </StyledAccordionItemHeading>
        <UnstyledAccordionItemPanel data-cy="accordionPanel">
          <AnimateHeight
            duration={500}
            height={expandedPanels.includes(title) ? 'auto' : 0}
          >
            <>
              {subTitle && <Header4>{subTitle}</Header4>}
              {children}
            </>
          </AnimateHeight>
        </UnstyledAccordionItemPanel>
        <StyledHorizontalRule />
      </UnstyledAccordionItem>
    </StyledAccordion>
  );
};

AccordionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default AccordionBlock;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import mapPinIcon from '../../../../assets/icons/map_pin_icon.svg';
import BoldText from '../../../components/BoldText';
import Link from '../../../components/Link';
import { COMPANY_NAME } from '../../../../constants';
import Paragraph from '../../../components/Paragraph';

const StyledCustomerCenterSuggestion = styled.div`
  align-items: center;
  display: flex;
  padding: ${(props) => props.theme.space['12px']} 0;
  padding-right: ${(props) => props.theme.space['24px']};
  width: 100%;
`;

const Image = styled.img`
  flex: none;
  height: 60px;
  margin-right: ${(props) => props.theme.space['12px']};
  width: 60px;
`;

const Information = styled.div`
  ${(props) => props.theme.device.tablet`
    align-items: center;
    break-inside: avoid;
    display: flex;
    width: 100%;
  `}
`;

const Description = styled.div`
  ${(props) => props.theme.device.tablet`
    flex: none;
    width: 50%;
  `}
`;

const ContactInformation = styled.div`
  ${(props) => props.theme.device.tablet`
    border-left: ${(props) => props.theme.border['2px']}
      ${(props) => props.theme.color.gray};
    margin-left: ${(props) => props.theme.space['12px']};
    padding-left: ${(props) => props.theme.space['12px']};
    width: 50%;
  `}
`;

const CustomerCenterSuggestion = ({
  address,
  description,
  email,
  name,
  phoneNumber,
}) => (
  <StyledCustomerCenterSuggestion>
    <Image alt="" src={mapPinIcon} />
    <Information>
      <Description>
        <BoldText inline>
          {name.includes(COMPANY_NAME) ? '' : COMPANY_NAME} {name}
        </BoldText>
        <Paragraph inline>{address}</Paragraph>
        <Paragraph inline>{description}</Paragraph>
      </Description>
      <ContactInformation>
        <div>
          <Link url={`mailto:${email}`}>{email}</Link>
        </div>
        <div>
          <Link url={`tel:${phoneNumber}`}>{phoneNumber}</Link>
        </div>
      </ContactInformation>
    </Information>
  </StyledCustomerCenterSuggestion>
);

CustomerCenterSuggestion.propTypes = {
  address: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
};

export default CustomerCenterSuggestion;

import styled, { css } from 'styled-components/macro';

const UnstyledHeader4 = styled.h4`
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
`;

const header4Styles = css`
  color: ${props => {
    if (props.blue) return props.theme.color.blue;
    if (props.white) return props.theme.color.white;
    return props.theme.color.grayDarker;
  }};
  font-family: ${props => props.theme.font.family.boing};
  font-size: ${props => props.theme.font.size['16px']};
  font-weight: ${props => props.theme.font.weight.regular};
  padding-bottom: ${props => (props.inline ? 0 : '16px')};

  ${props => props.theme.device.tablet`
    font-size: ${props => props.theme.font.size['20px']};
    padding-bottom: ${props => (props.inline ? 0 : '16px')};
  `}
`;

const Header4 = styled(UnstyledHeader4)`
  ${header4Styles}
`;

export default Header4;
export { header4Styles };

import { ENVIRONMENT } from '../constants';

/* Adform Tracking Code */
const initAdformClient = () => {
  if (ENVIRONMENT === 'PRODUCTION') {
    window._adftrack = Array.isArray(window._adftrack)
      ? window._adftrack
      : window._adftrack
      ? [window._adftrack]
      : [];
    window._adftrack.push({
      pm: 1583478
    });
    (function() {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  }
};

export default initAdformClient;

import { getCompleteSlug } from '../getPages';
import { formatUrl } from '../../../utils/link';
import { locales } from '../../../constants';

const formatNavigationBar = (menuOption, language) => ({
  title: menuOption.fields.displayTitle
    ? menuOption.fields.displayTitle[language] ||
      menuOption.fields.displayTitle[locales.FI]
    : menuOption.fields.title[locales.FI],
  link:
    menuOption.fields.link &&
    formatUrl(menuOption.fields.link[locales.FI]) +
      `${
        menuOption.fields.link[locales.FI].includes('ramirent') && language
          ? '?lang=' + language
          : ''
      }`,
  slugList: menuOption.fields.slug && getCompleteSlug(menuOption.fields),
  id: menuOption.sys.id,
});

export const formatNavigationBarCollectionFI = (
  navigationBarCollection,
  language = [locales.FI]
) => ({
  type: 'navigationBar',
  menuOptions:
    navigationBarCollection.menuOption &&
    navigationBarCollection.menuOption[locales.FI].map((option) => {
      return formatNavigationBar(option, language);
    }),
  title: navigationBarCollection.title[locales.FI],
});

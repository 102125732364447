import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import GET_CUSTOMER_CENTERS from '../../../../api/graphQL/queries/getCustomerCenters';
import DataError from '../../../components/DataError';
import MapContent from '../components/MapContent';
import { COUNTRY, HIDDEN_CENTER_IDS } from '../../../../constants';
import logError from '../../../../utils/logger';

const Wrapper = ({ initialZoom, activeMap }) => {
  const { data, error, loading } = useQuery(GET_CUSTOMER_CENTERS, {
    variables: { country: COUNTRY, type: 'CUSTOMER_CENTER' },
  });

  if (error) {
    logError(error);
  }

  const sortedCustomerCenters =
    data && data.locations
      ? [...data.locations].sort((location1, location2) =>
          location1.name.localeCompare(location2.name)
        )
      : undefined;

  const filteredCustomerCenters = sortedCustomerCenters
    ? sortedCustomerCenters.filter((cc) => !HIDDEN_CENTER_IDS.includes(cc.id))
    : undefined;

  return (
    <>
      {error && <DataError />}
      {(loading || filteredCustomerCenters) && (
        <MapContent
          customerCenters={filteredCustomerCenters}
          initialZoom={initialZoom}
          activeMap={activeMap}
          loading={loading}
        />
      )}
    </>
  );
};

export default Wrapper;

Wrapper.propTypes = {
  initialZoom: PropTypes.number,
  ativeMap: PropTypes.bool,
};

import englishTranslations from './en';
import finnishTranslations from './fi';
import norwegianTranslations from './no';
import swedishTranslations from './se';

const TRANSLATIONS = {
  EN: { ...englishTranslations },
  FI: { ...finnishTranslations },
  NO: { ...norwegianTranslations },
  SE: { ...swedishTranslations }
};

export default TRANSLATIONS[process.env.REACT_APP_COUNTRY_DOMAIN];

import { getCompleteSlug } from '../getPages';
import { formatUrl } from '../../../utils/link';
import { locales } from '../../../constants';
import { getLink } from '../Utils/link';

const formatFooterBadges = (badge) => ({
  title: badge.title[locales.FI],
  url: badge.link[locales.FI],
  image: badge.image[locales.FI].fields.file[locales.FI],
});

const formatSocialMediaLinks = (link) => ({
  title: link.title[locales.FI],
  links: link.socialMediaLinkList[locales.FI].map((socialMedia) => {
    return {
      image: socialMedia.fields.image[locales.FI].fields.file[locales.FI],
      link: socialMedia.fields.link[locales.FI],
      title: socialMedia.fields.title[locales.FI],
    };
  }),
});

const formatFooterBottomLinks = (link, language) => ({
  title: link.fields.displayTitle
    ? link.fields.displayTitle[language] || link.fields.displayTitle[locales.FI]
    : link.fields.title[locales.FI],
  link: getLink(link),
});

const formatFooterMenuLinks = (link, language) => ({
  title: link.fields.title[language] || link.fields.title[locales.FI],
  links: link.fields.links[locales.FI].map((link) => {
    if (link.fields.link) {
      return {
        title: link.fields.displayTitle
          ? link.fields.displayTitle[language] ||
            link.fields.displayTitle[locales.FI]
          : link.fields.title[locales.FI],
        url: formatUrl(link.fields.link[locales.FI]),
      };
    } else if (link.fields.slug) {
      return {
        title: link.fields.displayTitle
          ? link.fields.displayTitle[language] ||
            link.fields.displayTitle[locales.FI]
          : link.fields.title[locales.FI],
        completeSlug: getCompleteSlug(link.fields),
      };
    }
  }),
});

export const formatFooterCollectionFI = (
  footerCollection,
  language = [locales.FI]
) => {
  return {
    type: 'footer',
    footerBadges:
      footerCollection.footerBadges &&
      footerCollection.footerBadges[locales.FI].map((badge) =>
        formatFooterBadges(badge.fields)
      ),
    socialMediaLinks:
      footerCollection.socialMediaLinks &&
      formatSocialMediaLinks(
        footerCollection.socialMediaLinks[locales.FI][0].fields
      ),
    footerBottomLinks:
      footerCollection.footerBottomLinks &&
      footerCollection.footerBottomLinks[locales.FI].map((link) => {
        return formatFooterBottomLinks(link, language);
      }),
    footerMenuLinks:
      footerCollection.footerMenuLinks &&
      footerCollection.footerMenuLinks[locales.FI].map((link) => {
        return formatFooterMenuLinks(link, language);
      }),
    title: footerCollection.title[locales.FI],
  };
};

import React from 'react';
import UnstyledSelect, { components } from 'react-select';
import styled from 'styled-components/macro';

import caretDownIcon from '../../assets/icons/caret_down_icon.svg';

const StyledSelect = styled(UnstyledSelect)`
  .select {
    &__control {
      background-color: ${props => props.theme.color.blue};
      border-radius: ${props => props.theme.borderRadius.small};
      border: ${props => props.theme.border['2px']}
        ${props => props.theme.color.blue};
      color: ${props => props.theme.color.white};
      cursor: pointer;
      font-weight: ${props => props.theme.font.weight.semiBold};
      min-width: 195px;
      padding: ${props => props.theme.space['12px']} 0;
      &--is-focused {
        border: ${props => props.theme.border['2px']}
          ${props => props.theme.color.blueLighter};
        box-shadow: none;
      }
      &--menu-is-open {
        .select__indicator {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
      :hover {
        border-color: ${props => props.theme.color.blueLighter};
      }
    }
    &__indicator {
      color: inherit;
      content: url(${caretDownIcon});
      width: ${props => props.theme.icon.size['20px']};
      height: ${props => props.theme.icon.size['20px']};
      margin: 0 ${props => props.theme.space['8px']};
      padding: 0;
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      &-separator {
        display: none;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    &__menu-list {
      border-radius: ${props => props.theme.borderRadius.small};
      cursor: pointer;
      font-weight: ${props => props.theme.font.weight.semiBold};
      max-height: 120px;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 14px;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${props => props.theme.color.orange};
        border: ${props => props.theme.border['4px']} rgba(0, 0, 0, 0);
        border-radius: ${props => props.theme.borderRadius.large};
        min-height: 50px;
      }
    }
    &__option {
      background-color: ${props => props.theme.color.white};
      color: ${props => props.theme.color.grayDarker};
      cursor: pointer;
      transition: all 0.3s ease;
      &--is-focused {
        background-color: ${props => props.theme.color.blueLighter};
        color: ${props => props.theme.color.white};
      }
      &--is-selected {
        background-color: ${props => props.theme.color.blue};
        color: ${props => props.theme.color.white};
      }
    }
    &__placeholder {
      color: inherit;
    }
    &__single-value {
      color: inherit;
    }
  }
`;

// Added to make the dummy input accessible
const ValueContainer = ({ children, ...props }) => {
  const selectInput = React.Children.toArray(children).find(
    child => child.props.id && child.props.id.includes('react-select')
  );

  const accessibleSelectInput = React.cloneElement(selectInput, {
    'aria-label': 'Select value'
  });

  const otherElements = React.Children.toArray(children).filter(
    child => !(child.props.id && child.props.id.includes('react-select'))
  );

  return (
    <components.ValueContainer {...props}>
      {otherElements}
      {accessibleSelectInput}
    </components.ValueContainer>
  );
};

const Select = props => (
  <div data-cy="dropdown">
    <StyledSelect
      classNamePrefix="select"
      components={{ ValueContainer }}
      isSearchable={false}
      {...props}
    />
  </div>
);

export default Select;

import { gql } from '@apollo/client';

export const POST_CONTACT_FORM = gql`
  query SendEmailToCustomerService(
    $email: String!
    $name: String!
    $phone: String!
    $location: String!
    $message: String!
  ) {
    sendEmailToCustomerService(
      email: $email
      name: $name
      phone: $phone
      location: $location
      message: $message
    )
  }
`;

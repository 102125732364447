import { locales } from '../../../constants';
import { getLink } from '../Utils/link';

const formatContactMenu = (option, language) => ({
  title: option.fields.title[language] || option.fields.title[locales.FI],
  contactWay: option.fields.contactWay[locales.FI],
  copy: option.fields.copy[language] || option.fields.copy[locales.FI],
  icon: option.fields.icon[locales.FI],
  contactInfo:
    option.fields.contactWay[locales.FI] === 'Link'
      ? getLink(option.fields.link[locales.FI])
      : option.fields.contactInformation[locales.FI],
});

export const formatContactBarCollectionFI = (
  contactBarCollection,
  language = [locales.FI]
) => ({
  type: 'contactBar',
  contactMenu:
    contactBarCollection.contactOption &&
    contactBarCollection.contactOption[locales.FI].map((option) =>
      formatContactMenu(option, language)
    ),
  title: contactBarCollection.title[locales.FI],
});

import { getCompleteSlug } from '../getPages';
import { formatUrl } from '../../../utils/link';
import { getLink } from '../Utils/link';

const formatFooterBadges = (badge) => ({
  title: badge.title,
  url: badge.link,
  image: badge.image.fields.file,
});

const formatSocialMediaLinks = (link) => ({
  title: link.title,
  links: link.socialMediaLinkList.map((socialMedia) => {
    return {
      image: socialMedia.fields.image.fields.file,
      link: socialMedia.fields.link,
      title: socialMedia.fields.title,
    };
  }),
});

const formatFooterBottomLinks = (link) => ({
  title: link.fields.displayTitle
    ? link.fields.displayTitle
    : link.fields.title,
  link: getLink(link),
});

const formatFooterMenuLinks = (link) => ({
  title: link.fields.title,
  links: link.fields.links.map((link) => {
    if (link.fields.link) {
      return { title: link.fields.title, url: formatUrl(link.fields.link) };
    } else if (link.fields.slug) {
      return {
        title: link.fields.displayTitle
          ? link.fields.displayTitle
          : link.fields.title,
        completeSlug: getCompleteSlug(link.fields),
      };
    }
  }),
});

export const formatFooterCollection = (footerCollection) => ({
  type: 'footer',
  footerBadges: footerCollection.footerBadges?.map((badge) =>
    formatFooterBadges(badge.fields)
  ),
  socialMediaLinks:
    footerCollection.socialMediaLinks &&
    formatSocialMediaLinks(footerCollection.socialMediaLinks[0].fields),
  footerBottomLinks:
    footerCollection.footerBottomLinks &&
    footerCollection.footerBottomLinks.map((link) => {
      return formatFooterBottomLinks(link);
    }),
  footerMenuLinks:
    footerCollection.footerMenuLinks &&
    footerCollection.footerMenuLinks.map((link) => {
      return formatFooterMenuLinks(link);
    }),
  title: footerCollection.title,
});

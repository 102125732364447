import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Skeleton from './Skeleton';
import { fullWidthMixin, sectionMixin } from '../../theme';

const StyledSkeletonPageHeader = styled.div`
  ${fullWidthMixin};
  ${sectionMixin};

  background-color: ${props => props.theme.color.blueLighter};
  height: 74px;
  margin-top: -${props => props.theme.page.marginTop};
  padding-left: ${props => props.theme.page.paddingLeft};
  padding-right: ${props => props.theme.page.paddingRight};
  padding-top: ${props => props.theme.space['24px']};

  ${props => props.theme.device.tablet`
    height: 96px;
  `}
`;

const Breadcrumbs = styled.div`
  ${fullWidthMixin};
  ${sectionMixin};

  background-color: ${props => props.theme.color.yellowLighter};
  height: 46px;
  margin-top: -${props => props.theme.page.marginTop};

  ${props => props.theme.device.tablet`
    height: 49px;
  `}
`;

const PageHeader = styled.div`
  margin: auto auto;
  max-width: ${props => props.theme.page.maxWidth};
  height: 30px;

  ${props => props.theme.device.tablet`
    height: 50px;
  `}
`;

const StyledSkeleton = styled(Skeleton)`
  height: 30px;
  width: 80%;

  ${props => props.theme.device.tablet`
    height: 50px;
    width: 60%;
  `}
`;

const SkeletonPageHeader = ({ onlyBreadcrumbs, onlyPageHeader }) => (
  <>
    {!onlyPageHeader && <Breadcrumbs />}
    {!onlyBreadcrumbs && (
      <StyledSkeletonPageHeader>
        <PageHeader>
          <StyledSkeleton color={'blue'} />
        </PageHeader>
      </StyledSkeletonPageHeader>
    )}
  </>
);

SkeletonPageHeader.propTypes = {
  onlyBreadcrumbs: PropTypes.bool,
  onlyPageHeader: PropTypes.bool
};

export default SkeletonPageHeader;

import React from 'react';
import styled from 'styled-components/macro';

import Select from '../../../../components/Select';
import RamiDropdownFilterSkeleton from './RamiDropdownFilterSkeleton';

const DropDown = styled(Select)`
  margin-bottom: ${(props) => props.theme.space['24px']};
  margin-left: auto;
  margin-right: ${(props) => props.theme.space['24px']};
  width: 100%;

  ${(props) => props.theme.device.tablet`
    max-width: 260px;
  `}
`;

const RamiDropdownFilter = ({
  options,
  defaultOption,
  loading,
  placeholder,
  onFilterOptionChange,
}) => (
  <>
    {loading && <RamiDropdownFilterSkeleton />}
    {!loading && (
      <DropDown
        defaultValue={defaultOption}
        onChange={onFilterOptionChange}
        options={options}
        placeholder={placeholder}
      />
    )}
  </>
);

export default RamiDropdownFilter;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import CustomMarkdown from '../../../views/customMarkdown/CustomMarkdown';

import BoldText from '../../../components/BoldText';
import shorten from '../../../../utils/shorten';

const StyledFaqSuggestion = styled.div`
  align-items: center;
  display: flex;
  padding: ${(props) => props.theme.space['12px']} 0;
  padding-right: ${(props) => props.theme.space['24px']};
  width: 100%;
`;

const QuestionMark = styled.div`
  color: ${(props) => props.theme.color.blue};
  flex: none;
  font-size: ${(props) => props.theme.font.size['46px']};
  margin-right: ${(props) => props.theme.space['12px']};
  text-align: center;
  width: 60px;
  height: 60px;
`;

const FaqSuggestion = ({ copy, title }) => (
  <StyledFaqSuggestion>
    <QuestionMark>?</QuestionMark>
    <div>
      <BoldText inline>{shorten(title, 50)}</BoldText>
      {copy && <CustomMarkdown>{copy}</CustomMarkdown>}
    </div>
  </StyledFaqSuggestion>
);

FaqSuggestion.propTypes = {
  copy: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default FaqSuggestion;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Accordion from './Accordion';
import Header3 from '../Header3';

const StyledFaqAccordion = styled.div`
  margin: ${(props) => props.theme.space['48px']} 0;
`;

const FaqAccordion = ({ title, faqPosts }) => (
  <StyledFaqAccordion data-cy="accordionGroup">
    <Header3>{title}</Header3>
    <Accordion items={faqPosts}></Accordion>
  </StyledFaqAccordion>
);

FaqAccordion.propTypes = {
  faqPosts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default FaqAccordion;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BoldText from '../../../components/BoldText';
import imageFormat from '../../../../utils/imageFormat';
import ramiGreen from '../../../../assets/images/RamiGreen_sticker.png';

const StyledProductSuggestion = styled.div`
  align-items: center;
  display: flex;
  padding: ${(props) => props.theme.space['12px']} 0;
  padding-right: ${(props) => props.theme.space['24px']};
  width: 100%;
`;

const Image = styled.div`
  height: 60px;
  margin-right: ${(props) => props.theme.space['12px']};
  width: 60px;

  img {
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RamiGreenImage = styled.img`
  height: 60px;
  width: 60px;
`;

const Manufacturer = styled.div`
  color: ${(props) => props.theme.color.grayDarker};
  font-family: ${(props) => props.theme.font.family.messina};
  font-size: ${(props) => props.theme.font.size['12px']};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  text-transform: uppercase;
`;

const SpecificationName = styled.span`
  color: ${(props) => props.theme.color.grayDarker};
  font-size: ${(props) => props.theme.font.size['12px']};
  font-weight: ${(props) => props.theme.font.weight.semiBold};
  margin-right: ${(props) => props.theme.space['4px']};
`;

const SpecificationValue = styled.span`
  color: ${(props) => props.theme.color.grayDarker};
  font-family: ${(props) => props.theme.font.family.messina};
  font-size: ${(props) => props.theme.font.size['12px']};
  margin-right: ${(props) => props.theme.space['4px']};
`;

const ProductSuggestion = ({
  image,
  make,
  model,
  specifications,
  title,
  shouldHideMakeModel,
  shouldShowRamiGreenIcon,
}) => (
  <StyledProductSuggestion>
    {image && (
      <Image>
        <img alt={title} src={imageFormat(image)} />
      </Image>
    )}
    <div>
      <BoldText inline>{title}</BoldText>
      {!shouldHideMakeModel && (
        <Manufacturer>
          {make} {model}
        </Manufacturer>
      )}
      {specifications &&
        specifications.slice(0, 2).map((specification) => (
          <React.Fragment key={specification.name}>
            <SpecificationName>{specification.name}:</SpecificationName>
            <SpecificationValue>{specification.value}</SpecificationValue>
          </React.Fragment>
        ))}
    </div>
    {shouldShowRamiGreenIcon && (
      <RamiGreenImage src={ramiGreen} alt="RamiGreen" />
    )}
  </StyledProductSuggestion>
);

ProductSuggestion.propTypes = {
  image: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  specifications: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default ProductSuggestion;

import contentfulClient from '../../clients/contentfulClient';
import { getLocalIsoTime } from '../../utils/dateFunctions';
import logError from '../../utils/logger';
import { formatReferenceEntityForList, formatReferenceEntity } from './model';

// Get the latest references where the current reference is not included
const getLatestsReferenceEntities = async (slugToExclude, limit) => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'referenceEntity',
      'fields.slug[ne]': slugToExclude,
      limit,
      order: '-fields.date',
      select: 'fields.bannerImage,fields.slug,fields.title',
    });

    const references = response.items.map((reference) =>
      formatReferenceEntity(reference.fields)
    );

    return references;
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getReferenceEntities = async (limit, skip, filter) => {
  let min, max;

  if (filter && filter.toString() !== 'all') {
    min = `${filter}-01-01`;
    // If filter is the current year do not display
    // reference with publish time later than current time
    max = getLocalIsoTime().includes(filter)
      ? getLocalIsoTime()
      : `${filter}-12-31`;
  }

  try {
    const response = await contentfulClient.getEntries({
      content_type: 'referenceEntity',
      'fields.date[lte]': max ? max : getLocalIsoTime(),
      'fields.date[gte]': min,
      limit,
      skip,
      order: '-fields.date',
      select:
        'fields.bannerImage,fields.date,fields.slug,fields.teaser,fields.title',
    });

    const references = response.items.map((reference) =>
      formatReferenceEntityForList(reference.fields)
    );

    return { references, total: response.total };
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getReferenceEntityBySlug = async (slug) => {
  try {
    const response = await contentfulClient.getEntries({
      content_type: 'referenceEntity',
      'fields.slug': slug,
      limit: 1,
    });

    if (response.items[0]) {
      return formatReferenceEntity(response.items[0].fields);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export {
  getLatestsReferenceEntities,
  getReferenceEntities,
  getReferenceEntityBySlug,
};

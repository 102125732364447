import * as Sentry from '@sentry/browser';

import { ENVIRONMENT, SENTRY_DSN } from '../constants';

const initSentryClient = () => {
  if (ENVIRONMENT !== 'LOCAL') {
    Sentry.init({
      beforeSend(event, hint) {
        //Ignore prerender errors
        if (window.navigator.userAgent.indexOf('Prerender') !== -1) {
          return null;
        }
        return event;
      },
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT
    });
  }
};

export default initSentryClient;
